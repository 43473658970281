<div class="p-4">
    <div class="top-header">
        <img alt="logo" width="180px" src="assets/images/{{_global.whiteLabel.wlName}}-Logo-Main.svg">
        <div class="py-4">Terms of Services</div>
    </div>
    <div class="page">
        <div class="header">
            <p class="title">Software as a Service Agreement</p>
        </div>
        <p>
            This Software as a Service Agreement, hereby known as the "Agreement" entered into by and between
            {{_global.whiteLabel.wlName}}.com
            ("Supplier"), and the individual or entity ("Customer") who is signing up for the Suppliers SaaS platform
            (the
            "Service").
        </p>
        <p>
            Both Supplier and Customer together are collectively hereby known as "Party" or "Parties."
        </p>
        <span class="flex">
            <span class="dot"></span>
            <p> <span class="w-c">WHEREAS,</span> the Supplier has developed specific (Point-of-Sale) software and web
                applications that it makes available for customers to
                integrate with their systems over the Internet;</p>
        </span>
        <span class="flex">
            <span class="dot"></span>
            <p> <span class="w-c">WHEREAS,</span> the Customer wants to use the Supplier's applications in its company
                operations; and</p>
        </span>
        <span class="flex">
            <span class="dot"></span>
            <p> <span class="w-c">WHEREAS,</span> the Supplier agrees to provide said applications to the Customer who
                agrees to use and pay for Supplier's services, which are subject to the following terms and conditions:
            </p>
        </span>

        <div class="header">
            <p class="title">Definitions</p>
        </div>
        <h1 class="sub-title">Services</h1>
        <p>
            Subscription services provided by the Supplier to the Customer under this Agreement from the website <a
                href="https://www.{{_global.whiteLabel.wlsName}}.com">https://www.{{_global.whiteLabel.wlsName}}.com.</a>
        </p>
        <h1 class="sub-title">Documentation</h1>
        <p>
            Describes documents made available by Supplier to the Customer at the website <a
                href="https://www.{{_global.whiteLabel.wlsName}}.com">https://www.{{_global.whiteLabel.wlsName}}.com.</a>The description at said URL sets out the
            total
            Services offered by the Supplier and instructions for how to use said Services.</p>
        <h1 class="sub-title">Support Services Policy</h1>
        <p>
            Includes the support provided by the Supplier in relation to the Services made available at <a
                href="https://www.{{_global.whiteLabel.wlsName}}.com">https://www.{{_global.whiteLabel.wlsName}}.com.</a>
        </p>
        <h1 class="sub-title">Authorized Users</h1>
        <p>
            Users that are authorized by the Customer, like independent contractors, agents, or employees, to use the
            Services and Documentation.
        </p>
        <h1 class="sub-title">User Subscriptions</h1>
        <p>
            The subscription purchased by Customer under this Agreement gives them and their Authorized Users access to
            the
            Services and Documentation.
        </p>
        <h1 class="sub-title">Customer Data</h1>
        <p>
            Data inputted by the Customer, Supplier, or Authorized Users. The Supplier may input Customer Data on behalf
            of
            the Customer for the purpose of using the Services or to facilitate the Customer's use of said Services.
        </p>
        <h1 class="sub-title">Normal Business Hours</h1>
        <p>
            For the purpose of this Agreement, "Normal Business Hours" means 9am to 5pm local Central Standard Time,
            Monday
            through Friday except holidays. If the Supplier works on weekends, these hours do not count as "Normal
            Business
            Hours" for the purpose of this Agreement.
        </p>
        <h1 class="sub-title">Software</h1>
        <p>
            The online software applications provided to the Customer by the Supplier as part of its Services.
        </p>
        <h1 class="sub-title">Virus</h1>
        <p>
            Anything (including software, file, code, or program) that could affect, impair, or prevent portions of the
            operations of Services or Documentation.</p>

        <div class="header">
            <p class="title">User Subscriptions Terms</p>
        </div>
        <p>
            The Supplier grants to the Customer a non-transferable, non-exclusive right to permit the Authorized Users
            to
            use the Services and
            Documentation during the Subscription Terms solely for internal business operations and for no other
            purpose.
        </p>
        <p>
            In regards to the Customer’s Authorized Users, the Customer agrees to:
        </p>
        <p class="p-4">

            <span class="flex">
                <span class="dot"></span>
                <p> Not exceed the maximum amount of Authorized Users based on the number of User Subscriptions
                    purchased;</p>
            </span>
            <span class="flex">
                <span class="dot"></span>
                <p> Not use more than one Authorized User per User Subscription;</p>
            </span>
            <span class="flex">
                <span class="dot"></span>
                <p> Not transmit or distribute any Viruses, or anything that could be considered harmful, threatening,
                    offensive, harassing, obscene, illegal,
                    sexually explicit, violent, discriminatory, or a direct encouragement to hurt a person or a person’s
                    property. </p>
            </span>
        </p>
        <p>
            Due to this clause, the Supplier reserves the right to remove and/or disable the Customer’s access to any
            material if the Customer willingly breaks
            or desires to break said clause.
        </p>
        <div class="header">
            <p class="title">Additional User Subscriptions Terms</p>
        </div>
        <p>
            During the Subscription Term, the Customer may purchase additional User Subscriptions in excess as indicated
            in
            "Fees and service term." To do so, the Customer must:
        </p>
        <p class="p-4">
            <li> Request the additional amount of User Subscriptions in writing to the Supplier.</li>
            <li>If the Supplier agrees, the Customer must pay the additional User Subscriptions based on the amount
                outlined
                in "Fees and service term."</li>
        </p>
        <div class="header">
            <p class="title">Fees and Service Term</p>
        </div>
        <p>
            As a part of this Agreement, the fees and service term on the Effective Date are as follows:
        </p>
        <h1 class="sub-title">Subscription Fees</h1>
        <p>
            Customer agrees to pay Supplier the fees as specified on the Suppliers pricing page or as otherwise agreed
            upon.
            Customer 's access to the
            Service may be suspended or terminated for non-payment.
        </p>
        <h1 class="sub-title">Additional User Subscription Fees</h1>
        <p>
            Customer may purchase additional Location Subscriptions; Amount to be determined based on location needs.
        </p>
        <h1 class="sub-title">Subscription Term</h1>
        <p>
            The initial subscription is open ended and will end when the customer provides 30-days written notice to
            cancel.
        </p>
        <h1 class="sub-title">Customer Data</h1>
        <p>
            The Customer affirms to the Supplier that the Customer Data will not violate the intellectual property
            rights or
            any other legal rights and will not
            break the provisions of any law, regulation, or statute in any jurisdiction under applicable laws.
        </p>
        <p>
            The Customer agrees to create a backup copy of data daily, sufficient enough to be restored should it need
            be.
            The Supplier is not responsible for
            any lost data the Customer incurs due to misuse, nor are they responsible for lost data incurred from a
            blackout
            or Virus.
        </p>
        <h1 class="sub-title">
            Support Services</h1>
        <p>
            The Supplier shall make available a help desk to the Customer during Normal Business Hours. The Supplier
            agrees
            to provide Support Services
            with equitable skill and care.</p>
<p>
    The Customer may use the help desk to request or receive Support Services, and the Supplier shall respond promptly. The Supplier may suspend Support Services if any amount due to be paid by the Customer to the Supplier is late by at least (30) days.
</p>
 <h1 class="sub-title">
    Charges & Payments</h1>
   <p>
    The Supplier shall provide invoices for the Charges to the Customer from time to time during the term. The Customer is required to pay the said Payments or Charges to the Supplier within (7) days after issuing the invoice.
   </p>

        <p>
            If the Customer does not pay any amount of the Charge due to the Supplier under this Agreement, the Supplier
            may
            charge interest at the rate of
            1.5% per month until the Customer pays off their debts.</p>
        <p>

            The Supplier reserves the right to increase the fees for the Services provided under this Agreement;
            however,
            the Supplier shall provide the
            Customer with a written notice of such fee increase at least 30 days prior to its effective date. In the
            event
            of a fee increase, the Customer shall
            have the option to cancel the Services within the 30-day notice period without incurring any additional
            charges
            or penalties.</p>
        <p>
            The Customer acknowledges that the fees may be subject to periodic adjustments at the Supplier's discretion.
            The
            updated fees will be reflected
            in the invoices issued by the Supplier to the Customer. Continued use of the Services after the fee increase
            shall constitute acceptance of the
            revised fees.</p>
        <p>
            Refund Policy can be found at:<a
                href="https://{{_global.whiteLabel.wlsName}}.com/return-refund-policy/">https://{{_global.whiteLabel.wlsName}}.com/return-refund-policy/.</a></p>
        <h1 class="sub-title">
            Limitation of Liability</h1>
        <p>
            The Supplier shall make available a help desk to the Customer during Normal Business Hours. The Supplier
            agrees
            to provide Support Services
            with equitable skill and care.</p>
        <p>
            Supplier shall not be held liable for:
        </p>
        <p class="p-4">


            <span class="flex">
                <span class="dot"></span>
                <p> Personal injury or death resulting from negligence.</p>
            </span>
            <span class="flex">
                <span class="dot"></span>
                <p>Loss of business, contracts, or opportunities.</p>
            </span>
            <span class="flex">
                <span class="dot"></span>
                <p>fraudulent misrepresentation.</p>
            </span>
            <span class="flex">
                <span class="dot"></span>
                <p>Loss of savings or profits.</p>
            </span>
            <span class="flex">
                <span class="dot"></span>
                <p>Laws not limited by this Agreement.</p>
            </span>
            <span class="flex">
                <span class="dot"></span>
                <p>Liabilities that may not be excluded under applicable law.</p>
            </span>
        </p>
        <h1 class="sub-title">
            Proprietary Rights</h1>
        <p>No term in this Agreement allows the transfer or assignment of any intellectual property rights from the
            Supplier
            to the Customer or from the
            Customer to the Supplier. The Supplier owns 100% of the Services and Documentation included in this
            Agreement.
        </p>
        <h1 class="sub-title">
            Terms and Termination</h1>
        <p>Either Party can terminate this Agreement by giving the other Party at least (30) days of written notice. If
            any
            Party breaches the contract terms,

            any Party can terminate the Agreement immediately. If either Party is dissolved, ceases conduct of all of
            its
            business, is unable to pay its debts, or
            becomes insolvent, the Agreement will be terminated according to the subscription terms.</p>
        <p>
            Privacy Policy can be found at:<a
                href="https://{{_global.whiteLabel.wlsName}}.com/privacy-policy/">https://{{_global.whiteLabel.wlsName}}.com/privacy-policy/.</a></p>
        <div class="header">
            <p class="title">General Terms</p>
        </div>
        <h1 class="sub-title">
            No waiver</h1>
        <p>A breach in said Agreement shall not be waived except with the written consent of the Party not responsible
            for
            the violation.</p>

        <h1 class="sub-title">
            Severability</h1>
        <p>If any provisions of this Agreement are determined to be unlawful or enforceable by any court or competent
            authority, all other provisions in this
            Agreement will continue in effect.</p>

        <h1 class="sub-title">
            Entire agreement</h1>
        <p>This Agreement includes the entire agreement between the Supplier and the Customer and replaces all past
            Agreements, understandings, and
            representations of the Parties, whether written or oral.
        </p>

        <h1 class="sub-title">
            Governing law</h1>
        <p>This Agreement shall be governed by and constructed in accordance with laws in the state of Texas. The
            parties
            agree that the courts of Texas
            have exclusive jurisdiction to settle any claim or dispute that arises out of this Agreement.
        </p>

        <div class="header">
            <p class="title">Biometric Data Consent for User Login</p>
        </div>
        <p>
            The Customer hereby agrees and provides informed consent for the collection, use, and storage of biometric
            data
            for the purpose of user login
            within my organization. The customer understands that it is their responsibility to notify users of the use
            of
            biometric data for user login. The
            customer further understands that biometric data refers to unique physical and behavioral characteristics,
            such
            as facial recognition, fingerprints,
            or voiceprints, that can be used to verify a users identity.

        </p>
        <p>
            The customer acknowledges that the collection of biometric data is voluntary, and the customer has the right
            to
            refuse to provide this data.
            However, the customer also understands that using biometric data for user login may provide users with a
            more
            convenient and secure way to
            access certain systems and services.
        </p>
        <p>
            The customer understands that biometric data will be collected, used, and stored in accordance with
            applicable
            laws and regulations, and will
            only be used for the specific purposes outlined in this consent form. The customer acknowledges that the
            biometric data for their organi zations
            employees will be securely stored and protected against unauthorized access, use, or disclosure.
        </p>
        <p>
            The customer understands that they may revoke this consent at any time by providing written notice to
            Supplier
            by emailing
            <a
                href="Support@{{_global.whiteLabel.wlName}}.com">Support@{{_global.whiteLabel.wlName}}.com.</a>. However, revoking this consent may affect their ability to access certain systems and
            services.
        </p>
        <p>
            The customer certifies that they have read and understood this consent form, and that they 've been given
            the
            opportunity to ask any questions
            they may have about the collection, use, and storage of users' biometric data.

        </p>
        <div class="header">
            <p class="title">No Disparagement</p>
        </div>
        <p>
            The customer hereby agrees to the following terms and conditions regarding online behavior and
            communications
            regarding the supplier.
        </p>
        <p class="p-4">
            <span class="flex">
                <span class="dot"></span>
                <p>The customer will not post or publish any information or comments, whether written, verbal, or
                    visual,
                    that
                    may be construed as slanderous
                    or defamatory towards the supplier or any of its employees, products, or services.</p>
            </span>
            <span class="flex">
                <span class="dot"></span>
                <p>The customer understands that any negative or damaging comments made about the supplier could harm
                    the
                    company 's reputation,
                    cause financial losses, and negatively impact the morale of its employees.</p>
            </span>
            <span class="flex">
                <span class="dot"></span>
                <p>The customer agrees to refrain from posting any such comments or engaging in any behavior that could
                    be
                    perceived as harmful or
                    malicious towards the supplier.</p>
            </span>
            <span class="flex">
                <span class="dot"></span>
                <p>The customer understands that the supplier reserves the right to take legal action against the
                    customer
                    if
                    they violate this agreement and
                    post any slanderous comments about the company or its employees, products, or services.</p>
            </span>
            <span class="flex">
                <span class="dot"></span>
                <p>The customer acknowledges that this agreement is binding and enforceable under the laws of the
                    suppliers
                    jurisdiction, and that their
                    electronic agreement to these terms and conditions constitutes a legal and valid agreement.</p>
            </span>
        </p>

        <div class="header">
            <p class="title"> PCI Compliance Responsibility</p>
        </div>
        <p>
            1. PCI Compliance and Responsibility
        </p>
        <span class="flex flex-column pl-4">
          <span class="flex flex-row">
               <span class="px-2">
            1.1.
           </span> <p>
                 PCI Compliance: The Subscriber acknowledges and understands that the Payment Card Industry (PCI)
                Data
                Security Standard (PCI DSS) is a set of security standards established by the PCI Security Standards
                Council
                to protect cardholder data and ensure secure payment transactions. The Subscriber agrees to comply with
                all
                applicable requirements of the PCI DSS.
            </p>
          </span>
         
          <span class="flex flex-row">
            <span class="px-2">
             1.2.
        </span> <p>
                 {{_global.whiteLabel.wlName}} as an “Out of Scope” Solution: The Subscriber acknowledges that {{_global.whiteLabel.wlName}}, our
                Software-as-a-Service (SaaS) solution, is an “Out of Scope” solution, meaning it does not process,
                store, or
                transmit any cardholder data. {{_global.whiteLabel.wlName}} does not fall under the scope of PCI compliance obligations.</p>
           </span>
           <span class="flex flex-row">
            <span class="px-2">
         1.3.
        </span>              <p>
                 Subscriber’s Responsibility: The Subscriber understands and accepts that it is solely responsible
                for
                maintaining PCI compliance within its own organization, including any cardholder data processing,
                storage,
                or transmission conducted by the Subscriber or its authorized users.</p>
</span>
        </span>
        <p>
            2. Subscriber’s Obligations
         </p>   
          <span class="flex flex-column pl-4">
  
            <span class="flex flex-row">
                <span class="px-2">
                    2.1.
            </span> 
            <p>
                     PCI Compliance Obligations: The Subscriber agrees to undertake all necessary measures to ensure
                    PCI
                    compliance within its organization, including but not limited to:</p></span>
                    <span class="flex flex-column pl-4">
                        <span class="flex flex-row">
                            <span class="px-2">
                         2.1.1.
                        </span> 
                        <p>
                             Familiarizing themselves with the requirements of the PCI DSS and determining which
                            requirements apply to their business operations;
                        </p>
                        </span>
                        <span class="flex flex-row">
                            <span class="px-2">
                         2.1.2.
                        </span> 
                        <p>
                             Conducting self-assessments or engaging a Qualified Security Assessor (QSA) to
                            evaluate
                            their organization’s compliance status;</p></span>
                            <span class="flex flex-row">
                                <span class="px-2">
                            2.1.3.
                            </span> 
                            <p>
                            Implementing appropriate security controls, such as firewalls, encryption, access
                            controls, and regular security monitoring, to protect cardholder data;</p>
                       </span>
                       <span class="flex flex-row">
                        <span class="px-2">
                     2.1.4.
                    </span> 
                       <p>
                             Educating their employees about PCI compliance, data security best practices, and
                            their
                            responsibilities in safeguarding cardholder data;</p>
                        </span>
                        <span class="flex flex-row">
                            <span class="px-2">
                         2.1.5.
                        </span> 
                        <p>
                             Periodically reviewing and updating security measures to adapt to emerging threats and
                            changes in their business environment.</p></span>

                    </span>
                    <span class="flex flex-row">
                        <span class="px-2">
                     2.2.
                    </span> 
                    <p>
                    Limited Responsibility of {{_global.whiteLabel.wlName}}: The Subscriber acknowledges and agrees that {{_global.whiteLabel.wlName}}’s
                    responsibility is limited to ensuring the security of its systems and infrastructure that support
                    the
                    {{_global.whiteLabel.wlName}} platform. {{_global.whiteLabel.wlName}} does not process, store, or transmit any cardholder data and, therefore,
                    is
                    an “Out of Scope” solution for PCI compliance.</p></span>
                    <span class="flex flex-row">
                        <span class="px-2">
                     2.3.
                    </span> 
                    <p>
                    Indemnification: The Subscriber agrees to indemnify and hold harmless {{_global.whiteLabel.wlName}} and its
                    affiliates,
                    officers, directors, employees, and agents from any claims, damages, losses, or liabilities arising
                    out
                    of the Subscriber’s failure to maintain PCI compliance within its organization, including any
                    breaches
                    of cardholder data or non-compliance with PCI DSS requirements.</p></span>

            </span>
       
        <p>
            3. Disclaimer of Liability
        </p>
        <span class="flex flex-column pl-4">
            <p>
                3.1 No Liability for Subscriber’s PCI Non-Compliance: {{_global.whiteLabel.wlName}} shall not be liable for any losses,
                damages,
                costs, or expenses incurred by the Subscriber as a result of the Subscriber’s failure to maintain PCI
                compliance within its organization, including any fines, penalties, or assessments imposed by card
                brands or
                regulatory authorities.
            </p>
        </span>

        <div class="header">
            <p class="title">PCI Compliance Responsibility Cont'd</p>
        </div>

        <p>
            To Learn more about PCI Compliance policies and guidelines please review the following sources:
        </p>
        <span class="flex">
            <div class="dot"></div>
            <p>PCI Security Standards Council (SSC) Website: The official website of the PCI SSC
                (https://www.pcisecuritystandards.org) provides comprehensive information on PCI Compliance. It includes
                the
                PCI Data Security Standard (PCI DSS), Payment Application Data Security Standard (PADSS), and other
                relevant
                standards, guidelines, and resources.</p>
        </span>

        <span class="flex">
            <div class="dot"></div>
            <p>Self-Assessment Questionnaires (SAQs): The PCI SSC provides different SAQs tailored to different merchant
                environments. Merchants can access the appropriate SAQ for their business type and size to assess their
                PCI
                Compliance requirements and implementation.</p>
        </span>
        <span class="flex">
            <span class="dot"></span>
            <p>PCI Compliance Guides and Documentation: The PCI SSC offers various guides and documentation that provide
                detailed explanations, interpretations, and recommendations for achieving and maintaining PCI
                Compliance.
                These resources cover topics such as network security, encryption, vulnerability scanning, and more.</p>
        </span>
        <span class="flex">
            <span class="dot"></span>
            <p>
                Qualified Security Assessors (QSAs): Merchants can engage a Qualified Security Assessor (QSA) to conduct
                a
                formal assessment of their PCI Compliance status. QSAs are certified professionals who can evaluate the
                merchant’s compliance with the PCI DSS and provide guidance on remediation if needed.
            </p>
        </span>
        <span class="flex">
            <span class="dot"></span>
            <p>
                Acquiring Bank or Payment Processor: Merchants can consult with their acquiring bank or payment
                processor,
                as they often have resources and expertise in PCI Compliance. They can provide guidance, documentation,
                and
                assistance in understanding and meeting PCI requirements.
            </p>
        </span>
        <span class="flex">
            <span class="dot"></span>
            <p>Industry Associations and Forums: Industry-specific associations and forums may offer educational
                materials,
                webinars, or seminars on PCI Compliance. These resources can provide valuable insights and practical
                advice
                on meeting compliance obligations within specific industries.</p>
        </span>
        <span class="flex">
            <span class="dot"></span>
            <p>Security Consultants: Engaging a reputable security consulting firm that specializes in PCI Compliance
                can
                provide merchants with expert guidance and assistance in achieving and maintaining compliance. These
                consultants can assess the merchant’s systems, provide remediation recommendations, and help implement
                security controls.</p>
        </span>

    </div>
</div>