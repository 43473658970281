<p-overlayPanel
    #opActions
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            <div *ngFor="let act of actions">
                <button
                    pButton
                    pRipple
                    type="button"
                    [label]="act.label"
                    [class]="act.styleClass"
                    [icon]="act.icon"
                    [disabled]="act.disabled"
                    iconPos="left"
                    [style]="{ width: '200px', 'text-align': 'center' }"
                    (click)="actionButtonOptionsClicked(act.label)"
                ></button>
            </div>
            <!-- <button
                pButton
                pRipple
                type="button"
                label="Edit"
                class="p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-pencil"
                iconPos="right"
                (click)="optionEditClicked()"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Cancel"
                class="p-button-danger p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-trash"
                iconPos="right"
                (click)="optionEditClicked()"
            ></button> -->
        </div>
    </ng-template>
</p-overlayPanel>
<!-- ----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++ -->
<!-- ----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++ -->
<!-- ----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++ -->
<!-- ----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++----++++ -->

<!-- Email -->
<ng-container *ngIf="_purchaseService.isSendEmail">
    <p-dialog
        header="Purchase Order Email"
        [(visible)]="_purchaseService.isSendEmail"
        [style]="{ width: '60%' }"
        modal="true"
    >
    <app-po-send-email [emailData]="completeData"></app-po-send-email>
    </p-dialog>
</ng-container>

<!--/Email -->

<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'purchase-orders-list'"
        [statusList]="_purchaseService.purchaseStatusList"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
        (exportCsvFn)="dt.exportCSV()"
    >
    </app-report-top-filter-bar>

    <br />
    <p-table
        #dt
        [columns]=""
        styleClass="p-datatable-striped"
        id="table-001"
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        [value]="dataList"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="true"
        selectionMode="single"
        [(selection)]="selectedRow"
        dataKey="id"
        (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Sr#</th>
                <th>Date</th>
                <th>PO #</th>
                <th>Location</th>
                <th>Vendor</th>
                <th class="text-center">#Items</th>
                <th class="text-center">Sub-total ($)</th>
                <th class="text-center">Discount ($)</th>
                <th class="text-center">Net Cost ($)</th>
                <th class="text-center">Tax ($)</th>
                <th class="text-center">Total Cost ($)</th>
                <th class="text-center">PO Status</th>
                <th class="text-center">Inventory Status</th>
                <th>Action</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item" class="text-center">
                <td>{{ item.srNo }}</td>
                <td>{{ item.dated | date : "mediumDate" }}</td>
                <td>{{ item.poNumber }}</td>
                <td>{{ item.locationName }}</td>
                <td>{{ item.vendorName }}</td>
                <td class="text-center">
                    {{ item.totalQty | number : ".0-0" }}
                </td>
                <td class="text-center">{{ item.subTotal | currency }}</td>
                <td class="text-center">{{ item.discountTotal | currency }}</td>
                <td class="text-center">
                    {{ item.subTotal - item.discountTotal | currency }}
                </td>
                <td class="text-center">{{ item.taxTotal | currency }}</td>
                <td class="text-center">{{ item.grandTotal | currency }}</td>
                <td class="text-center">
                    <p-chip
                        [label]="
                            _purchaseService.getPurchaseStatusName(
                                item.poStatus
                            )
                        "
                        [styleClass]="
                            _purchaseService.getPoStatusClass(item.poStatus)
                        "
                    ></p-chip>
                </td>
                <td class="text-center">{{ item.inventoryStatus }}</td>
                
                <td class="text-center">
                    <button
                        pButton
                        pRipple
                        icon="pi pi-angle-down"
                        iconPos="right"
                        type="button"
                        label="Actions"
                        class="n-action-button"
                        (click)="opActions.toggle($event)"
                        (click)="actionButtonClicked(item)"
                    ></button>
                    <!-- [disabled]="doEnableActionButton(item.poStatus)" -->
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="14" style="text-align: center">
                        There is no data to show.
                    </td>
                </tr>
            </ng-template>
    </p-table>

    <br />
    <p-paginator
        #p
        *ngIf="dataList?.length"
        (onPageChange)="onPageChange($event)"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Records: {first} of {last}, Total Records: {totalRecords}"
    >
    </p-paginator>
</div>
