import { Component, OnInit } from '@angular/core';
import { BizzCustomersService } from 'src/app/_business-dashboard/_services/bizz-customers.service';
import { SubscriptionModel, BusinessSetupModel } from 'src/app/_models/subscription-management/subscription-model';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { SignupService } from 'src/app/_services/signup.service';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';
import { CurrentPlanGeneralInformation } from 'src/app/_models/subscription-management/current-plan-general-info';
import {
    PlanInfo,
    UserSubscriptionDetail,
    UserSubscriptionInfo,
} from 'src/app/_models/subscription-management/user-subscription-info';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-billing-address',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.scss']
})
export class BillingAddressComponent implements OnInit {
    
    _globals = GlobalService;
    stateOptions = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]

      invoiceDetail: any = []
      productsDetail: any = []


    dataList: any = [];
    totalRecords: number = 0;
    currentPlanSubscription = <UserSubscriptionDetail>{};
    loadingContent: boolean = false;
  planPricePerMonth: number = 0;
  basePlanPrice: number = 0;
  costHeading: string = "";
  userCurrentPlan = <PlanInfo>{};
  currentPlanGeneralInformation: CurrentPlanGeneralInformation[] = [];
  subscribedPackages: any[] = [];

    planInfo = <SubscriptionModel>{};
    businessSetup = <BusinessSetupModel>{}
    showChangeCardPopup :boolean = false;
    usertPaymentProfile= <any> {};
    constructor
    (
        private _subscriptionManagementService: SubscriptionPlanService,
        public _bizzCustomerService: BizzCustomersService,
        private _subscriptionPlanService: SubscriptionPlanService,
        private _abd: AppBaseDataService,
        public _signup: SignupService,
        private _router: Router,
        ) { }


    ngOnInit(): void {
        this.businessSetup = this._globals.userInfo.businessSetup[0];
        this.planInfo = this._globals.userInfo.businessSetup[0].subscription;
       if(this._globals.userInfo?.businessSetup[0]?.subscription?.transactionDetails?.paymentMethod === "Stripe"){
       this.usertPaymentProfile ={
        paymentProfile : {
            billTo : this._globals.userInfo?.businessSetup[0]?.subscription?.transactionDetails?.billTo,
            payment:{
                item:{
                    cardType:this._globals.userInfo?.businessSetup[0]?.subscription?.transactionDetails?.accountType,
                    cardNumber:this._globals.userInfo?.businessSetup[0]?.subscription?.transactionDetails?.accountNumber,
                }
            }
        }
       } 
       }else{
           this.getUserPaymentProfile();
       }
       console.log( this.usertPaymentProfile )
    }
    changeCard(){
        window.location.href = "/#/app/payment_process?pid="+this.usertPaymentProfile.paymentProfile.customerProfileId;
       // this.showChangeCardPopup = true
    }
    getUserPaymentProfile() {
        this._abd.showSpinner()
        this.loadingContent = true;
        this._subscriptionPlanService.getBusinessUserPaymentProfile().subscribe(
            (res: UserSubscriptionInfo) => {
                if (res.responseStatusCode == 903) {

                    this.usertPaymentProfile = res.data;
                    this.loadingContent = false;
                    this._abd.hideSpinner()
                }
            },
            (error) => {
                this.loadingContent = false;
            }
        );
    }
}
