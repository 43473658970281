<form [formGroup]="editform" autocomplete="off">
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
            <div class="p-field-checkbox">
                <p-checkbox
                    [ngModelOptions]="{ standalone: true }"
                    name="group11"
                    value="Taxable"
                    [binary]="true"
                    [(ngModel)]="_productService.isTaxable"
                    inputId="ny1"
                ></p-checkbox>
                <label for="ny1" style="margin-left: 10px"
                    >Product is Taxable</label
                >
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
            <div
                class="p-field-checkbox"
                style="margin-top: 10px"
                [ngClass]="_productService.isSellOnline ? 'disabled' : ''"
                [pTooltip]="
                    _productService.isSellOnline
                        ? 'Not valid for online products'
                        : 'Prompt for price will be displayed on Register'
                "
            >
                <p-checkbox
                    [ngModelOptions]="{ standalone: true }"
                    name="group11"
                    value="Open Price"
                    [binary]="true"
                    [disabled]="this._abd.selectedProductSellType.id != 2"
                    [(ngModel)]="_productService.isOpenPrice"
                    inputId="sf1"
                ></p-checkbox>
                <label for="sf1" style="margin-left: 10px"
                    >Open Price - Prompt for price on POS</label
                >
            </div>
        </div>
    </div>
    <br />
    <div class="grid">
        <div class="col-12 md:col-4 lg:col-4 xl:col-4">
            <div>
                <label class="control-label" style="margin-right: 15px"
                    ><strong
                        >Price A
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <br />

                <div class="p-grid">
                    <div class="p-col-12">
                        <div
                            class="p-inputgroup"
                            [style]="{ width: '100%', 'margin-top': '10px' }"
                        >
                            <span class="p-inputgroup-addon">$</span>
                            <p-inputNumber
                                [disabled]="_productService.isOpenPrice"
                                formControlName="priceA"
                                mode="decimal"
                                [format]="false"
                                [min]="0"
                                [minFractionDigits]="2"
                                [maxFractionDigits]="2"
                                (onBlur)="
                                    _productService.pts.priceA = $event.target.value
                                "
                            ></p-inputNumber>

                            <!-- <span class="p-inputgroup-addon">.00</span> -->
                        </div>
                        <span class="control-description text-muted">
                            Selling Price A, if $0.00 then can't sell online</span
                        >
                        <!-- <div *ngIf="editform.controls.priceA.errors" class="invalid-feedback">
                            Enter a valid Price
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-4 lg:col-4 xl:col-4">
            <div>
                <label class="control-label" style="margin-right: 15px"
                    ><strong
                        >Price B
                        <span
                            style="color: red; font-size: 1.25rem"
                        ></span> </strong
                ></label>
                <br />
                <div class="p-grid">
                    <div class="p-col-12">
                        <div
                            class="p-inputgroup"
                            [style]="{ width: '100%', 'margin-top': '10px' }"
                        >
                            <span class="p-inputgroup-addon">$</span>
                            <p-inputNumber
                                [disabled]="_productService.isOpenPrice"
                                formControlName="priceB"
                                mode="decimal"
                                [format]="false"
                                [min]="0"
                                [minFractionDigits]="2"
                                [maxFractionDigits]="2"
                                (onBlur)="
                                    _productService.pts.priceB = $event.target.value
                                "
                                ></p-inputNumber>
                            <!-- <span class="p-inputgroup-addon">.00</span> -->
                        </div>
                        <span class="control-description text-muted">
                            Selling Price B</span
                        >
                        <div
                            *ngIf="editform.controls.priceB.errors?.['nonzerovalidate']"
                            class="invalid-feedback"
                        >
                            Price B cannot be less than zero!
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-4 lg:col-4 xl:col-4">
            <div>
                <label class="control-label" style="margin-right: 15px"
                    ><strong
                        >Price C
                        <span
                            style="color: red; font-size: 1.25rem"
                        ></span> </strong
                ></label>
                <br />
                <div class="p-grid">
                    <div class="p-col-12">
                        <div
                            class="p-inputgroup"
                            [style]="{ width: '100%', 'margin-top': '10px' }"
                        >
                            <span class="p-inputgroup-addon">$</span>
                            <p-inputNumber
                                [disabled]="_productService.isOpenPrice"
                                formControlName="priceC"
                                mode="decimal"
                                [format]="false"
                                [min]="0"
                                [minFractionDigits]="2"
                                [maxFractionDigits]="2"
                                (onBlur)="
                                    _productService.pts.priceC = $event.target.value
                                "
                            ></p-inputNumber>
                            <!-- <span class="p-inputgroup-addon">.00</span>-->
                        </div>
                        <span class="control-description text-muted">
                            Selling Price C</span
                        >
                        <div
                            *ngIf="editform.controls.priceC.errors?.['nonzerovalidate']"
                            class="invalid-feedback"
                        >
                            Price C cannot be less than zero!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="grid">
        <div class="col-12 md:col-4 lg:col-4 xl:col-4">
            <div>
                <label class="control-label" style="margin-right: 15px"
                    ><strong
                        >Default Price
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <br />

                <div class="p-grid">
                    <div class="p-col-12">
                        <div
                            class="p-inputgroup"
                            [style]="{ width: '100%', 'margin-top': '10px' }"
                        >
                            <p-dropdown
                                [disabled]="_productService.isOpenPrice"
                                [options]="_productService.productInfo.defaultPrice"
                                [(ngModel)]="selectedDefaultPrice"
                                optionLabel="name"
                                (onChange)="defaultPriceChange($event)"
                                [ngModelOptions]="{standalone: true}"
                                [style]="{ width: '100%' }"
                            >
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <span class="control-description text-muted">
                    Set a default price at product to be sold</span
                >
            </div>
        </div>
        <div class="col-12 md:col-4 lg:col-4 xl:col-4">
            <div>
                <label class="control-label" style="margin-right: 15px"
                    ><strong
                        >Cost Price
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <br />
                <div class="p-grid">
                    <div class="p-col-12">
                        <div
                            class="p-inputgroup"
                            [style]="{ width: '100%', 'margin-top': '10px' }"
                        >
                            <span class="p-inputgroup-addon">$</span>
                            <p-inputNumber
                                formControlName="costPrice"
                                mode="decimal"
                                [minFractionDigits]="2"
                                [maxFractionDigits]="2"
                                [format]="false"
                                [min]="0"
                                (onBlur)="
                                    _productService.pts.costPrice =
                                        $event.target.value
                                "
                            ></p-inputNumber>
                            <!-- <span class="p-inputgroup-addon">.00</span> -->
                        </div>
                        <span class="control-description text-muted">
                            Cost price for reporting purpose</span
                        >
                        <div
                            *ngIf="editform.controls.costPrice.errors?.['nonzerovalidate']"
                            class="invalid-feedback"
                        >
                            Cost Price cannot be less than zero!
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-4 lg:col-4 xl:col-4">
            <div style="display: none">
                <label class="control-label" style="margin-right: 15px"
                    ><strong
                        >Discount Price
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                >
                <br />
                <div class="p-grid">
                    <div class="p-col-12">
                        <div
                            class="p-inputgroup"
                            [style]="{ width: '100%', 'margin-top': '10px' }"
                        >
                            <span class="p-inputgroup-addon">$</span>
                            <p-inputNumber
                                formControlName="discountPrice"
                                mode="decimal"
                                [minFractionDigits]="2"
                                [maxFractionDigits]="2"
                                (onBlur)="
                                    _productService.pts.discountPrice =
                                        $event.target.value
                                "
                            ></p-inputNumber>
                            <!-- <span class="p-inputgroup-addon">.00</span> -->
                        </div>
                        <span class="control-description text-muted">
                            Default discount price
                        </span>
                        <div
                            *ngIf="editform.controls.discountPrice.errors?.['nonzerovalidate']"
                            class="invalid-feedback"
                        >
                            Discount Price cannot be less than zero!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="grid">
        <div class="col">
            <div>
                <label class="control-label"
                    ><strong
                        >Tax Rate
                        <!-- <span style="color: red; font-size: 1.25rem">*</span> -->
                    </strong></label
                >
                <br />
                <p-dropdown
                *ngIf="_productService.isTaxable"
                    [ngModelOptions]="{ standalone: true }"
                    [options]="_productService.taxRates"
                    [(ngModel)]="_productService.selectedTaxRate"
                    optionLabel="name"
                    [disabled]="!_productService.isTaxable"
                    [style]="{ width: '100%', 'margin-top': '10px' }"
                >
                </p-dropdown>
            </div>
            <!-- <span
                class="control-description text-muted"
                *ngIf="_productService.isTaxable"
            >
                Default Tax Rate is 8.25%
            </span> -->
            <span
                class="control-description text-muted"
                *ngIf="!_productService.isTaxable"
            >
                Product is not taxable
            </span>
        </div>
        <div class="col">
            <div>
                <label class="control-label"
                    ><strong
                        >Sell Quantity
                        <!-- <span style="color: red; font-size: 1.25rem">*</span> -->
                    </strong></label
                >
                <br />
                <p-dropdown
                    [ngModelOptions]="{ standalone: true }"
                    [options]="_productService.productInfo.sellQuantity"
                    [(ngModel)]="_productService.selectedSellQuantity"
                    (onChange)="sellQtyChanged($event)"
                    optionLabel="name"
                    [style]="{ width: '100%', 'margin-top': '10px' }"
                >
                </p-dropdown>
            </div>
            <span class="control-description text-muted">
                Set single if product is sold individually
            </span>
        </div>
        <div
            class="col"
            *ngIf="_productService.selectedSellQuantity.value === 2"
        >
            <div>
                <label class="control-label"
                    ><strong>Case Quantity</strong></label
                >
                <br />
                <p-inputNumber
                    formControlName="caseQty"
                    [style]="{ width: '100%', 'margin-top': '12px' }"
                    (onBlur)="_productService.pts.caseQty = $event.target.value"
                ></p-inputNumber>
            </div>
            <span class="control-description text-muted">
                How much quantity in a case</span
            >
        </div>
        <div class="col">
            <div>
                <label
                    ><strong
                        >UOM (Unit of Measure)
                        <!-- <span style="color: red; font-size: 1.25rem">*</span> -->
                    </strong></label
                >
                <br />
                <p-dropdown
                    [ngModelOptions]="{ standalone: true }"
                    [options]="_abd.uomCalc"
                    [(ngModel)]="_abd.selectedUom"
                    optionLabel="unitName"
                    [style]="{ width: '100%', 'margin-top': '10px' }"
                >
                </p-dropdown>
            </div>
            <span class="control-description text-muted">
                How product is measured
            </span>
        </div>
    </div>
</form>
