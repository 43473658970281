import { GlobalService } from 'src/app/_services/global.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-product-inventory',
  templateUrl: './product-inventory.component.html',
  styleUrls: ['./product-inventory.component.scss']
})
export class ProductInventoryComponent implements OnInit {
  editform: FormGroup
  _globals=GlobalService

  constructor(
    public _productServices: ProductsService,
    private _fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    if(!this._productServices.pts.sku) this._productServices.pts.sku=this._globals.generateSKU()
    if(!this._productServices.pts.barcode) {
      this._productServices.pts.barcode=this._globals.generateSKU()
      this._productServices.pts.barcode = ((<any>this._productServices.pts.barcode * 1) + 23) + ''
    }
    this.initForm()
  }

  initForm() {
    this.editform = this._fb.group({
      sku: [this._productServices.pts.sku, Validators.required],
      barcode: [this._productServices.pts.barcode, Validators.required],
      maxLevel: [this._productServices.pts.maxLevel],
      minLevel: [this._productServices.pts.minLevel],
      reorderLevel: [this._productServices.pts.reorderLevel],
      minOrderQty: [this._productServices.pts.minOrderQty],
    })
  }

  isFormValid() {
    return this.editform.valid
  }

}
