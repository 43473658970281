<div class="conn-grid-3x" *ngIf="_abd.actLocations.length > 0">
    <div *ngIf="_abd.actLocations.length > 0">
        <label class="control-label" style="margin-right: 15px"
            ><strong
                >Select Locations
                <span style="color: red; font-size: 1.25rem"></span> </strong
        ></label>
        <!-- [(ngModel)]="_productService.selectedLocations" -->
        <p-multiSelect
            [disabled]="!this._productService.isNew"
            [options]="_abd.locationsBS$ | async"
            [(ngModel)]="_productService.selectedLocations"
            optionLabel="locationName"
            display="chip"
            appendTo="body"
            [style]="{ width: '100%', 'margin-top': '10px' }"
        ></p-multiSelect>
    </div>
    <div></div>
    <div></div>
</div>

<div class="p-grid">
    <br />
    <p-table
        #dt
        [autoLayout]="true"
        [tableStyle]="{ width: 'max-content' }"
        [value]="_productService.selectedLocations"
        responsiveLayout="scroll"
        showLoader="true"
        rowHover="false"
        [customSort]="true"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Location</th>
                <th style="text-align: center; width: 200px">
                    Opening Quantity
                </th>
                <ng-container *ngIf="!this._productService.isNew">
                    <!-- <th style="text-align: center; width:200px;"> QOH</th> -->
                    <th style="text-align: center; width: 200px">New QOH</th>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>
                    {{ item.locationName | slice : 0 : 35 }}
                    <span *ngIf="item.locationName.length > 35">...</span>
                </td>
                <td style="text-align: center">
                    <span class="p-input-icon-right">
                        <!-- <i class="pi pi-spin pi-spinner" ></i> -->
                        <p-inputNumber
                            [disabled]="!this._productService.isNew"
                            [(ngModel)]="item.openingStock"
                            [min]="0"
                            [max]="calcMax(item)"
                            [inputStyle]="{
                                'text-align': 'center',
                                width: '100px'
                            }"
                        ></p-inputNumber>
                    </span>
                </td>
                <ng-container *ngIf="!this._productService.isNew">
                    <!-- <td style="text-align: center">
                    <label [disabled]="true" >{{item.stockOnHandQoh}}</label>
                </td> -->
                    <td style="text-align: center">
                        <span class="p-input-icon-right">
                            <i class="pi pi-spin pi-spinner" *ngIf="!loading"></i>
                            <p-inputNumber
                                [(ngModel)]="item.stockOnHand"
                                [disabled]="!this._productService.isEdit || !loading"
                                [inputStyle]="{
                                    'text-align': 'center',
                                    width: '100px'
                                }"
                                (onChange)="changeQOH($event)"
                            ></p-inputNumber>
                        </span>
                    </td>
                </ng-container>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="conn-grid" *ngIf="_abd.actLocations.length === 0">
    <span class="control-description text-muted">
        No location exists to manage Stock, please add location first!</span
    >
    <br />
    <p-button (onClick)="gotoaddlocation()">Add Location</p-button>
</div>
