<!-- <div class="grid">
    <div class="mb-12 mt-6">
        <h1 class="fw-bolder wizard-title-inner d-flex align-items-center text-dark mx-2">
            General Info
        </h1>

        <div class="fs-6 mx-2 text-muted">
            Set some generic info about your business.
        </div>
    </div>
</div> -->

<br />
<br />

<form [formGroup]="editform" (ngSubmit)="editform.valid && saveFn()" autocomplete="off">

    <label class="control-label"><strong>Company Name
        <span style="color: red; font-size: 1.25rem">*</span>
    </strong></label>
    <app-text-input [formControl]="$any(editform.controls)['companyName']"
                    [label]="'Company Name'"
                    [name]="'Company Name'"
                    [description]="'This will update the company name on the dashboard. However, your domain name will stay the same as you entered during signup.'">
    </app-text-input>
    


    <div class="row" style="margin-top: 40px; margin-right: -5px">

        <label class="control-label"><strong>Type of Industry
            <span style="color: red; font-size: 1.25rem">*</span>
        </strong></label>
    </div>
    <div class="flex" *ngIf="_globals.userInfo">
        <div class="flex-1 flex align-items-center justify-content-center m-2" *ngFor="let item of _abd.industryTypes">
            <button type="button" [ngClass]="
                        _businessSetup.activeItem.industryType == item.id
                            ? 'n-button-active'
                            : 'n-button'
                    " (click)="selectIndustryType(item)">
                <p class="n-head-label">{{ item.name }}</p>
                <p [ngClass]="
                            _businessSetup.activeItem.industryType == item.id
                                ? 'n-desc-label-active'
                                : 'n-desc-label'
                        ">
                    {{ item.desc }}
                </p>
            </button>
        </div>
    </div>

    <!-- Business Phone + Email -->
    <div class="grid">
        <div class="col">
            <div class="row" style="margin-top: 40px;  margin-right: -5px">
                <div class="col-12">
                    <label class="control-label"
                        ><strong
                            >Business Phone
                            <span style="color: red; font-size: 1.25rem">*</span>
                        </strong></label
                    >
                    <br />
    
                    <!-- <div class="p-inputgroup" style="margin-top: 9px">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon"
                                ><img
                                    src="assets/images/us-flag-icon.png"
                                    style="width: 25px"
                            /></span>
                            <div style="width: 100%">
                                <p-inputMask
                                    [style]="{width: '100%'}"
                                    mask="(999) 999-9999"
                                    [formControl]="$any(editform.controls)['companyPhone']"
                                    [name]="'Phone'"
                                ></p-inputMask>
                            </div>
                        </div>
                    </div> -->
                    <app-phone-input [formControl]="$any(editform.controls)['companyPhone']"></app-phone-input>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="row" style="margin-top: 40px; margin-right: -5px">
                <div class="col-12">
                    <label class="control-label">
                        <strong
                            >Business Email
                            <span style="color: red; font-size: 1.25rem"
                                >*</span
                            >
                        </strong></label
                    >
                    <app-text-input
                        [formControl]="$any(editform.controls)['companyEmail']"
                        [name]="'Company Email'"
                        [label]="'Company Email'"
                    ></app-text-input>
                    </div>
                </div>
                <div class="fs-6 mx-2 text-muted">
                    We will use this email to contact your business.
                </div>
            </div>
    </div>

    <!-- 3rd row -->
    <div class="row" style="margin-top: 20px; margin-right: -5px">
        <div class="col-12">
            <label class="control-label"><strong>Address
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label>
            <app-text-input formControlName="companyAddress" [label]="'Address'" [name]="'address'" 
                ></app-text-input>
        </div>
    </div>

    <!-- 4th row -->
    <div class="conn-grid-3x" style="margin-top: 40px; margin-right: -5px; margin-left: 5px">
        <div>
            <label class="control-label"><strong>Zip Code
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label>
            <br />
            <p-inputMask formControlName="companyZip"
                        mask="99999" 
                        (onInput)="zipInputEvent($event.target.value)"
                        [style]="{ 'margin-top': '7px', width: '100%' }">
            </p-inputMask>
            <div *ngIf="f.companyZip.touched && f.companyZip.invalid" class="invalid-feedback">
                <div *ngIf="f.companyZip.errors.required">zip code is required.</div>
            </div>
        </div>
        <div>
            <label class="control-label"><strong>City
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label>
            <br />
            <span class="p-input-icon-right" [style]="{ width: '100%' }">
                <i class="pi pi-spin pi-spinner" *ngIf="showCityWait"></i>
                <app-text-input 
                    formControlName="companyCity" 
                    [label]="'City'" >
                </app-text-input>
            </span>
        </div>
        <div>
            <label class="control-label"><strong>State
                    <span style="color: red; font-size: 1.25rem">*</span>
                </strong></label>
            <br />
            <p-dropdown [style]="{
                    width: '100%',
                    'min-width': '200px',
                    'margin-top': '7px'
                }" 
                class="p-dropdown-1" appendTo="body" 
                [options]="usaStates"
                [(ngModel)]="selectedState" 
                optionLabel="name" 
                [filter]="true"
                filterBy="name" 
                [ngModelOptions]="{ standalone: true }" 
                [disabled]="isViewOnly">
            </p-dropdown>
        </div>
    </div>

    <div class="flex justify-content-end flex-wrap card-container green-container mt-3">
          <div class="flex align-items-center justify-content-center">
              <button
                  pButton
                  pRipple
                  type="submit"
                  label="Save"
                  [disabled]="!editform.valid"
              ></button>
          </div>
          <div
              class="flex align-items-center justify-content-center ml-2 mt-3"
              *ngIf ="singupBusinessConfig == true"
          >
              <button
                  pButton
                  pRipple
                  type="button"
                  label="Cancel"
                  class="p-button-outlined p-button-danger"
                  (click)="cancelButtonClick()"
                  style="width:130px;"
              ></button>
          </div>
    </div>


</form>