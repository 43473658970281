<div class=" subscription-page" *ngIf="!loadingContent">
    <div class="card border-dotted-custom" *ngIf="_bizzCustomerService.activeItem.isFreeTrial">
        <div class="flex  flex-wrap w-full  card-container s-container ">
            <div class="flex  justify-content-start flex-column flex-grow-1 ">
                <div class="w-12 flex align-items-center py-2 justify-content-start font-bold text-2xl">
                    Subscribed Plan: {{planName}} <button pButton pRipple type="button" label="Upgrade"
                        class="p-ripple p-element mx-3 p-button p-component"
                        (click)="changePlanButtonClicked()"></button>
                </div>
                <!-- <div class="w-12 flex align-items-center py-2 justify-content-start font-bold
                    text-xl billing-color" *ngIf="!_bizzCustomerService.activeItem.isFreeTrial">
                    Grand Total: {{_bizzCustomerService.activeItem.currentSubscription.total| currency}}
                    <span class="mx-1" *ngIf="planDuration == 1"> /month</span>
                    <span class="mx-1" *ngIf="planDuration == 12"> /year</span>

                </div> -->
                <div class="w-12 flex align-items-center py-2 justify-content-start text-lg
                    text-md link">

                    <div>
                        <h2>Welcome to {{who?.wlName}}</h2>
                        <p>Dear Valued {{who?.wlName}} User,</p>
                        <p>If you're considering upgrading to one of our subscription plans to safeguard your data and
                            continue enjoying {{who?.wlName}}, please feel free to reach out to our support team.</p>
                        <p>Thank you for considering {{who?.wlName}} for your needs.</p>

                    </div>
                </div>
                <span *ngIf="_bizzCustomerService.activeItem.isFreeTrial">
                    <div class="flex flex-row flex-wrap flex-grow-1  py-2 flex align-items-center
                        justify-content-start  text-lg" *ngFor="let info of currentPlanGeneralInformation">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link">
                            {{info.title}}
                        </div>
                        <div
                            class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 font-bold justify-content-start text-md link">
                            {{info.value}}
                        </div>
                    </div>
                </span>
                <!-- <span *ngIf="!_bizzCustomerService.activeItem.isFreeTrial">
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                        justify-content-start flex-grow-1 text-lg">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link">
                            Subscription Status
                        </div>
                        <div
                            class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 font-bold justify-content-start text-md link">
                           {{_bizzCustomerService.activeItem.currentSubscription.status ? 'Active': 'Inactive'}}
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                        justify-content-start flex-grow-1 text-lg">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link">
                            Expires on
                        </div>
                        <div
                            class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 font-bold justify-content-start text-md link">
                            {{_bizzCustomerService.activeItem.currentSubscription.subscriptionExpire|date:'M/d/yyyy'}}
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                        justify-content-start flex-grow-1 text-lg" *ngFor="let info of subscribedPackages">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link">
                            {{info.title}}
                            <span class="mx-1" *ngIf="info.qty> 0"> ({{info.qty}})</span>
                        </div>
                        <div
                            class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 font-bold justify-content-start text-md link">
                            {{info.totalPrice | currency}}
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                        justify-content-start flex-grow-1 text-lg">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link">
                          Tax
                        </div>
                        <div
                            class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 font-bold justify-content-start text-md link">
                            {{currentSubscription.tax | currency}}
                        </div>
                    </div>
                </span> -->


            </div>
        </div>
    </div>
    <div class="grid" *ngIf="!_bizzCustomerService.activeItem.isFreeTrial">
        <div class="col">
            <div class="conn-card">
                <h3 style="
                    margin-top: 0px;
                    font-size: 1.25rem;
                    color: var(--bs-active-primary);
                    font-weight: 700px;
                ">
                    Subscription Plan


                    <p-button label="Upgrade Plan" [disabled]="disabledTrue"
                        *ngIf="_globals.userInfo.businessSetup[0].isFreeTrial" (click)="upgradePlan()"></p-button>

                </h3>

                <p-divider></p-divider>

                <span *ngIf="!_bizzCustomerService.activeItem.isFreeTrial">
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                    justify-content-start flex-grow-1 text-lg">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link nas-label">
                           Current Subscription Plan
                        </div>
                        <div
                            class="flex xxl:col-9 xl:col-9 lg:col-8 md:col-8 font-bold justify-content-start text-md link nas-value">
                            {{planName}}
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                justify-content-start flex-grow-1 text-lg">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link nas-label">
                            Subscription Status
                        </div>
                        <div
                            class="flex xxl:col-9 xl:col-9 lg:col-8 md:col-8 font-bold justify-content-start text-md link nas-value">
                            {{_bizzCustomerService.activeItem.currentSubscription?.status ? 'Active': 'Inactive'}}
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                    justify-content-start flex-grow-1 text-lg">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link nas-label">
                            Payment Interval
                        </div>
                        <div
                            class="flex xxl:col-9 xl:col-9 lg:col-8 md:col-8 font-bold justify-content-start text-md link nas-value">
                            {{ _bizzCustomerService.activeItem.currentSubscription.subscriptionInterval == 1 ? 'Monthly'
                            : 'Yearly' }}
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                justify-content-start flex-grow-1 text-lg">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link nas-label">
                            Fixed Charges (monthly/yearly)
                        </div>
                        <div
                            class="flex xxl:col-9 xl:col-9 lg:col-8 md:col-8 font-bold justify-content-start text-md link nas-value">
                            {{_bizzCustomerService.activeItem.currentSubscription.total| currency}}
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                justify-content-start flex-grow-1 text-lg">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link nas-label">
                            Next Payment Due Date
                        </div>
                        <div
                            class="flex xxl:col-9 xl:col-9 lg:col-8 md:col-8 font-bold justify-content-start text-md link nas-value">
                            {{_bizzCustomerService.activeItem.currentSubscription.subscriptionExpire|date:'M/d/yyyy'}}
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap  py-2 flex align-items-center
            justify-content-start flex-grow-1 text-lg"
                        *ngIf="!_bizzCustomerService.activeItem.isFreeTrial && !_bizzCustomerService.activeItem.currentSubscription.lastChargeDate=='-'">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link nas-label">
                            Last Charge
                        </div>
                        <div *ngIf="!_bizzCustomerService.activeItem.isFreeTrial && !_bizzCustomerService.activeItem.currentSubscription.lastChargeDate=='-'"
                            class="flex xxl:col-9 xl:col-9 lg:col-8 md:col-8 font-bold justify-content-start text-md link nas-value">

                            {{_bizzCustomerService.activeItem.currentSubscription.lastChargeDate |date:'M/d/yyyy'}}
                        </div>

                    </div>
                    <!-- <div class="flex flex-row flex-wrap  py-2 flex align-items-center
            justify-content-start flex-grow-1 text-lg">
                        <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link nas-label">
                            Applied Coupon
                        </div>
                        <div class="flex xxl:col-9 xl:col-9 lg:col-8 md:col-8 font-bold justify-content-start text-md link nas-value">

                            {{_bizzCustomerService.activeItem.invoice.couponCode ? _bizzCustomerService.activeItem.invoice.couponCode : "N/A"  }}
                        </div>

                    </div> -->
                    <!-- <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                    justify-content-start flex-grow-1 text-lg" *ngFor="let info of subscribedPackages">
                    <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link">
                        {{info.title}}
                        <span class="mx-1" *ngIf="info.qty> 0"> ({{info.qty}})</span>
                    </div>
                    <div
                        class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 font-bold justify-content-start text-md link">
                        {{info.totalPrice | currency}}
                    </div>
                </div>
                <div class="flex flex-row flex-wrap  py-2 flex align-items-center
                    justify-content-start flex-grow-1 text-lg">
                    <div class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 attribute-color text-md link">
                      Tax
                    </div>
                    <div
                        class="flex xxl:col-3 xl:col-3 lg:col-4 md:col-4 font-bold justify-content-start text-md link">
                        {{currentSubscription.tax | currency}}
                    </div>
                </div> -->
                </span>

                <!-- <ng-container *ngFor="let inv of invoiceDetail; let i = index">
                <br />
                <div class="nas-grid-2x">
                    <div class="nas-label">{{ inv.label }}
                    </div>
                    <div class="nas-value" *ngIf="inv.label.includes('Date')">
                        {{ inv.value | date: "fullDate" }}
                    </div>
                    <div class="nas-value" *ngIf="!inv.label.includes('Date')">
                       <span *ngIf="i != 4"> {{ inv.value }}</span>
                       <span *ngIf="i == 4"> {{ inv.value | currency}}</span>
                    </div>
                </div>
            </ng-container> -->

                <br />
                <br />

                <div class="grid">
                    <div class="col headingCol">
                        <h3 style="
                        margin-top: 10px;
                        margin-bottom: 0px;
                        font-size: 1.25rem;
                        color: var(--bs-active-primary);
                        font-weight: 700px;
                    ">
                           Subscription Details
                        </h3>
                    </div>
                    <div class="col buttonCol">

                        <button pButton type="button" label="Purchase Addons" style="width: 170px"
                            (click)="purchaseAddonHandler()"></button>
                    </div>
                </div>

                <p-divider></p-divider>
                <br />
                <br />
                <p-table styleClass="p-datatable-striped" [tableStyle]="{ width: 'max-content' }"
                    [value]="subscribedPackages" responsiveLayout="scroll" rowHover="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Subscription/Addons</th>
                            <th style="text-align: center">Qty</th>
                            <th style="text-align: center">Price</th>
                            <th style="text-align: center">Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-info>
                        <tr>
                            <td> {{info.title}}</td>
                            <td style="text-align: center">

                                <ng-container
                                    *ngIf="info.title=='Allowed Locations' || info.title=='Allowed Registers'">
                                    {{info.qty}}
                                </ng-container>
                            </td>

                            <td style="text-align: center">{{info.totalPrice | currency }}</td>
                            <td style="text-align: center">
                                <button pButton type="button" label="Change Plan" style="width: 170px"
                                    (click)="upgradePlan()" *ngIf="info.packageTypeString=='Subscription' && _global.whiteLabel?.isChangePlan"></button>
                                <button pButton type="button" label="Add More" style="width: 170px"
                                    (click)="buyLocationFormHandler()"
                                    *ngIf="info.directPurchase && info.packageTypeString =='Locations' && _global.whiteLabel?.isAddLocations"></button>
                                <button pButton type="button" label="Add More" style="width: 170px"
                                    (click)="buyRegisterFormHandler()"
                                    *ngIf="info.directPurchase && info.packageTypeString =='Registers' && _global.whiteLabel?.isAddRegidters"></button>
                                <button pButton type="button" label="Unsubscribe" style="width: 170px"
                                    (click)="generateSupportRequest('Unsubscribe Request ! for '+ info.title +' Subscription ')"
                                    *ngIf="info.directPurchase==false && info.packageTypeString !='Locations' && info.packageTypeString !='Registers'"></button>
                                <!-- <button
                        pButton
                        type="button"
                        label="Support Request"
                        style="width: 170px"
                        (click)="generateSupportRequest()"
                        *ngIf="info.directPurchase==false  && info.packageTypeString !='Subscription' && (info.packageTypeString !='BasicLoyalty')"
                    ></button> -->

                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                         <tr>

                            <td style="text-align: left">Sub Total</td>
                            <td></td>
                            <td style="text-align: center"> {{ currentSubscription.subTotal | currency }}</td>
                            <td></td>
                        </tr>
                        <!-- <tr>

                            <td style="text-align: left">Discount</td>
                            <td></td>
                            <td style="text-align: center"> ${{ currentSubscription.tax.totalDiscountAmount === 0 ? "0.00" : _bizzCustomerService.activeItem.invoice.totalDiscountAmount }}</td>
                            <td></td>
                        </tr> -->
                        <tr>

                            <td style="text-align: left">Tax</td>
                            <td></td>
                            <td style="text-align: center"> {{currentSubscription.tax | currency}}</td>
                            <td></td>
                        </tr>
                          <!-- New -->
                           <tr>

                            <td style="text-align: left">Total Charges</td>
                            <td></td>
                            <td style="text-align: center">{{_bizzCustomerService.activeItem.currentSubscription.total |
                                currency}}</td>
                            <td></td>
                        </tr>


                    </ng-template>
                    <ng-template pTemplate="emptymessage" style="text-align: center">
                        <tr>
                            <td colspan="17" style="text-align: center">
                                No data found
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <button *ngIf="!cancellationRequest && !_bizzCustomerService.activeItem.isFreeTrial"
                    [disabled]="disabledTrue" pButton pRipple type="button" label="Cancel Subscription"
                    class="p-button-text" (click)="cancelSubscription('c')"></button>
            </div>
        </div>
    </div>
    <!-- <div class="grid">
        <div class="col">
            <div class="conn-card">
                <app-bizz-customer-personal-info></app-bizz-customer-personal-info>
            </div>
        </div>
    </div> -->
    <!-- <div class="nas-grid-2x">
        <div class="col" *ngIf="_bizzCustomerService.activeItem.subscriptionInvoice?.plans">
            <div class="conn-card">
                <app-bizz-customer-subscription-plan-details></app-bizz-customer-subscription-plan-details>
            </div>
        </div>
        <div class="col"
            *ngIf="_bizzCustomerService.activeItem.subscriptionInvoice?.hardwarePkg && !_bizzCustomerService.activeItem.isFreeTrial">
            <div class="conn-card">
                <app-bizz-customer-hardware-details></app-bizz-customer-hardware-details>
            </div>
        </div>
    </div> -->

    <!-- <div class="grid"
        *ngIf="_bizzCustomerService.activeItem.isSubscriptionCompleted && !_bizzCustomerService.activeItem.isFreeTrial">
        <div class="col">
            <div class="conn-card">
                <app-bizz-customer-subscription-invoice></app-bizz-customer-subscription-invoice>

            </div>
        </div>
    </div> -->
    <!--
    <div class="flex justify-content-end">
        <p>Looking for a custom plan? <a href="#"> <span class="font-bold text-lg" style="color: #00457E;">Contact
                    Us</span><i class="pi pi-arrow-up-right
                    " style="font-size: 10px;
                        background: #00457E;
                        color: white;
                        padding: 4px;
                        border-radius: 15%;
                        margin-left: 5px;"></i>
            </a>
        </p>
    </div>
     <div class="card p-0 border-dotted-custom plan-container">
        <div class="flex flex-row flex-wrap w-full  card-container ">
            <div class="  flex justify-content-start border-dotted-custom  px-0 text-lg flex-grow-1"
                [ngClass]="{'xxl:col-4':item.isTrial, 'xxl:col-2': !item.isTrial, 'hidden-991': i==0 }"
                *ngFor="let item of planDetails; let i= index">
                <div class="  flex flex-column  flex-wrap px-0 text-lg w-full">
                    <div class="plan-info-container flex flex-column justify-content-center" [ngClass]="{'subscribed-plan':item.currentUserSelectedPlan ,'dotted-border':
                    item.currentUserSelectedPlan,'dotted-border':
                    !item.currentUserSelectedPlan , 'background-none': i==0}">
                        <span *ngIf="!item.isTrial">
                            <div class="flex lite plan-title justify-content-center subscribed-plan-title">
                                {{item.title}}
                            </div>
                            <div class="flex plan-price justify-content-center py-4 text-2xl font-medium text-dark">
                                <div *ngIf="selectedBillingPeriod == 'Monthly'">{{item.monthlyPrice|currency}}
                                    <span style=" color: #c3c3c3; font-size: 13px"> / month</span>
                                </div>
                                <div *ngIf="selectedBillingPeriod == 'Yearly'">{{item.yearlyPrice|currency}}
                                    <span style=" color: #c3c3c3; font-size: 13px"> / year</span>
                                </div>
                            </div>
                            <div class="flex plan-price justify-content-center">
                                <button *ngIf="!item.currentUserSelectedPlan" pButton pRipple type="button"
                                    label="Select" class="unselected-btn" [disabled]="!_bizzCustomerService.activeItem.isFreeTrial"
                                    (click)="choseDifferentPlan(item)">

                                </button>
                                <p-button class="selected-btn subscribed-plan-button"
                                    *ngIf="item.currentUserSelectedPlan" label="Current Plan">
                                </p-button>
                            </div>
                        </span>
                    </div>


                    <div *ngIf="onDesktopScreen()">
                        <div class="cell px-0 flex flex-wrap break-word justify-content-center   py-2  text-lg"
                            [ngClass]="{

                            'justify-content-start':item.isTrial,
                            'cell-border': !item.isTrial,
                            'justify-content-center': !item.isTrial }" *ngFor="let feature of item.featureData">
                            <div class="flex" *ngIf="!item.isTrial">
                                {{getWordsWithSpace(feature.value)}}
                            </div>
                            <div class="flex px-4" *ngIf="item.isTrial">
                                {{getWordsWithSpace(feature.title)}}
                            </div>
                        </div>
                    </div>




                    <div *ngIf="onMobileScreen() ">
                        <div class="cell px-0 flex flex-wrap break-word justify-content-center py-2 text-lg" [ngClass]="{
                        'justify-content-start':item.isTrial,
                        'cell-border': !item.isTrial,
                        'justify-content-center': !item.isTrial }" *ngFor="let feature of item.features">
                            <div class="flex px-4">
                                {{getWordsWithSpace(feature.data)}}
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class=" card border-dotted-custom" *ngIf="_bizzCustomerService.activeItem.isFreeTrial">
        <div class="flex flex-row flex-wrap w-full  card-container ">
            <div class="flex xl:col-3 xxl:col-3  py-2 justify-content-center text-lg flex-grow-1 font-bold"
                *ngFor="let h of additionItemsPriceInfoHeader">
                {{h}}
            </div>
        </div>
        <div class="flex flex-row flex-wrap w-full  py-2 card-container " *ngFor="let h of additionItemsPriceInfo">
            <div
                class="flex xl:col-3 xxl:col-3 align-items-center justify-content-center text-lg font-bold flex-grow-1">
                {{h.items}}
            </div>
            <div class="flex xl:col-3 xxl:col-3 align-items-center justify-content-center text-lg flex-grow-1">
                <p-inputNumber [(ngModel)]="h.quantity" (onInput)="updateQuantity($event,h)"
                    (onClear)="resetQuantity(h)" [disabled]="h.disabled || additionalLocationNotAvailable"
                    [showButtons]="true" inputId="minmax-buttons" [min]="1" [max]="999">
                </p-inputNumber>
            </div>
            <div class="flex xl:col-3 xxl:col-3 align-items-center justify-content-center text-lg flex-grow-1">
                {{h.costOfAdditionalItems | currency}} / {{selectedBillingPeriod | lowercase}}
                each
            </div>
            <div class="flex xl:col-3 xxl:col-3 align-items-center justify-content-center text-lg flex-grow-1">
                {{h.actualPrice | currency}} * {{h.includeQuantity | number}} =
                {{h.totalCost | currency}}
            </div>
        </div>
    </div>
    <div class="card  border-dotted-custom mb-0 py-0 px-0" *ngIf="_bizzCustomerService.activeItem.isFreeTrial">
        <div class="flex flex-row flex-wrap w-full justify-content-end  py-2 card-container ">
            <div class="flex xl:col-3 xxl:col-3 align-items-center px-2 justify-content-end text-lg">
                Subscription Cost:
            </div>
            <div class="flex xl:col-3 xxl:col-3 align-items-center px-2 justify-content-center ">
                <span class="font-bold text-xl">{{subscriptionCost | currency}}</span> / billed
                {{selectedBillingPeriod | lowercase}}
            </div>
        </div>
        <div class="flex flex-row flex-wrap w-full justify-content-end  py-2 card-container ">
            <div class="flex xl:col-3 xxl:col-3 align-items-center px-2 justify-content-end text-lg">
                Additional Location(s):
            </div>
            <div class="flex xl:col-3 xxl:col-3 align-items-center px-2 justify-content-center ">
                <span class="font-bold text-xl">{{additionalLocationCost | currency}}</span> /
                billed
                {{selectedBillingPeriod | lowercase}}
            </div>
        </div>
        <div class="flex flex-row flex-wrap w-full justify-content-end  py-2 card-container ">
            <div class="flex xl:col-3 xxl:col-3 align-items-center px-2 justify-content-end text-lg">
                Additional Register(s):
            </div>
            <div class="flex xl:col-3 xxl:col-3 align-items-center px-2 justify-content-center ">
                <span class="font-bold text-xl">{{additionalRegisterCost | currency}}</span> /
                billed
                {{selectedBillingPeriod | lowercase}}
            </div>
        </div>
        <div class="card px-0 py-0 mb-0 mt-3 pt-2 border-dotted-custom border-radius-none">
            <div class="flex flex-row flex-wrap w-full py-4 justify-content-end card-container ">
                <div class="flex xl:col-3 xxl:col-3 align-items-center justify-content-end text-2xl px-2 font-bold">
                    Amount Due
                </div>
                <div class="flex xl:col-3 xxl:col-3 align-items-center px-2 justify-content-center text-lg">
                    <span class="font-bold text-2xl">{{grandBill | currency}}</span> / billed
                    {{selectedBillingPeriod | lowercase}}
                </div>
            </div>
        </div>
    </div>
    <div class="flex justify-content-end">
        <div class="flex py-4">
            <p-button class="text-2xl" label="Proceed Payment" (click)="paySubscriptionBill()"></p-button>
        </div>
    </div>-->
</div>
<div class="field xxl:col-12 xl:col-12 col-12 md:col-6" *ngIf="loadingContent">
    <p-skeleton styleClass="mb-2"></p-skeleton>
    <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton height="2rem" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="10rem" height="4rem"></p-skeleton>
</div>

<p-dialog header="Confirmation" [(visible)]="ShowCustomConfirmation" [modal]="true" [draggable]="false"
    [resizable]="false">
    <div *ngIf="upgradeSucessMessage.length==0" class="plan-upgrade-message">
        <strong>You have chosen to {{ CurrentAction==1 ? 'upgrade' : 'downgrade' }} your plan.</strong>
        <br><br>
        <table class="p-datatable-table ng-star-inserted upgrade-plan">
            <tr class="ng-star-inserted">
                <td>From: <strong>{{ planName }} plan</strong></td>
                <td><strong>${{ currentSubscriptionCharges }}</strong> {{
                    (this._bizzCustomerService.activeItem?.currentSubscription?.subscriptionInterval === 12
                    ? '/ Year '
                    : '/ Month') + '(Subscription + Addons + Tax)' }} </td>
            </tr>
            <tr class="ng-star-inserted">
                <td>To: <strong>{{ selectedPlanForSubscription.title }} plan</strong></td>
                <td><strong>${{ newSubscriptionCharges }}</strong> {{
                    (this._bizzCustomerService.activeItem?.currentSubscription?.subscriptionInterval === 12
                    ? '/ Year'
                    : '/ Month') + '(Subscription + Addons + Tax)' }} </td>
            </tr>
        </table>
        <ng-container *ngIf="subscriptionChangeNotes.length>0">

            <p-messages severity="info">
                <ng-template pTemplate>

                    <div class="ml-2">{{subscriptionChangeNotes}}</div>
                </ng-template>
            </p-messages>
        </ng-container>
        Would you like to proceed?
    </div>
    <div *ngIf="upgradeSucessMessage.length>0" class="plan-upgrade-message">
        <p>You have sucessfully updated your plan</p>
    </div>

    <div class="flex flex-wrap align-items-center justify-content-center m-2">
        <div class="flex align-items-center justify-content-center m-2">
            <button pButton type="button" label="Confirm" *ngIf="upgradeSucessMessage.length==0" class="ml-2"
                style="width: 160px" (click)="performAction()"></button>
            <button pButton type="button" label="Ok" class="ml-2" style="width: 160px"
                (click)="ShowCustomConfirmation=false" *ngIf="upgradeSucessMessage.length>0"></button>
            <button pButton type="button" label="Cancel" *ngIf="upgradeSucessMessage.length==0" class="ml-2"
                style="width: 160px" (click)="ShowCustomConfirmation=false"></button>

        </div>
    </div>

    <div style="color:red" *ngIf="ErrorMessage.length>0">
        <br>
        <br>
        {{ErrorMessage}}
    </div>
</p-dialog>



<p-dialog header="Confirmation" [(visible)]="showSubscriptionModal" [modal]="true" [style]="{ width: '50vw' }"
    [draggable]="true" [resizable]="false">
    <p class="m-0">
        Thank you for reaching out to us regarding your request. We have received your message and our customer support
        team will be in touch with you shortly to assist you with your query.
    </p>
</p-dialog>

<p-dialog header="Add Registers" [(visible)]="showBuyMoreRegisterForm" [blockScroll]="true" modal="true" [breakpoints]="{
        '960px': '80vw',
        '640px': '90vw',
        '250px': '100vw'
    }" [draggable]="false" [style]="{ width: '60vw' }">
    <ng-container>
        <p>
            You are going to change number of Registers available to you.
            Buying any additional location will be charged according to your selected payment interval and subscription
            plan.

        </p>
        <br />
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Current Registers
            </div>
            <div class="">
                <div class="">
                    <input type="number" min="0" placeholder="Enter a number" [(ngModel)]='currentRegisters.qty'
                        disabled pInputText style="width: 100%;" />
                </div>

            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Additional Registers
            </div>
            <div class="">
                <input type="number" min="0" placeholder="Enter a number" [(ngModel)]="buyRegQty" pInputText />
                <button pButton type="button" label="Update Cart" class="ml-2" style="width: 130px"
                    (click)="registerQtyChanged($event)" [disabled]="buyRegQty==0"></button>

            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Additional Charges ($)
            </div>
            <div class="">
                <input [(ngModel)]="buylocationList.additionalCost " [(ngModel)]='currentRegisters.qty' disabled
                    (onInput)="locationQtyChanged($event)" pInputText style="width: 100%;" />


            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">New Charges
            </div>
            <div class="">
                <p-inputNumber mode="currency" [(ngModel)]="buyRegisterList.additionalCost" currency="USD"
                    locale="en-US" [disabled]="true"></p-inputNumber>
            </div>
        </div>
        <div class="" *ngIf="buyRegisterList.note.length>0">
            <div class="nas-label">{{buyRegisterList.note}}
            </div>

        </div>
    </ng-container>

    <div class="flex justify-content-end flex-wrap">
        <!-- <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Support Request"
                class="ml-2"
                style="width: 160px"
            ></button>
        </div> -->
        <div class="flex align-items-center justify-content-center m-2">
            <button pButton type="button" label="Purchase" class="ml-2" style="width: 130px"
                (click)="postBuyRegisters()" [disabled]="buyRegQty==0"></button>
        </div>

    </div>
</p-dialog>

<p-dialog header="Add More Inventory Locations" [(visible)]="showBuyMoreLocationForm" [blockScroll]="true" modal="true"
    [draggable]="false" [breakpoints]="{
        '960px': '80vw',
        '640px': '90vw',
        '250px': '100vw'}" [style]="{ width: '60vw' }">
    <ng-container>
        <p>
            You are going to change number of locations available to you. Buying any additional location will be charged
            according to your selected payment interval and subscription plan.
            First register for every new location is free!.
        </p>
        <br />
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Current Inventory Locations
            </div>
            <div class="">
                <div class="">
                    <input type="number" min="0" placeholder="Enter a number" [(ngModel)]='currentLocations.qty'
                        disabled (onInput)="locationQtyChanged($event)" pInputText style="width: 100%;" />
                </div>

            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Additional Location to Buy
            </div>
            <div class="">
                <input type="number" min="0" placeholder="Enter a number" [(ngModel)]="buyLocQty" pInputText />
                <button pButton type="button" label="Update Cart" class="ml-2" style="width: 130px"
                    (click)="locationQtyChanged($event)" [disabled]="buyLocQty==0"></button>

            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Additional Charges ($)
            </div>
            <div class="">
                <input [(ngModel)]="buylocationList.additionalCost " placeholder="Enter a number"
                    [(ngModel)]='currentLocations.qty' disabled (onInput)="locationQtyChanged($event)" pInputText
                    style="width: 100%;" />


            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">New Charges
            </div>
            <div class="">
                <p-inputNumber mode="currency" [(ngModel)]="buylocationList.newSubscriptionCharges" currency="USD"
                    locale="en-US" [disabled]="true"></p-inputNumber>
            </div>
        </div>
        <div class="" *ngIf="buylocationList.note.length>0">
            <div class="nas-label">{{buylocationList.note}}
            </div>

        </div>
    </ng-container>
    <div class="flex justify-content-end flex-wrap">
        <!-- <div class="flex align-items-center justify-content-center m-2">
            <button
                pButton
                type="button"
                label="Support Request"
                class="ml-2"
                style="width: 160px"
            ></button>
        </div> -->
        <div class="flex align-items-center justify-content-center m-2">
            <button pButton type="button" label="Purchase" class="ml-2" style="width: 130px" (click)="postBuyLocation()"
                [disabled]="buyLocQty==0"></button>
        </div>

    </div>
</p-dialog>

<p-dialog header="Support Request" [(visible)]="showSupportRequestForm" [blockScroll]="true" modal="true" [breakpoints]="{
        '960px': '80vw',
        '640px': '90vw',
        '250px': '100vw'
    }" [draggable]="false" [style]="{ width: '40vw' }">
    <ng-container>
        <br />
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Subject
            </div>
            <div class="">
                <input type="text" [(ngModel)]="generalQuestion" pInputText style="width: 100%;" />
            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Anything to add?
            </div>
            <div class="">
                <textarea [rows]="5" [cols]="30" [(ngModel)]="generalMessage" pInputTextarea
                    style="width: 100%;">​</textarea>
            </div>
        </div>
    </ng-container>
    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button pButton type="button" label="Submit Request" class="ml-2" (click)="submitGeneralRequest()"></button>
        </div>
    </div>
</p-dialog>
<p-dialog header="Contact Support for Additional Support" [(visible)]="talkToExpertForm" [blockScroll]="true"
    modal="true" [breakpoints]="{
        '960px': '80vw',
        '640px': '90vw',
        '250px': '100vw'
    }" [draggable]="false" [style]="{ width: '40vw' }">
    <ng-container>
        <br />
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Your Question
            </div>
            <div class="">
                <input type="text" [(ngModel)]="addtionalAddonsQuestion" pInputText style="width: 100%;" />
            </div>
        </div>
        <div class="nas-grid-2x m-2">
            <div class="nas-label">Details
            </div>
            <div class="">
                <textarea [rows]="5" [cols]="30" [(ngModel)]="additionalAddonRequestionMessage" pInputTextarea
                    style="width: 100%;">​</textarea>

            </div>
        </div>
    </ng-container>
    <div class="flex justify-content-end flex-wrap">
        <div class="flex align-items-center justify-content-center m-2">
            <button pButton type="button" label="Submit Request" class="ml-2" style="width: 160px"
                (click)="submitTalkExpertFormRequest()"></button>
        </div>
    </div>
</p-dialog>

<p-dialog header="Addons and optional Features​" [(visible)]="showPurchaseAddon" [blockScroll]="true" modal="true"
    [breakpoints]="{
        '960px': '80vw',
        '640px': '90vw',
        '250px': '100vw'
    }" [draggable]="false" [style]="{ width: '40vw' }">
    <ng-container *ngIf="!_bizzCustomerService.activeItem.isFreeTrial">

        <p-table styleClass="p-datatable-striped" [tableStyle]="{ width: 'max-content'}"
            [value]="getSubPlan?.plans[0]?.featureData" responsiveLayout="scroll" rowHover="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>Addon</th>
                    <th style="text-align: left">Price</th>
                    <th style="text-align: left">Status</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-info>
                <!-- <tr *ngIf="info.typeString=='AdditionalLocationPlus1Reg'">
            <td>{{info.title}}</td>
            <td style="text-align: left">{{info.price | currency }} </td>
            <td style="text-align: left" >
                <button
                pButton
                type="button"
                label="Support Request"
                (click)="generateSupportRequest()"
            ></button>
            </td>
        </tr> -->
                <tr *ngIf="info.typeString=='StaticWebsite'">
                    <td>Website/ Basic eCommerce Store</td>
                    <td style="text-align: left"> Starting from {{info.price | currency }} </td>
                    <td style="text-align: left">
                        <button pButton type="button" label="Request Support"
                            (click)="generateSupportRequest('Support Request Website/ Basic eCommerce Store Subscription')"></button>
                    </td>
                </tr>
                <!-- <tr *ngIf="info.typeString=='BasiceCommerceStore'">
                    <td>{{info.title}}</td>
                    <td style="text-align: left">{{info.price | currency }} </td>
                    <td style="text-align: left">
                        <button pButton type="button" label="Support Request"
                            (click)="showPopuptoSelectWebsite()"></button>
                    </td>
                </tr> -->
                <tr *ngIf="info.typeString=='LoyaltyProgram'">
                    <td>{{info.title}}</td>
                    <td style="text-align: left">{{info.price | currency }} </td>
                    <td style="text-align: left">
                        <button pButton type="button" label="Request Support"
                            (click)="generateSupportRequest('Support Request Loyalty Program Subscription')"></button>
                    </td>
                </tr>
                <!-- <tr *ngIf="info.typeString=='ReportingAndBusinessAnalytics'">
                    <td>{{info.title}}</td>
                    <td style="text-align: left">{{info.price | currency }} </td>
                    <td style="text-align: left">
                        <button pButton type="button" label="Support Request"
                            (click)="generateSupportRequest('Support Request for advanc')"></button>
                    </td>
                </tr> -->
            </ng-template>
            <ng-template pTemplate="emptymessage" style="text-align: center">
                <tr>
                    <td colspan="17" style="text-align: center">
                        No data found
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>
    <div class="justify-content-end flex-wrap">
        <!-- <div class="align-items-center justify-content-center m-2">
            <button pButton type="button" label="Request Hardware Catalog" class="ml-4"
                (click)="submitHardwareCatalogRequest()"></button>
        </div> -->
    </div>
    <div class="flex justify-content-between">
        <div class="align-items-center justify-content-center mt-4">
            <button pButton type="button" label="Request Hardware Catalog" 
                (click)="submitHardwareCatalogRequest()"></button>
        </div>
        <div class="text-align-center justify-content-center mt-4">
            <button pButton type="button" label="Contact Support for Additional Support​" 
                (click)="talkToExpertRequestForm()"></button>
        </div>
    </div>

</p-dialog>

<p-dialog header="Select Website​" [(visible)]="showWebsiteSelectionPopup" [blockScroll]="true" modal="true"
    [breakpoints]="{
    '960px': '80vw',
    '640px': '90vw',
    '250px': '100vw'
}" [draggable]="false" [style]="{ width: '70vw' }">
    <app-signup-website-and-online-store>

    </app-signup-website-and-online-store>

</p-dialog>

<p-dialog header="Select Loyalty Program" [(visible)]="showLoyaltyProgramSelectionPopup" [blockScroll]="true"
    modal="true" [breakpoints]="{
    '960px': '80vw',
    '640px': '90vw',
    '250px': '100vw'
}" [draggable]="false" [style]="{ width: '70vw' }">
    <app-signup-loyalty-program>

    </app-signup-loyalty-program>

</p-dialog>
<p-dialog header="Please select subscription to modify" modal="true" [closable]="false"
    [(visible)]="displaySubscriptionPlan" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '95vw'}">

    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="closeSubscriptionModal(1)" class="p-button-secondary"
            label="Close"></button>
        <button pButton type="button" [disabled]="_signup?.selectedPlan?.title === 'Free Trial'"
            class="checkout-popup-btn-save" (click)="closeSubscriptionModal(2)" label="Next"></button>
        <!-- <button pButton type="button" [disabled]="!planChanged" class="checkout-popup-btn-save"
            (click)="closeSubscriptionModal(2)" label="Next"></button> -->
    </ng-template>
    <app-subscription-plans-layout [showFooter]="false" [showTrial]="false" [AlreadySubscribedCustomer]="false"
        [DefaultInterval]="_bizzCustomerService.activeItem?.currentSubscription?.subscriptionInterval"
        [AllowQtyChange]="_bizzCustomerService.activeItem.isFreeTrial"
        (subscriptionPlanChanged)="subscriptionPlanChanged($event)"
        (closeDialogSubscriptionPlan)="closeDialogSubscriptionPlan($event)"></app-subscription-plans-layout>