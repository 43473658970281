import { Subscription } from 'rxjs';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/_services/global.service';
import { SignupService } from 'src/app/_services/signup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-signup-personal-info',
  templateUrl: './signup-personal-info.component.html',
  styleUrls: ['./signup-personal-info.component.scss']
})
export class SignupPersonalInfoComponent implements OnInit, AfterViewInit {
  editform: FormGroup
  localActiveItem = this._signup.personalInfo
  displayTerms = false
  displayWhat = 'terms-and-conditions'
  displayWhos = ''

  emailValidatorString = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  passwordValidatorString = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/
  displayPasswordError = false

  _globals = GlobalService


    @ViewChild('InputPassword')
    InputPassword!: ElementRef;

  constructor(
    public _signup: SignupService,
    private _fb: FormBuilder,
    private _connMessageService: ConnMessageService,
    private _spinner: NgxSpinnerService,
    private _router: Router,
      private eRef: ElementRef,
      private renderer: Renderer2,
      @Inject(DOCUMENT) document: Document
  ) { }
    ngAfterViewInit(): void {
        this.setAttribute('email', 'autocomplete', 'new-password')
    }

    private setAttribute(feild, attribute, value): void {
        document.getElementsByName(feild).forEach(x => {
            x.setAttribute(attribute, value)
        })

        let stepOneFound: any = sessionStorage.getItem('step-1');
        if (stepOneFound == undefined || stepOneFound == null) {
            document.querySelectorAll('input[type=password]')[0].setAttribute("autocomplete","new-password");
        }

    }

  get f() {
    return this.editform.controls;
  }

  ngOnInit(): void {
    this.initForm()
  }

    initForm() {


        this.editform = this._fb.group({
            firstName: [this._signup.personalInfo.firstName, Validators.required],
            lastName: [this._signup.personalInfo.lastName, Validators.required],
            email: [{ value: this._signup.personalInfo?.email, disabled: this._signup.isViewOnly }, [

                Validators.required, this.emailFormatValidator()]],
            // confirmEmail: [this._signup.personalInfo.confirmEmail, [Validators.required, this.confirmEmailValidator()]],
            phone: [this._signup.personalInfo.phone, Validators.required],
            password: [
                this._signup.personalInfo.password,
                [Validators.required, this.passwordStrengthValidator()],
            ],
            confirmPassword: [
                this._signup.personalInfo.confirmPassword,
                [Validators.required, this.confirmPasswordStrengthValidator()],
            ],
        })

        if (this._signup.userAccountPendingForPayment) {
            this._signup.personalInfo.password = "vAc@2v0p454";
            this._signup.personalInfo.confirmPassword = "vAc@2v0p454";
        }



    }

    emailFormatValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) return null;

        const pvalid = value.match(this.emailValidatorString)
        return pvalid ? null : { emailStrength: true };
        }
    }

    // confirmEmailValidator(): ValidatorFn {
    //     return (control: AbstractControl): ValidationErrors | null => {
    //     const value = control.value;
    //     try {
    //         const value11 = this.editform.get('email').value
    //     } catch { return null }
    //     const value1 = this.editform.get('email').value
    //     if (!value) return null;

    //     return value === value1 ? null : { mismatchEmail: true }
    //     }
    // }

    passwordStrengthValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) return null;

        const pvalid = value.match(this.passwordValidatorString)
        return pvalid ? null : { passwordStrength: true };
        }
    }

    confirmPasswordStrengthValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        try {
            const value11 = this.editform.get('password').value
        } catch { return null }
        const value1 = this.editform.get('password').value
        if (!value) return null;

        return value === value1 ? null : { conpasswordStrength: true }
        }
    }

    showTerms() {
        // if (window.location.host.includes('connect-360') || window.location.host.includes('localhost:')) {
        this.displayTerms = true
        this.displayWhat = 'terms-and-conditions'
        this.displayWhos = 'connect-360'
        // }
        // else if (window.location.host.includes('connect-cbd')) {
        // this.displayTerms = true
        // this.displayWhat = 'terms-and-conditions'
        // this.displayWhos = 'connect-cbd'
        // }
        // else {
        //   this.displayTerms = true
        //   this.displayWhat = 'terms-and-conditions'
        //   this.displayWhos = _globals.whiteLabel.wlName
        //   }
    }

    showEndUserAggreement() {

        // if (window.location.host.includes('connect-360') || window.location.host.includes('localhost:')) {
        this.displayTerms = true
        this.displayWhat = 'end-user-agreement'
        this.displayWhos = 'connect-360'
        // }
        // else if (window.location.host.includes('connect-cbd')) {
        // this.displayTerms = true
        // this.displayWhat = 'end-user-agreement'
        // this.displayWhos = 'connect-cbd'
        // }
        // else 
        //  {
        //   this.displayTerms = true
        //   this.displayWhat = 'terms-and-conditions'
        //   this.displayWhos = _globals.whiteLabel.wlName
        //   }
    }

    inputPersonalInfoPhone(e) {
        let x = this.editform.get('phone').value
        this._signup.personalInfo.phone = x.replace(/\D/g, '')
    }

    saveFn() {
        if (!this.isFormValidFn()) return

        // this._spinner.show()
        this._signup.currentStep++;
        if (this._signup.savedSteps < 9){
            this._signup.savedSteps++;
        }

        sessionStorage.setItem('step-1', JSON.stringify(this._signup.personalInfo))
        sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
        sessionStorage.setItem('savedSteps', this._signup.savedSteps.toString());

    }

  isFormValidFn() {
    let msg = []
    let haserror = false
    try {
      if (!this._signup.personalInfo.doAgree) {
        this._signup.ok2savePersonalInfo = false
        msg.push('You must agree to terms and conditions')
        haserror = true
      }

      if (this.editform.get('firstName').value.length < 1 || this.editform.get('lastName').value.length < 1) {
        this._signup.ok2savePersonalInfo = false
        msg.push('Invalid name')
        haserror = true
      }

        if (!this._signup.userAccountPendingForPayment){
            if (this.editform.get('password').value.trim().length < 1 || this.editform.get('confirmPassword').value.trim().length < 1) {
                this._signup.ok2savePersonalInfo = false
                msg.push('Invalid Password')
                haserror = true
            }
        }


        if (!this._signup.userAccountPendingForPayment) {
            if (this.editform.get('password').value.trim() !== this.editform.get('confirmPassword').value.trim()) {
                this._signup.ok2savePersonalInfo = false
                msg.push('Password do not match')
                haserror = true
            }
        }

        // if (!this._signup.userAccountPendingForPayment) {
            // if (this.editform.get('email').value.trim().length < 1 || this.editform.get('confirmEmail').value.trim().length < 1) {
            //     this._signup.ok2savePersonalInfo = false
            //     msg.push('Invalid email')
            //     haserror = true
            // }

        // if (this.editform.get('email').value.trim() !== this.editform.get('confirmEmail').value.trim()) {
        //         this._signup.ok2savePersonalInfo = false
        //         msg.push('Email do not match')
        //         haserror = true
        //     }
        // }

      const ph = this.editform.get('phone').value.replace(/\D/g, '')
      if (ph.length !== 10) {
        this._signup.ok2savePersonalInfo = false
        msg.push('Phone number error')
        haserror = true
      }
    } catch {
      this._signup.ok2savePersonalInfo = false
      msg.push('Unknown error')
      haserror = true
    }

    if (haserror) {
      for (let i = 0; i < msg.length; i++)
        this._connMessageService.showToastMessage(msg[i], 'error');

      return false
    } else {
      this._signup.ok2savePersonalInfo = true
      return true
    }
  }

  backClicked() {

    this._signup.currentStep = 0
      sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
      sessionStorage.setItem('savedSteps', this._signup.currentStep.toString());
    this._router.navigateByUrl('setup/subscription-plans')
   }

  nextClick() {
    const what = this.isFormValidFn()
    if (what) {
      this._signup.personalInfo.phone = this.editform.get('phone').value.replace(/\D/g, '')
      this._signup.saveBusinessUserV2(this._signup.personalInfo).subscribe(res => {
        if (res.success) {
          this._signup.personalInfo.userID = res.data
          this._signup.currentStep++
            sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
            sessionStorage.setItem('savedSteps', this._signup.savedSteps.toString());
        }
      })
      // console.log('this._signup.personalInfo', this._signup.personalInfo)
    }
    else
      this._connMessageService.showToastMessage(`Unable to save personal information!`, 'error');
  }

  checkForDuplicateUser() {
    const value = this.editform.get('email').value.trim()
    if (value.length < 3 || !this._globals.isValidEmail(value)) {
      this._connMessageService.showToastMessage(`Invalid email format!`, 'error');
      return false
    }

    this._spinner.show()
    this._signup.isUserExist(value).subscribe(res => {
      if (res.success) {
        if (res.data) {
          this._spinner.hide()
          this._signup.personalInfo.userExist = true
          this.editform.controls['email'].setErrors({ 'userexist': true })
          //this._connMessageService.showToastMessage(`User Name ${value} already taken, please choose another email`, 'error');
        } else {
          this._signup.personalInfo.userExist = false
        }
      }
    })
  }
}
