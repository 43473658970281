import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ConnMessageService } from './conn-message.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  baseUrl: string = ''
  _globals = GlobalService

  constructor(
    private http: HttpClient,
    private _connMessageService: ConnMessageService,
    private _spinner: NgxSpinnerService,
    // private toastr: ToastrService
  ) {
    this.baseUrl = environment.apiUrl
  }

  httpPost(_url: string, model: any = null, auth: boolean = false, showSpinner: boolean = true,businessName:string=null) {
    // if (showSpinner) this._abd.showSpinner();
    // else this._abd.hideSpinner();
    const xx = this.makeHttpHeader(auth)
    let headers = new HttpHeaders();
    
    if (businessName) {
      headers = headers.set('BusinessName', businessName);
    }
    return this.http.post(_url, model,{ headers }).pipe(
      map((res: any) => {
        if (showSpinner) this._spinner.hide();
        return this.processErrors(res)
      })
    )
  }

  httpPut(_url: string, model: any = null, auth: boolean = false, showSpinner: boolean = true, businessName:string=null) {
    // if (showSpinner) this._abd.showSpinner();
    // else this._abd.hideSpinner();
    const xx = this.makeHttpHeader(auth)
    let headers = new HttpHeaders();
    
    if (businessName) {
      headers = headers.set('BusinessName', businessName);
    }
    return this.http.put(_url, model, { headers }).pipe(
      map((res: any) => {
        if (showSpinner) this._spinner.hide();
        return this.processErrors(res);
      })
    );
  }

  httpGetParams(_url: string, model: any = null, auth: boolean = false, showSpinner: boolean = true): Observable<any> {
    // if (showSpinner) this._abd.showSpinner()
    // else this._abd.hideSpinner();
    let wlid = <any>this._globals.getWlId()
    if (wlid) {
      const requestOptions = {
        headers: this.makeHttpHeader(auth),
      };

      return this.http.get(_url, { params: model }).pipe(map(res => {
        // if (showSpinner) this._abd.hideSpinner();
        return this.processErrors(res)
      }));
    }
  }

  httpGet(_url: string, showSpinner: boolean = true,businessName:string=null): Observable<any> {
    // if (showSpinner) this._abd.showSpinner();
    // else this._abd.hideSpinner();
    let headers = new HttpHeaders();
    
    if (businessName) {
      headers = headers.set('BusinessName', businessName);
    }
    return this.http.get(_url, { headers }).pipe(map(res => {
      // if (showSpinner) this._abd.hideSpinner();
      return this.processErrors(res)
    }));
  }
  httpGetByPassInterceptor(_url: string, showSpinner: boolean = true): Observable<any> {
    // if (showSpinner) this._abd.showSpinner();
    // else this._abd.hideSpinner();

    return this.http.get(_url).pipe(map(res => {
      // if (showSpinner) this._abd.hideSpinner();
      return res;
    }));
  }

  httpDelete(_url: string,businessName:string=null): Observable<any> {
    return this.http.delete(_url).pipe(map(res => {
      return this.processErrors(res)
    }))
  }


  //--------------------------------------------------
  makeHttpHeader(auth: boolean) {
    let wlid = <any>this._globals.getWlId()
    if (!wlid) return false

    if (auth) {
      return new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        WLID: wlid,
        Authorization: `Bearer ${this._globals.getUserToken()}`
      })
    } else {
      return new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        WLID: wlid
      })
    }
  }
  //--------------------------------------------------
  GetAuthHeader() {
    let wlid = <any>this._globals.getWlId()
    if (!wlid) return null


    return new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      WLID: wlid,
      Authorization: `Bearer ${this._globals.getUserToken()}`
    })

  }

  processErrors(res: any) {
    // this._abd.hideSpinner()
    //
    if (res.success) {
      try {
        if (res.data.accountStatusString === 'AccessDenied') {
          let errorMessage = 'Access denied, invalid username or password'
          res.success = false
          this._globals.loginErrorMessage = errorMessage;
          this._connMessageService.showToastMessage(errorMessage)
        }
         else if (res.data.accountStatusString === 'InvalidAccount') {
          let errorMessage = 'Access denied, invalid username or password'
          res.success = false
          this._globals.loginErrorMessage = errorMessage;
          this._connMessageService.showToastMessage(errorMessage)
        }
        else if (res.data.accountStatusString === 'DeactivatedAccount') {
          let errorMessage = 'You have not activated your account yet. Please check your email to activate your account.'
          res.success = false
          this._globals.loginErrorMessage = errorMessage;
          this._connMessageService.showToastMessage(errorMessage)
        }
        else if (res.data.accountStatusString === 'DeploymentInProgress') {
          let errorMessage = 'Your Business account setup is in progress. Please wait for confirmation email.'
          res.success = false
          this._globals.loginErrorMessage = errorMessage;
          this._connMessageService.showToastMessage(errorMessage)
        }
        else if (res.data.accountStatusString === 'AccountExpired') {
          let errorMessage = 'Your Account is expired, please contact support.'
          res.success = false
          this._globals.loginErrorMessage = errorMessage;
          this._connMessageService.showToastMessage(errorMessage)
        }

      } catch { }
    } else {
      if (res.message === "Activation link has been expired!") {
        this._globals.loginErrorMessage = res.message;
      }
      else if (res.message === "") {
        this._globals.loginErrorMessage = res.message;
      }
      else if (res.message === "Invalid Link!") {
        this._globals.loginErrorMessage = res.message;
      }
      else if (res.message) {
        this._connMessageService.showToastMessage(res.message)
        return
      }
      this._connMessageService.showToastMessage(res)
    }
    return res
  }



}


