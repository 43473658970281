<div class="grid">
    <div class="col-12 md:col-6 lg:col-6 xl:col-6">
        <label class="control-label" style="margin-right: 10px"
            ><strong
                >Product Type
                <span style="color: red; font-size: 1.25rem">*</span>
            </strong></label
        >
        <br />
        <p-dropdown
            [options]="_productService.productInfo.itemType"
            [(ngModel)]="_productService.selectedProductType"
            optionLabel="name"
            [style]="{'width':'100%', 'margin-top':'10px'}"
        >
        </p-dropdown>
        <span class="control-description text-muted">Set the product type</span>
    </div>

    <div class="col-12 md:col-6 lg:col-6 xl:col-6">
        <label class="control-label" style="margin-right: 10px"
            ><strong
                >Sold as
                <span style="color: red; font-size: 1.25rem">*</span>
            </strong></label
        >
        <br />
        <p-dropdown
            [options]="_productService.productInfo.sellAs"
            [(ngModel)]="_productService.selectedSellAs"
            (onChange)="sellAsClicked($event)"
            optionLabel="name"
            [style]="{'width':'100%', 'margin-top':'10px'}"
        >
        </p-dropdown>
        <span class="control-description text-muted">Set how product is sold</span>
    </div>


    <div class="col-12 md:col-6 lg:col-6 xl:col-6" style="display: none;">
        <label class="control-label" style="margin-right: 15px"
            ><strong
                >Grouping
                <span style="color: red; font-size: 1.25rem">*</span>
            </strong></label
        >
        <br />
        <p-dropdown
            [options]="_productService.productInfo.grouping"
            [(ngModel)]="_productService.selectedGrouping"
            optionLabel="name"
            [style]="{'width':'100%', 'margin-top':'10px'}"
        >
        </p-dropdown>
    </div>

    <div class="col-12 md:col-6 lg:col-6 xl:col-6">
        <label class="control-label" style="margin-right: 15px"
            ><strong
                >Discount
                <span style="color: red; font-size: 1.25rem">*</span>
            </strong></label
        >
        <br />
        <p-dropdown
            [options]="_productService.productInfo.discount"
            [(ngModel)]="_productService.selectedDiscount"
            optionLabel="name"
            [style]="{'width':'100%', 'margin-top':'10px'}"
        >
        </p-dropdown>
        <span class="control-description text-muted">Set discount eligiblity</span>
    </div>

    <div class="col-12 md:col-6 lg:col-6 xl:col-6">
        <label class="control-label" style="margin-right: 15px"
            ><strong
                >Age Restriction
                <span style="color: red; font-size: 1.25rem">*</span>
            </strong></label
        >
        <br />
        <p-dropdown
            [options]="_productService.productInfo.ageRestriction"
            [(ngModel)]="_productService.selectedAgeRestriction"
            optionLabel="name"
            [style]="{'width':'100%', 'margin-top':'10px'}"
        >
        </p-dropdown>
        <span class="control-description text-muted">Set if product is restricted to some age group</span>
    </div>

    <div class="col-12 md:col-6 lg:col-6 xl:col-6">
        <label class="control-label" style="margin-right: 15px"
            ><strong
                >Gender Specific
                <span style="color: red; font-size: 1.25rem">*</span>
            </strong></label
        >
        <br />
        <p-dropdown
            [options]="_productService.productInfo.gender"
            [(ngModel)]="_productService.selectedGender"
            optionLabel="name"
            [style]="{'width':'100%', 'margin-top':'10px'}"
        >
        </p-dropdown>
        <span class="control-description text-muted">Set if product is gender specific</span>
    </div>
</div>
