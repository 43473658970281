import { IndexedDBService } from './../../../_services/indexed-db.service';
import { DataViewModule } from 'primeng/dataview';
import { filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { PurchaseService } from 'src/app/_services/purchase.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DatePipe, formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/_services/base.service';

@Component({
  selector: 'app-po-receive-open',
  templateUrl: './po-receive-open.component.html',
  styleUrls: ['./po-receive-open.component.scss']
})
export class PoReceiveOpenComponent implements OnInit {
  isDataLoaded = false
  locations: any = null
  vendors: any = null
  dataList: any = []
  histList: any = []
  activeItem: any = null

  invoiceNo = ''
  shipping = 0
  comments1 = ''
  selectedOptions: any
  histSelectedRow: any

  showReceiveInvoice = false
  receiveData: any

  sumSubTotal = 0
  sumDiscount = 0
  sumTax = 0
  grandTotal = 0

  divideShipping = false

  actionsHist: MenuItem[]

  method = 'Manual'
  createdBy = ''

  _globals = GlobalService
  showInventoryReceiveBtn : boolean = false;
  @ViewChild('histmenu') histMenu: OverlayPanel

    isFooterNoteEnabled: boolean = false;
    footerNotesText: string = '';
    grandTotalNew: number = 0;
    completeData;
    inputSendEmail: any;
    isSendEmailEnable:boolean = false;
  
    constructor(
    public _purchaseService: PurchaseService,
    public _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private _messageService: MessageService,
    private _confirmationService: ConfirmationService,
    private _router: Router,
    private _bs: BaseService,
    private datePipe:DatePipe,
  ) { }

  ngOnInit(): void {
    this.createdBy = this._globals.userInfo.firstName + ' ' + this._globals.userInfo.lastName
    this.actionsHist = [
      {
        label: 'Post To Inventory',
        icon: 'pi pi-window-maximize',
        command: () => { this.postToInvoice(); }
      },
      {
        label: 'View Receive Invoice',
        icon: 'pi pi-fw pi-eye',
        command: () => { this.viewReceiveInventory(); }
      },
    ]
    this.locations = this._abd.initActLocations()
    this.vendors = this._abd.initActVendors()

    if (!this.locations || !this.vendors) {
      this._messageService.add({ severity: 'error', summary: '[S-12.85.68] Missing basic data!' })
      window.history.back()
    }

    this.initData()

  }
  postToInvoice() {
    const hr = JSON.parse(JSON.stringify(this.histSelectedRow))
    const url = `${environment.InventoryBaseURL}ReceivePOTxn/${hr.id}`

    this._bs.httpPut(url).subscribe(res => {
      if (res.success) {
        this.initData();
        this._messageService.add({ severity: 'success', summary: 'Posted to Inventory operation was successful!' })
      }
    })
  }

  viewReceiveInventory() {
    this.receiveData = ''
    const hr = JSON.parse(JSON.stringify(this.histSelectedRow))
    const url = `${environment.InventoryBaseURL}ReceivePO/${hr.poRecivingId}`
    this._bs.httpGet(url).subscribe(res => {
      if (res.success) {

        this.receiveData = res.data

        this.receiveData.createdDate = new Date(this.receiveData.createdDate)
        this.receiveData.receivedDate = new Date(this.receiveData.receivedDate)
        this.showReceiveInvoice = true
      }
    })
  }

  initData() {
    this.showInventoryReceiveBtn = false;
    this._purchaseService.searchSingleOrder(this._purchaseService.newEditItem.Id, false).subscribe(res => {
      if (res.success) {
        this.isDataLoaded = true

        this.activeItem = res.data
        this.activeItem.vendorObj= this.vendors.find(f => f.id == this.activeItem.vendorId)
        this.getFooterNotesConfigValue()
        let item = this.activeItem
        this._purchaseService.activeItemFull = item

        this.dataList = []
        this.dataList = JSON.parse(JSON.stringify(res.data.poItems))
        this.histList = JSON.parse(JSON.stringify(res.data.txnItems))

        this.showInventoryReceiveBtn = this.histList.filter(f=> f.inventoryCommitStatus == 'RECEIVED').length> 0 ? true: false;
        this.grandTotalNew = res.data.grandTotal;
        item.receiveNo = this._purchaseService.generatePoNumber()
        item.receiveBy = this._globals.userInfo.displayName
        item.receiveDate = new Date(this.activeItem.dueDate)
        item.dated = new Date(this.activeItem.dated)
        this._purchaseService.newEditItem.locationObj = this.locations.find(f => f.locationId == item.locationId)
        this.activeItem.vendorObj= this.vendors.find(f => f.id == this.activeItem.vendorId)
        item.locationObj = this.locations.find(f => f.locationId == item.locationId)
      //  item.vendorObj = this.vendors.find(f => f.id == item.vendorId)

        this.comments1 = item.notes

        for (let i = 0; i < this.dataList.length; i++) {
          this.dataList[i].srNo = i + 1
          this.dataList[i].remainingQty = this.dataList[i].dueQuantity
        }

        this.calculateTotals()
      }
    })
  }
  handlePdf(value:any){

    let     colHeader = [['Sr#','Barcode','SKU','Product','UOM','Ordered Qty','Received Qty','Due Qty','Unit Cost ($)','Discount (%)','Net Total ($)','Tax (%)','Shipping ($)','Total Cost ($)']];
    let     colflds = ['srNo','barcode','skuCode','productName','uomDescription','orderedQty','receivedQty','dueQuantity','costPrice','discountPercent','netTotal','taxRate','shipping' ,'itemLevelItemCost'];
    let obj = {
    location:this.activeItem?.locationName || "",
    poNumber:this.activeItem?.poNumber || "",
    requestedBy:this.activeItem?.requestedBy || "",
    createdDate:this.activeItem?.createdDate || "",
    receiveDate:this.datePipe.transform(this.activeItem?.receiveDate, "yyyy-MM-ddTHH:mm:ss.SSSSSSS"),
    poType:this.activeItem?.poType || "",
    poStatus:this.activeItem?.poStatus || "",
    company:this.activeItem?.vendorObj?.company || "",
    vendorName:this.activeItem?.vendorObj?.vendorName || "",
    address:this.activeItem?.vendorObj?.city || "",
    email:this.activeItem?.vendorObj?.email || "",
    phone:this.activeItem?.vendorObj?.phone || "",
    account:this.activeItem?.vendorObj?.account || "",
    note:this.comments1 || "",
    subTotal:this.activeItem?.subTotal.toString() || "0.00",
    discountTotal:this.activeItem?.discountTotal.toString() || "0.00",
    taxTotal:this.activeItem?.taxTotal.toString() || "0.00",
    shipping:this.activeItem?.shipping.toString() || "0.00",
    grandTotal:this.activeItem?.grandTotal.toString() || "",

 }
     let doc;
     doc = this._abd.exportPOPdf(this.dataList, colHeader, colflds,obj)
   
   if(value === 'printPdf'){

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none'; // Hide the iframe
    document.body.appendChild(iframe);

    // Generate the PDF as a Blob
    const pdfBlob = doc.output('blob');

    // Create a Blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // This event listener waits for the iframe to load the PDF document
    // and triggers the print dialog
    iframe.onload = () => {
      // Execute print command
      iframe.contentWindow?.print();

     };

    // Set the src of the iframe to the Blob URL
    iframe.src = blobUrl;
     
   }
   else if(value === 'savePdf'){
     doc.save('Purchase Order' + ' (' +
     formatDate(new Date(), 'MM/dd/yyyy', 'en-US') + ')' + '.pdf')
   }
   else if(value === 'sendEmail'){
    this._purchaseService.isSendEmail = true
   }
   else if(value === 'openNewTab'){
    const pdfBlob = doc.output('blob');

    const pdfUrl = URL.createObjectURL(pdfBlob);

    window.open(pdfUrl, '_blank');
}  
   
    }
   openInNewTab(){
    this.handlePdf('openNewTab')
  }
    getFooterNotesConfigValue() {
        try {
            this._purchaseService.getPurchaseOrderConfigurationDetails().subscribe((r) => {
                if (r.success) {
                  this.inputSendEmail = r.data.po_sendemail;
                    this.isFooterNoteEnabled = r.data.po_enablefooternote;
                    this.footerNotesText = r.data.po_footernote
                  if(this.inputSendEmail === true){
                   if(this.activeItem.poStatus === 'OPEN'){
                    this.isSendEmailEnable = true;
                   }
                  }
                  }
                else {
                    this._messageService.add({ severity: 'error', summary: 'Unable to load saved values of configuration' })
                }
            }, error => {
                this._messageService.add({ severity: 'error', summary: 'Unable to load saved values of configuration' })
            })
        } catch (error) {

        }
    }

  costPriceChanged(e, item) {
    try {
      item.costPrice = e.value
      if (!item.costPrice) item.costPrice = 0
    }
    catch {
      item.subTotal = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  discountChanged(e, item) {
    try {
      item.discountPercent = e.value
      if (!item.discountPercent) item.discountPercent = 0
    }
    catch {
      e.value = 0
    }
    this.calculateTotals()
  }

  taxChanged(e, item) {
    try {
      item.taxRate = e.value
      if (!item.taxRate) item.taxRate = 0
    }
    catch {
      e.value = 0
    }
    this.calculateTotals()
  }

  shippingChanged(e) {
    try {
      this.shipping = e.value

    }
    catch {
      e.value = 0
    }
    this.calculateTotals()
  }

  receivedQtyChanged(e, item) {
    try {
      item.receivedQuantity = e.value
      if (!item.receivedQuantity) item.receivedQuantity = 0
      if (item.receivedQuantity > item.dueQuantity) item.receivedQuantity = item.dueQuantity
    }
    catch {
      item.totalCost = 0.0
      e.value = 0
    }
    this.calculateTotals()
  }

  onChangeValA(e) {
    this.calculateTotals()
  }

  calculateTotals() {
    let tmp = this.dataList.filter(f => f.receivedQuantity > 0)

    this.sumSubTotal = 0
    this.sumDiscount = 0
    this.sumTax = 0
    this.grandTotal = 0

    for (let i = 0; i < this.dataList.length; i++) {
      this.dataList[i].srNo = i + 1
      this.dataList[i].remainingQty = this.dataList[i].dueQuantity - this.dataList[i].receivedQuantity
    }


    if (tmp.length && this.selectedOptions && this.selectedOptions.find(f => f == 'valA')) {
      this.divideShipping = true
    } else {
      this.divideShipping = false
    }

    let tmp1 = 0
    let lineShipping = 0

    if (this.shipping > 0 && this.dataList.length) {
      let find = this.dataList.filter(f => f.orderedQty > 0)
      lineShipping = this.shipping / find.length
    }

    for (let i = 0; i < this.dataList.length; i++) {
      let dr = this.dataList[i]

      let pc = (dr.costPrice * dr.orderedQty)
      let disc = 0
      if (dr.discountPercent > 0) disc = pc * (dr.discountPercent / 100)
      dr.discountAmount = disc
      dr.gsubtotal=pc
      dr.netTotal = pc - disc


      if (!dr.totalCost || isNaN(dr.totalCost) || dr.totalCost <= 0) dr.totalCost = dr.netTotal //-- check if to keep

      let tax = 0
      if (dr.taxRate > 0) tax = dr.netTotal * (dr.taxRate / 100)
      dr.taxAmount = tax

      dr.totalCost = dr.netTotal + tax + tmp1

      dr.itemLevelItemCost = dr.netTotal + dr.taxAmount  + dr.shipping;
      this.grandTotal += dr.itemLevelItemCost
      this.sumSubTotal += dr.gsubtotal
      this.sumDiscount += dr.discountAmount
      this.sumTax += dr.taxAmount
    }
    this.grandTotal += this.shipping
  }

  onHistRowSelect(e) {
    this.histSelectedRow = e.DataViewModule
  }

  onHistRowUnselect(e) { }

  histRowClicked(e, item) {
    this.histMenu.toggle(e)
    this.histSelectedRow = item
    if (item.inventoryCommitStatus!="POSTED") {
      this.actionsHist = [
        {
          label: 'Post To Inventory',
          icon: 'pi pi-window-maximize',
          command: () => { this.postToInvoice(); }
        },
        {
          label: 'View Receive Invoice',
          icon: 'pi pi-fw pi-eye',
          command: () => { this.viewReceiveInventory(); }
        },
      ]
    }
    else {

      this.actionsHist = [

        {
          label: 'View Receive Invoice',
          icon: 'pi pi-fw pi-eye',
          command: () => { this.viewReceiveInventory(); }
        },
      ]

    }
  }

  cancelButtonClick() {
    window.history.back()
    // this._confirmationService.confirm({
    //   header: `Cancel changes?`,
    //   message: 'Are you sure you want to proceed?',
    //   accept: () => {
    //     try {
    //       window.history.back()
    //     } catch { this._abd.hideSpinner() }
    //   },
    // });
  }

  // cancelButtonClick() {
  //   this._confirmationService.confirm({
  //     header: `Cancel changes?`,
  //     message: 'Changes you made will be lost. Are you sure you want to proceed?',
  //     accept: () => {
  //       try {
  //         let data = {
  //           "poStatus": "CANCELLED",
  //           "receivedById": this._globals.userInfo.userID,
  //           "receivedBy": this.createdBy,
  //           "purchaseOrderId": this.activeItem.id,
  //         }

  //         this._spinner.show()
  //         this._purchaseService.purchaseOrderReceive(data, true).subscribe(res => {
  //           this._spinner.hide()
  //           if (res.success) {
  //             this._messageService.add({ severity: 'success', summary: 'Purchase Order Cancelled successfully!' })
  //             window.history.back()
  //           }
  //         })

  //       } catch { this._abd.hideSpinner() }
  //     },
  //   });
  // }

  receivePoClicked() {
    this._purchaseService.receivingNotes = this.comments1
    this._router.navigate(['app/purchase-orders-receive-closed-for-receiving'])
  }
  receivePo_1() {
    let itemsSelected = JSON.parse(JSON.stringify(this.dataList))

    const isShipping = this.selectedOptions.find(f => f == 'valA')

    let items = []
    for (let i = 0; i < itemsSelected.length; i++) {
      const obj = {
        "itemSKUId": this.dataList[i].itemSKUId,
        "notes": "",
        "orderedQuantity": this.dataList[i].orderedQuantity,
        "dueQuantity": this.dataList[i].dueQuantity,
        "receivedQuantity": this.dataList[i].receivedQuantity,
        "returnQuantity": this.dataList[i].returnQuantity,
        "committedQuantity": this.dataList[i].committedQuantity,
        "costPrice": this.dataList[i].costPrice,
        "priceA": this.dataList[i].priceA,
        "priceB": this.dataList[i].priceB,
        "priceC": this.dataList[i].priceC,
        "subTotal": this.dataList[i].netTotal,
        "discountPercent": this.dataList[i].discountPercent,
        "discountAmount": this.dataList[i].discountAmount,
        "taxRate": this.dataList[i].taxRate,
        "taxAmount": this.dataList[i].taxAmount,
        "Shipping": isShipping,
        "totalAmount": this.dataList[i].totalCost,
        "updatedById": this._globals.userInfo.userID,
        "updatedBy": this._globals.userInfo.displayName,
      }
      items.push(obj)
    }

    let data = {
      "invoiceNumber": this.invoiceNo,
      "notes": this.comments1,
      "poStatus": "OPEN",
      "subTotal": this.sumSubTotal,
      "discountTotal": this.sumDiscount,
      "taxTotal": this.sumTax,
      "shipping": this.shipping,
      "grandTotal": this.grandTotal,
      "receivedDate": formatDate(this.activeItem.receiveDate, 'MM/dd/yyyy', 'en-US'),
      "receivedById": this._globals.userInfo.userID,
      "receivedBy": this.createdBy,
      "purchaseOrderId": this.activeItem.id,
      "DividedShippingItems": isShipping,
      "RPOItems": items
    }

    try {
      this._spinner.show()
      this._purchaseService.purchaseOrderReceive(data, true).subscribe(res => {
        this._spinner.hide()
        if (res.success) {
          //debugger;
        }
      })
    } catch { this._spinner.hide() }
  }
  commitAllInventory () {
    this._confirmationService.confirm({
        message: 'Are you sure you want to Post this Purchase Order?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          try {
            this.optionCommitClicked()
          } catch { }
        },
        reject: () => { }
      })
  }
  savePO(obj, poStatus = 'DRAFT') {
    // console.log('**** DATA ****', obj)

    let id = obj.id

    if (poStatus == 'DRAFT') {
      const pono = this._purchaseService.generatePoNumber()
      id = '-1'
      obj.poNumber = pono
    }

    const lst = obj.poItems
    let mlist = []

    let netTotal = 0
    let taxTotal = 0
    let discountTotal = 0

    for (let i = 0; i < lst.length; i++) {
      let sku = ''

      const itm = lst[i]
      const m = {
        itemSKUId: itm.itemSKUId,
        notes: itm.notes,
        quantity: itm.orderedQty,
        costPrice: this._globals.roundNumbers(itm.costPrice),
        subTotal: this._globals.roundNumbers(itm.subTotal),
        discountPercent: itm.discountPercent,
        discountAmount: this._globals.roundNumbers(itm.discountAmount),
        taxRate: itm.taxRate,
        taxAmount: this._globals.roundNumbers(itm.taxAmount),
        Shipping: this._globals.roundNumbers(itm.shipping),
        totalAmount: this._globals.roundNumbers(itm.totalAmount),
        updatedById: this._globals.userInfo.userID,
        updatedBy: this._globals.userInfo.displayName,
      }
      mlist.push(m)

      netTotal += lst[i].netCost
      taxTotal += lst[i].taxAmount
      discountTotal += lst[i].discountAmount
    }

    const model = {
      id: id,
      poNumber: obj.poNumber,
      POType: 'MANUAL',
      dated: new Date(),
      dueDate: new Date(),
      vendorId: obj.vendorId,
      requestedById: this._globals.userInfo.userID,
      requestedBy: this._globals.userInfo.displayName,
      locationId: obj.locationId,
      notes: obj.notes,
      poStatus: poStatus, //'DRAFT',
      paymentStatus: 'PENDING',
      subTotal: this._globals.roundNumbers(obj.subTotal),
      netTotal: this._globals.roundNumbers(obj.netTotal),
      taxTotal: this._globals.roundNumbers(obj.taxTotal),
      discountTotal: this._globals.roundNumbers(obj.discountTotal),
      shipping: this._globals.roundNumbers(obj.shipping),
      grandTotal: this._globals.roundNumbers(obj.grandTotal),
      ShippingByItem: obj.shippingByItem,
      DividedShippingItems: obj.dividedShippingItems,
      "POItems": mlist,
    }

    this._spinner.show()
    this._purchaseService.purchaseOrderPost(model).subscribe(res => {

      if (res.success) {
            this._messageService.add({ severity: 'success', summary: 'Purchase Order Posted successfully!' })

      } else {
        this._messageService.add({ severity: 'error', summary: 'Unable to post purchase order!' })
      }
      this._spinner.hide()
    })
  }
  createDuplicateOrder(poStatus = 'DRAFT') {
    this._spinner.show()
    try {
      this._purchaseService.searchSingleOrder(this._purchaseService.newEditItem.Id).subscribe(res => {
        if (res.success) {
        this._messageService.add({ severity: 'success', summary: 'Purchase Order duplicated successfully!' })
          const obj = res.data
          this.savePO(obj, poStatus)
          this._spinner.hide()
        } else {
          this._spinner.hide()
          this._messageService.add({ severity: 'error', summary: 'Unable to duplicate purchase order!' })
        }
      })
    } catch { this._spinner.hide() }
  }

  optionCommitClicked() {
    try {
      let data = {
        "poStatus": this._purchaseService.newEditItem.POStatus,
        "requestedById": this._globals.userInfo.userID,
        "requestedBy": this._globals.userInfo.displayName,
      }

      this._spinner.show()
      this._purchaseService.saveReceivedPurchaseOrder(this._purchaseService.newEditItem.Id, true, data).subscribe(res => {
        this._spinner.hide()
        if (res.success) {
            this.initData();
          this._messageService.add({ severity: 'success', summary: 'Purchase Order processed successfully!' })

        }
      })

    } catch { this._abd.hideSpinner() }
  }


}
