import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { BizzCustomersService } from 'src/app/_business-dashboard/_services/bizz-customers.service';
import { Status } from 'src/app/_models/coupon-management';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-bizz-customer-personal-info',
  templateUrl: './bizz-customer-personal-info.component.html',
  styleUrls: ['./bizz-customer-personal-info.component.scss']
})
export class BizzCustomerPersonalInfoComponent implements OnInit {
 
  statusList:any[] = [
    {id:0,viewValue:'Disable',value:false},
    {id:1,viewValue:'Enable',value:true},
   ]; 

  statusValue:FormControl = new FormControl()
  isChangeStatus:boolean = false;
  
  stateOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  constructor(
    public _bizzCustomerService: BizzCustomersService,
    private _connMessageService:ConnMessageService,
    public _abd:AppBaseDataService,
    private _confirmationService: ConfirmationService,

  ) { }

  ngOnInit(): void {
    if(this._bizzCustomerService.activeItem.accountStatusString === "Account Expired" || this._bizzCustomerService.activeItem.accountStatusString === "Deployment Completed" && this._abd.isAdmin === true){
      this.isChangeStatus = true
      this.statusValue.setValue(this._bizzCustomerService.activeItem.accountStatusString === "Deployment Completed" ? true : false)
    }
  }
  saveStatus(){

    this._confirmationService.confirm({
      message: `Do you wish to ${this.statusValue.value === true ? "activate":"deactivate" } this account?`,
      header: 'Account Status',
      icon: 'pi pi-info-circle',
      accept: () => {
   let customerID = this._bizzCustomerService.activeItem.userId
    this._bizzCustomerService.updateBusinessStatus(customerID,this.statusValue.value).subscribe((res)=>{
       if(res.success){
        this._connMessageService.showToastMessage('Customer Status changes successfully', 'success');
      } 
      else {
        this._connMessageService.showToastMessage(`Customer status could not be changes.`, 'error');
        // this._abd.hideSpinner()
      }
    }
     
    )   
  },
      // reject: () => { }
    });

   


    


  }
}
