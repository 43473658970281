import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { TenderScreenConfig } from 'src/app/_models/configuration-widgets/business-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
  selector: 'app-tender-configuration',
  templateUrl: './tender-configuration.component.html',
  styleUrls: ['./tender-configuration.component.scss']
})
export class TenderConfigurationComponent implements OnInit {

  tenderform: FormGroup;
  tenderSettingData: TenderScreenConfig;
  @Input() singupBusinessConfig: boolean = true;
    @Output() widgetSaved = new EventEmitter();
  constructor(private _fb: FormBuilder,
              public _businessSetupService: BusinessSetupService,
              private _spinner: NgxSpinnerService,
              private _connMessageService: ConnMessageService,
              private _abd: AppBaseDataService) { }

  ngOnInit(): void {
    this.tenderSettingData = {
      enableTips: this._abd.systemConfigs.tenderScreenConfig.enableTips,
      promptCustomerTip: this._abd.systemConfigs.tenderScreenConfig.promptCustomerTip,
      enableCashPayment: this._abd.systemConfigs.tenderScreenConfig.enableCashPayment,
      enableDebitCartPayment: this._abd.systemConfigs.tenderScreenConfig.enableDebitCartPayment,
      enableCreditCardPayment: this._abd.systemConfigs.tenderScreenConfig.enableCreditCardPayment,
      enableCheckPayment: this._abd.systemConfigs.tenderScreenConfig.enableCheckPayment,
      enableZellePayment: this._abd.systemConfigs.tenderScreenConfig.enableZellePayment,
      enablePaypalPayment: this._abd.systemConfigs.tenderScreenConfig.enablePaypalPayment,
      enableVenomoPayment: this._abd.systemConfigs.tenderScreenConfig.enableVenomoPayment,
      // active: this._abd.systemConfigs.tenderScreenConfig.active,
    }
  }
 
  ngAfterContentInit() {
    this.initForm()
    // const enable=this.tenderform.get('enableTenderForm');
    // enable?.valueChanges
    //   .subscribe(value=>this.disableControls(value))
  }

  disableControls(value){
    if(value==false){
      this.tenderform.get('enableTips')?.disable();
      this.tenderform.get('promptCustomerTip')?.disable();
      this.tenderform.get('allowCash')?.disable();
      this.tenderform.get('allowDeditCard')?.disable();
      this.tenderform.get('allowCreditCard')?.disable();
      this.tenderform.get('allowCheck')?.disable();
      this.tenderform.get('allowZellePayment')?.disable();
      this.tenderform.get('allowPaypalPayment')?.disable();
      this.tenderform.get('allowVenomoPayment')?.disable();
    }else if (value==true){
      this.tenderform.get('enableTips')?.enable();
      this.tenderform.get('promptCustomerTip')?.enable();
      this.tenderform.get('allowCash')?.enable();
      this.tenderform.get('allowDeditCard')?.enable();
      this.tenderform.get('allowCreditCard')?.enable();
      this.tenderform.get('allowCheck')?.enable();
      this.tenderform.get('allowZellePayment')?.enable();
      this.tenderform.get('allowPaypalPayment')?.enable();
      this.tenderform.get('allowVenomoPayment')?.enable();
    }
  }

  initForm() {
    this.tenderform = this._fb.group({
      enableTenderForm: [this.tenderSettingData.active],
      enableTips : [this.tenderSettingData.enableTips],
      promptCustomerTip : [this.tenderSettingData.promptCustomerTip],
      allowCash : [this.tenderSettingData.enableCashPayment],
      allowDeditCard : [this.tenderSettingData.enableDebitCartPayment],
      allowCreditCard : [this.tenderSettingData.enableCreditCardPayment],
      allowCheck : [this.tenderSettingData.enableCheckPayment],
      allowZellePayment : [this.tenderSettingData.enableZellePayment],
      allowPaypalPayment : [this.tenderSettingData.enablePaypalPayment],
      allowVenomoPayment : [this.tenderSettingData.enableVenomoPayment],
    })

    this.f.enableTips.valueChanges.subscribe((v)=>{
      this.f.promptCustomerTip.setValue(false)
    })
  }
  get f(){
    return this.tenderform?.controls
  }
  saveFn(){
    let tenderValue: TenderScreenConfig = {
      enableTips: this.tenderform.get('enableTips').value,
      promptCustomerTip: this.tenderform.get('promptCustomerTip').value,
      enableCashPayment: this.tenderform.get('allowCash').value,
      enableDebitCartPayment: this.tenderform.get('allowDeditCard').value,
      enableCreditCardPayment: this.tenderform.get('allowCreditCard').value,
      enableCheckPayment: this.tenderform.get('allowCheck').value,
      enableZellePayment: this.tenderform.get('allowZellePayment').value,
      enablePaypalPayment: this.tenderform.get('allowPaypalPayment').value,
      enableVenomoPayment: this.tenderform.get('allowVenomoPayment').value,
      // active: this.tenderform.get('enableTenderForm').value,
       isSaved: true,
    };
    try{
      this._abd.showSpinner()
      this._businessSetupService.saveTenderConfiguration(tenderValue).subscribe(res=>{
        if(res.success){
            this._abd.systemConfigs.tenderScreenConfig.isSaved = true
            this.widgetSaved.emit(true)
          this._connMessageService.showToastMessage(`Tender settings saved successfully!`, 'success')
          this._businessSetupService.showTenderConfiguration = false
          this._abd.getBusinessConfig().subscribe(res=>{
            if(res.success){
              this._businessSetupService.initWidget("tender");
              this._abd.hideSpinner()
            }
          })
        }
      })
    }
    catch{
      this._connMessageService.showToastMessage(`Error saving tender settings!`, 'error')
      this._abd.hideSpinner()
    }
  }

  cancelButtonClick(){
    this._businessSetupService.showTenderConfiguration = false
  }

}
