<div class=" subscription-page">
    <div class="card border-dotted-custom mb-5">
        <div class="flex  flex-column  w-full  card-container ">
            <div class="flex justify-content-start">
                <h3 class="font-bold text-2xl">Billing Address</h3>
            </div>
            <div class="flex flex-column xxl:col-4 xl:col-4 lg:col-4 p-4 border-custom justify-content-center">

                <div class="flex flex-row justify-content-start align-items-center ">
                    <div class="flex justify-content-start font-bold text-xl ">
                        Address 1
                    </div>
                    <div class="flex justify-content-start text-lg border-success mx-4 py-1 px-3 ">
                        Primary
                    </div>
                </div>
                <div class="flex flex-column justify-content-star py-3 ">
                    <div class="flex justify-content-start text-lg py-1 muted-color">
                        {{usertPaymentProfile?.paymentProfile?.billTo.address}}
                    </div>
                    <div class="flex justify-content-start text-lg py-1 muted-color">
                        {{usertPaymentProfile?.paymentProfile?.billTo.city}} {{usertPaymentProfile?.paymentProfile?.billTo.state}}
                        {{usertPaymentProfile?.paymentProfile?.billTo.zip}}
                    </div>
                    <div class="flex justify-content-start text-lg muted-color">
                        {{usertPaymentProfile?.paymentProfile?.billTo.country}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card border-dotted-custom">
        <div class="flex  flex-column  w-full  card-container ">

            <div class="flex flex-row xxl:col-4 xl:col-4 lg:col-4 p-4 border-custom justify-content-between">
                <h3 class="font-bold text-2xl">Credit/Debit Card</h3>
                <button pButton (click)="changeCard()" type="button" label="Change Card" *ngIf="_globals.whiteLabel?.isChangeCard" ></button>
            </div>

            <div class="flex flex-column xxl:col-4 xl:col-4 lg:col-4 p-4 border-custom justify-content-center">

                <div class="flex flex-row justify-content-start align-items-center ">
                    <div class="flex justify-content-start font-bold text-xl ">
                       {{usertPaymentProfile?.paymentProfile?.billTo.firstName}} {{usertPaymentProfile?.paymentProfile?.billTo.lastName}}
                    </div>
                    <div class="flex justify-content-start text-lg border-success mx-4 py-1 px-3 ">
                        Primary
                    </div>
                </div>
                <div class="flex flex-column justify-content-star py-3 ">
                    <div class="flex justify-content-start text-lg py-1">
                        {{usertPaymentProfile?.paymentProfile?.payment?.item?.cardType}}  {{usertPaymentProfile?.paymentProfile?.payment?.item?.cardNumber}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


