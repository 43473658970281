import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { PurchaseService } from 'src/app/_services/purchase.service';
import { RewardProgramService } from 'src/app/_services/reward-program.service';

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.scss']
})
export class PurchaseOrderComponent implements OnInit {

    purchaseOrderConfigurationForm: FormGroup
    inputSendEmail: boolean = true;
    inputEnableFooter: boolean = true;
    inputEnableBody: boolean = true;
  
    _global = GlobalService
    textValue: string;
    constructor(
        private fb: FormBuilder,
        public _abd: AppBaseDataService,
        private _messageService: MessageService,
        private _purchaseService: PurchaseService,
        private _confirmationService: ConfirmationService,
    ) { }

    ngOnInit(): void {
    
        this.purchaseOrderConfigurationForm = this.fb.group({
            InputBodyText: ['', Validators.required],
            InputFooterText: ['', Validators.required],
        });
      this.textValue = `
        <p>Hi Vender,<p/>
    <p>Please find our attached purchase order for your reference. We kindly request your confirmation of the order and estimated delivery timeframe. If you have any questions or require further clarification, please do not hesitate to contact us.</p>
    <br />
    <p>Thank you.</p>
    <br />
    <p>${this._global.userInfo.displayName}</p>
    <p>${this._global.userInfo.companyName}</p>
    <p>${this._global.userInfo.phone}</p>
    <p>${this._global.userInfo.email}</p>
    `;
        this.setInitialValuesForForm();
    }

    setInitialValuesForForm(){
       try {
            this._abd.showSpinner()
            this._purchaseService.getPurchaseOrderConfigurationDetails().subscribe((r) => {
                if (r.success) {
                    this.inputSendEmail = r.data.po_sendemail;
                    this.inputEnableBody = r.data.po_enablebodynote;
                    if(r.data.po_bodynote !== ""){
                        this.f.InputBodyText.setValue(r.data.po_bodynote);
                    }else{
                        this.f.InputBodyText.setValue(this.textValue);
                    }
                    this.inputEnableFooter = r.data.po_enablefooternote;
                    this.f.InputFooterText.setValue(r.data.po_footernote);
                    this._abd.hideSpinner()
                }
                else {
                    this._abd.hideSpinner()
                    this._messageService.add({ severity: 'error', summary: 'Unable to load saved values of configuration' })
                }
            }, error => {
                this._abd.hideSpinner()
                this._messageService.add({ severity: 'error', summary: 'Unable to load saved values of configuration' })
            })
        } catch (error) {

        }
    }

    get f() { return this.purchaseOrderConfigurationForm.controls; }
    resetDefaultEmail(){
        this._confirmationService.confirm({
                  message: `Clicking 'Yes' will delete your current content. Are you sure you want to proceed?`,
                  header: 'Email Body',
                  icon: 'pi pi-info-circle',
                  accept: () => {
                        this.f.InputBodyText.setValue(this.textValue);
                  },
                 
                });
       
    }
    saveConfigurationInfo(){
        try {
            if(this.inputEnableBody){
                if (this.f.InputBodyText.value.trim() == '') {
                    this._messageService.add({ severity: 'error', summary: 'Please add value in body note' })
                    return false;
                }
            }else if (this.inputEnableFooter) {
                if (this.f.InputFooterText.value.trim() == '') {
                    this._messageService.add({ severity: 'error', summary: 'Please add value in footer note' })
                    return false;
                }
            }
            let data = {
                "po_enable": true,
                "po_sendemail": this.inputSendEmail,
                "po_enablebodynote": this.inputEnableBody,
                "po_bodynote": this.f.InputBodyText.value.trim(),
                "po_enablefooternote": this.inputEnableFooter,
                "po_footernote": this.f.InputFooterText.value.trim()

            }
            this._abd.showSpinner()

            this._purchaseService.savePurchaseOrderConfiguration(data).subscribe((r) => {
                if (r.success) {
                    this._abd.hideSpinner()
                    // this._messageService.add({ severity: 'success', summary: 'Footer note configuration for purchase order has been saved' })
                    this._messageService.add({ severity: 'success', summary: 'Configuration for purchase order has been saved' })
                }
                else {
                    this._abd.hideSpinner()
                    // this._messageService.add({ severity: 'error', summary: 'Unable to save footer note configuration for purchase order' })
                    this._messageService.add({ severity: 'error', summary: 'Unable to save configuration for purchase order' })
                }
            }, error => {
                this._abd.hideSpinner()
                // this._messageService.add({ severity: 'error', summary: 'Unable to save footer note configuration for purchase order' })
                this._messageService.add({ severity: 'error', summary: 'Unable to save configuration for purchase order' })
            })
        } catch (error) {
            this._messageService.add({ severity: 'error', summary: 'Unable to perform action' })
        }


    }

}
