import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { GlobalService } from 'src/app/_services/global.service';
import { SalesChannelService } from '../../../_services/bizz-sales-channel.services';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';
import { CouponReportsService } from '../../../_services/reports.services';


@Component({
  selector: 'app-bizz-sales-channel-report-list',
  templateUrl: './bizz-sales-channel-report-list.component.html',
  styleUrls: ['./bizz-sales-channel-report-list.component.scss']
})
export class BizzSalesChannelReportListComponent implements OnInit {

  _globals = GlobalService
  @ViewChild('op') op: OverlayPanel
  page = 0
  rows = 10
  first = 0
  totalRecords = 0
  dataList: any = null
  searchText = ''
  lastSearchText = ''
  masterDataList: any = null
  isViewOnly = false
  orderParam = ''
  orderBy = 'asc'
  salesChannelList:any[] = [];
  subscriptionPlanList:any[]=[];
  couponCodesList:any[]=[];
 
  totalValues:any;

  colHeader = this._abd.reports.filter(x => x.paraReportName == 'Sales Channel Report')[0].colheader;
  colflds = this._abd.reports.filter(x => x.paraReportName == 'Sales Channel Report')[0].colflds;
 

  @ViewChild('p', { static: false }) paginator: Paginator;


  constructor(
    public salesChannelServices: SalesChannelService,
    private _spinner: NgxSpinnerService,
    private _connMessageService: ConnMessageService,
    private _confirmationService: ConfirmationService,
    public _abd: AppBaseDataService,
    public _filterService: FilterServiceService,
    private currencyPipe: CurrencyPipe,
    private _subscriptionPlanService: SubscriptionPlanService,
    private _salesChannelService: SalesChannelService,
    private _reportsService: CouponReportsService,
  ) { }

  ngOnInit(): void {
    this._globals.componentName = 'Reports'
    this._globals.secondTitle = 'Sales Channel'

  }

  ngAfterViewInit() {
    let ti = null
    ti = setInterval(() => {
      let ppages = this._filterService.getPages(this._globals.componentName)
      if (ppages) {
        let lastpage = parseInt(ppages.page)
        if (lastpage < 0) lastpage = 0
        if (lastpage > 0) {
          this.page = lastpage
          this.first = lastpage * this.rows
        }
      }
      let filter = this._filterService.getFilters(this._globals.componentName)
      if (filter) {
        if (filter.filterValue) this._filterService.searchText = filter.filterValue
         if (filter.startDate) this._filterService.dateRange[0] = new Date(filter.startDate)
        if (filter.endDate) this._filterService.dateRange[1] = new Date(filter.endDate)
      } 
      clearInterval(ti)
      this.getDataList()
    }, 500);
  }

  getDataList() {
    this.page = this.page < 0 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows
    
    let obj:any = {
      "channelId": this._filterService.selectedSaleChannel,
      "startDate": formatDate(this._filterService.dateRange[0], 'yyyy-MM-dd', 'en-US'),
      "endDate": formatDate(this._filterService.dateRange[1], 'yyy-MM-dd', 'en-US'),

    }
    if(obj.channelId !== null){
      obj =`startDate=${obj.startDate}&endDate=${obj.endDate}&ChannelId=${+obj.channelId.id}`
    }else{
      obj =`startDate=${obj.startDate}&endDate=${obj.endDate}&ChannelId=`
    }
    try {
      this._abd.showSpinner()
      this._reportsService.getSalesChannelReportsList(obj).subscribe(res => {
        if (res.success) {
          this.totalRecords = res.totalRecords
          this.dataList = res.data
          this.masterDataList = this.dataList;
          this._abd.hideSpinner()
          this.getTotal()
        }
      })

      this._filterService.updateFilters(this._globals.componentName, obj)
      this._filterService.updatePages(this._globals.componentName, { page: this.page, rows: this.rows })

    } catch { this._abd.hideSpinner() }
  }
  getTotal(){
    this.totalValues = {
      // active
      leads:this.getTotalLeads(),
      signup:this.getTotalSigns(),
      trial:this.getTotalTrial(),
      lite:this.getTotalLite(),
      standard:this.getTotalStandard(),
      plus:this.getTotalPlus(),
      premium:this.getTotalPremium(),
      custom:this.getTotalCustom(),
      planCost:this.getPlanCost(),
      hardwareCost:this.getTotalHardwareCost(),
      addons:this.getTotalAddons(),
    }
  }

  ngOnDestroy() {
    this._globals.componentName = ''
    this._globals.secondTitle = ''
  }

  refrestDataList(e: any) {
    this.getDataList();
  }


  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  filterButtonPressed() {
    this.getDataList()
  }

  onPageChange(e) {
    this.page = e.page
    this.rows = e.rows
    this._abd.setOptionToLocalStorage(this._globals.componentName, 'last-page', e.page)

    this.getDataList()
  }
  exportExcel() {
    let list = this.dataList.map((v)=>    
    v.list.map((x,i)=>{
      return{
       ...x,channelName:i === 0 ? x.channelName : '',total:x.planCost + x.hardwareCost + x.addons
      }
    })
)
list = list.flat(Infinity)
let colflds =['channelName', 'couponCode', 'leads','signup','trial', 'lite','standard','plus','premium','custom','planCost','hardwareCost','addons','total']
    this._abd.exportExcel(list, this.colHeader[0], colflds, 'Sales Channel Report')
  }

  exportPdf() {
    let list = this.dataList.map((v)=>    
          v.list.map((x,i)=>{
            return{
             ...x,channelName:i === 0 ? x.channelName : '',total:x.planCost + x.hardwareCost + x.addons
            }
          })
      
  )
  list = list.flat(Infinity)
   let colflds =['channelName', 'couponCode', 'leads','signup','trial', 'lite','standard','plus','premium','custom','planCost','hardwareCost','addons','total']
    this._abd.exportPdf(list, this.colHeader, this.colflds, 'Sales Channel Report', true,false,false)
  }
  calculateSubTotal(col: string,list?:any) {
    let total = "0";
    if(list && list.length > 0){
      if (col.includes("leads") || col.includes("signup") || col.includes("trial") || col.includes("lite") || col.includes("standard") || col.includes("plus") || col.includes("premium") || col.includes("custom")) {
        total = list.reduce((sum, item) => sum + item[col], 0);
      }
      else {
        total = this.currencyPipe.transform(list.reduce((sum, item) => sum + item[col], 0), 'USD', "$");
      }
      return total;
    }

  }

  getTotalLeads(): number {
    return this.dataList.reduce((total, value) => {
      return total + value.list.reduce((valueTotal, detail) => {
        return valueTotal + detail.leads;
      }, 0);
    }, 0);
  }
  getTotalSigns(): number {
    return this.dataList.reduce((total, value) => {
      return total + value.list.reduce((valueTotal, detail) => {
       
        return valueTotal + detail.signup;
      }, 0);
    }, 0);
  }
  getTotalTrial(): number {
    
    return this.dataList.reduce((total, value) => {
      return total + value.list.reduce((valueTotal, detail) => {
       
        return valueTotal + detail.trial;
      }, 0);
    }, 0);
  }
  getTotalLite(): number {
    
    return this.dataList.reduce((total, value) => {
      return total + value.list.reduce((valueTotal, detail) => {
       
        return valueTotal + detail.lite;
      }, 0);
    }, 0);
  }
  getTotalStandard(): number {
    
    return this.dataList.reduce((total, value) => {
      return total + value.list.reduce((valueTotal, detail) => {
       
        return valueTotal + detail.standard;
      }, 0);
    }, 0);
  }
  getTotalPlus(): number {
    
    return this.dataList.reduce((total, value) => {
      return total + value.list.reduce((valueTotal, detail) => {
       
        return valueTotal + detail.plus;
      }, 0);
    }, 0);
  }
  getTotalPremium(): number {
    
    return this.dataList.reduce((total, value) => {
      return total + value.list.reduce((valueTotal, detail) => {
       
        return valueTotal + detail.premium;
      }, 0);
    }, 0);
  }
  getTotalCustom(): number {
    
    return this.dataList.reduce((total, value) => {
      return total + value.list.reduce((valueTotal, detail) => {
       
        return valueTotal + detail.custom;
      }, 0);
    }, 0);
  }
  getPlanCost(): number {
    
    return this.dataList.reduce((total, value) => {
      return total + value.list.reduce((valueTotal, detail) => {
       
        return valueTotal + detail.planCost;
      }, 0);
    }, 0);
  }
  getTotalHardwareCost(): number {
    
    return this.dataList.reduce((total, value) => {
      return total + value.list.reduce((valueTotal, detail) => {
       
        return valueTotal + detail.hardwareCost;
      }, 0);
    }, 0);
  }
  getTotalAddons(): number {
    
    return this.dataList.reduce((total, value) => {
      return total + value.list.reduce((valueTotal, detail) => {
       
        return valueTotal + detail.addons;
      }, 0);
    }, 0);
  }
  grandTotal(planCost,hardwareCost,addons){
    const addonsValue = parseFloat(addons.replace(/[^\d.-]/g, ''));
    const hardwareCostValue = parseFloat(hardwareCost.replace(/[^\d.-]/g, ''));
    const planCostValue = parseFloat(planCost.replace(/[^\d.-]/g, ''));
    const totalCost = addonsValue + hardwareCostValue + planCostValue;
    return totalCost
  }

  // customSort(e) {
  //   const last = this.orderParam
  //   switch (e.sortField) {

  //     case 'salechannel':
  //       this.orderParam = 'AppNotificationText'
  //       break;
  //     case 'couponCode':
  //       this.orderParam = 'couponCode'
  //       break;
  //     case 'discountType':
  //       this.orderParam = 'discountValueType'
  //       break;
  //     case 'discountAmount':
  //       this.orderParam = 'discountAmount'
  //       break;
  //     case 'subscriptionPlanTitle':
  //       this.orderParam = 'subscriptionPlanTitle'
  //       break;
  //     case 'couponUsed':
  //       this.orderParam = 'couponUsed'
  //       break;
  //     case 'discountAmount':
  //       this.orderParam = 'discountAmount'
  //       break;
  //     default:
  //       this.orderParam = ''
  //   }
  //   if (this.orderParam !== '') {
  //     this.orderBy = e.sortOrder == 1 ? 'asc' : 'desc'
  //     if (last !== this.orderParam) this.orderBy = 'asc'
  //     this.getDataList()
  //   }
  // }

}