<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'time-tracking-detail-report'"
        (filterCallbackFunc)="callbackFuncFromFilter()"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    ></app-report-top-filter-bar>

     <p-table [value]="dataList" dataKey="name" #dt [columns]="csvcols" styleClass="p-datatable-striped" [tableStyle]="{ width: 'max-content' }"   responsiveLayout="scroll" [autoLayout]="true" *ngIf="dataList?.length > 0">
        <ng-template pTemplate="header">
            <tr>
                <th ><strong>Date</strong> </th>
                <th ><strong>Employee ID</strong> </th>
                <th ><strong>Employee Name</strong> </th>
                <th ><strong>Employee Role</strong> </th>
                <th ><strong>Employee Email</strong> </th>
                <th><strong>Clock-In Time</strong></th>
                <th ><strong>Clock-Out Time</strong> </th>
                <th ><strong>Total Hours</strong> </th>
                </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data let-expanded="expanded">
            <tr>
                <td style="vertical-align: baseline;padding:20px;">{{data.date | date:'MM/dd/yyyy'}}</td>
                <td style="vertical-align: baseline;padding:20px;">{{ data.employeeID }}</td>
                <td style="vertical-align: baseline;padding:20px;">{{ data.employeeName}}</td>
                <td style="vertical-align: baseline;padding:20px;">{{ data.employeeRole }}</td>
                <td style="vertical-align: baseline;padding:20px;">{{ data.employeeEmail }}</td>
                <td style="vertical-align: baseline;" colspan="1">
                    <p-table
                    [value]="data?.clockInTimes"
                    [autoLayout]="true"
                    [tableStyle]="{ width: 'min-content' }"
                    responsiveLayout="scroll"
                    dataKey="id"
                    rowGroupMode="subheader"
                >
                    <ng-template
                        pTemplate="body"
                        let-clock
                    >
                        <tr>
                            <td>{{clock === "0001-01-01T00:00:00"? "-": clock | date:'h:mm a'}}</td>
                           
                        </tr>
                    </ng-template>
                    <ng-template
                        pTemplate="footer"
                        let-clock
                    >
                        <tr >
                         <td>
                            <strong>Total</strong>
                         </td>  
                        </tr>
                    </ng-template>
                    <ng-template
                        pTemplate="emptymessage"
                    >
                        <tr>
                            <td colspan="6">
                                No data found!
                            </td>
                        </tr>
                    </ng-template>
                    </p-table>
                </td>
                <td style="vertical-align: baseline;" colspan="1">
                    <p-table
                    [value]="data?.clockOutTimes"
                    [autoLayout]="true"
                    [tableStyle]="{ width: 'min-content' }"
                    responsiveLayout="scroll"
                    dataKey="id"
                    rowGroupMode="subheader"
                >
                    <ng-template
                        pTemplate="body"
                        let-clock
                    >
                        <tr>
                            <td>{{clock === "0001-01-01T00:00:00"? "-": clock | date:'h:mm a'}}</td>
                           
                        </tr>
                    </ng-template>
                    <ng-template
                        pTemplate="emptymessage"
                    >
                        <tr>
                            <td colspan="6">
                                No data found!
                            </td>
                        </tr>
                    </ng-template>
                    </p-table>
                </td>
                <td style="vertical-align: baseline;" colspan="1">
                    <p-table
                    [value]="data?.totalHours"
                    [autoLayout]="true"
                    [tableStyle]="{ width: 'min-content' }"
                    responsiveLayout="scroll"
                    dataKey="id"
                    rowGroupMode="subheader"
                >
                    <ng-template
                        pTemplate="body"
                        let-clock
                    >
                        <tr>
                            <td>{{clock}}</td>
                           
                        </tr>
                    </ng-template>
                    <ng-template
                        pTemplate="footer"
                        let-clock
                    >
                        <tr>
                            <td style="padding:10px;" >{{getTotalHours(data?.totalHours)}}</td>
                           
                        </tr>
                    </ng-template>
                    <ng-template
                        pTemplate="emptymessage"
                    >
                        <tr>
                            <td colspan="6">
                                No data found!
                            </td>
                        </tr>
                    </ng-template>
                    </p-table>
                </td>
             
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr *ngIf="dataList?.length > 0">
                <td rowspan="2" colspan="1" style="text-align: center">
                    <strong>Total</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style="padding:0px 20px;">{{totalTime}}</td>
               
            </tr>
        </ng-template>

    </p-table>
    <div class="flex" *ngIf="dataList?.length==0">
        <div class="flex justify-content-center" style="width: 100%">
            <span class="control-description text-muted text-align-center"> No data found!</span>
        </div>
    </div>

</div>
