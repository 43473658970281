import { Injectable } from '@angular/core';
// import { exists } from 'fs';

@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {

  constructor() { }

  dbExists(name, callback) {
    let dbExists = true;
    let request = window.indexedDB.open(name, 3);
    request.onupgradeneeded = function (e) {
      if (request.result.version === 1) {
        dbExists = false;

        window.indexedDB.deleteDatabase(name);
        if (callback) callback(dbExists);
      }
    };
    request.onsuccess = function (e) {
      if (dbExists) {
        if (callback) callback(dbExists);
      }
      else {
        // window.indexedDB.deleteDatabase(name);
      }
    };
  }
 
  doesDatabaseExits(dbName: string) {
    let ret = false
    this.dbExists(dbName, function (exists) {
      ret = exists
      if(!exists) {
        let request = window.indexedDB.open(dbName, 3).onsuccess  = function (e) {

        }
      }
    });
    return ret
  }




}
