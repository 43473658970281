<p-overlayPanel
    #op
    [style]="{ 'min-width': '200px', padding: '-20px', margin: '-20px' }"
>
    <ng-template pTemplate>
        <div class="conn-grid" style="margin-top: 1px">
            <div></div>
            <button
                pButton
                pRipple
                type="button"
                label="Edit"
                class="p-button-text"
                [style]="{ width: '100%' }"
                icon="pi pi-pencil"
                iconPos="right"
                (click)="editButtonClicked()"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                label="Delete"
                [style]="{ width: '100%' }"
                class="p-button-danger p-button-text"
                icon="pi pi-trash"
                iconPos="right"
                (click)="optionDeleteClicked()"
            ></button>
        </div>
    </ng-template>
</p-overlayPanel>
<div class="conn-card">
    <app-report-top-filter-bar
        [paraReportName]="'sales-channel-report'"
        (filterCallbackFunc)="callbackFuncFromFilter($event)"
        (filterCallbackFuncUpdateFilter)="filterButtonPressed()"
        (exportExcelFn)="exportExcel()"
        (exportPdfFn)="exportPdf()"
    ></app-report-top-filter-bar>
    <br />
   
    <div class="grid summary" *ngIf="dataList?.length > 0">
        <div class="col">
            <div
                class="conn-card"
                style="background: var(--brandColor); color: white"
            >
                <div class="grid">
                    <div class="col-12 text-align-center">
                        <div>
                            <label class="widget-title"> Total Sales </label>
                        </div>
                        <div>
                            <label class="widiget-figure-main">
                                {{totalValues?.planCost + totalValues?.hardwareCost + totalValues?.addons  | currency}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="conn-card" style="background: #eef0f9">
                <div class="grid">
                    <div class="col-12 text-align-center">
                        <div>
                            <label class="widget-title">
                              Total Leads
                            </label>
                        </div>
                        <div>
                            <label class="widiget-figure-main">
                                {{ totalValues?.leads  }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col">
            <div class="conn-card" style="background: #eef0f9">
                <div class="grid">
                    <div class="col-12 text-align-center">
                        <div>
                            <label class="widget-title">Active Channels
                            </label>
                        </div>
                        <div>
                            <label class="widiget-figure-main">
                                {{ totalValues?.activeChannel }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
   
        <div class="col">
            <div class="h-full pb-3">
                <div class="h-full border-round-lg" style="background: #eef0f9">
             <div class="font-bold flex justify-content-center py-3 ">
                Active Sign- Ups
             </div>
             <div class="flex flex-row col-12">
                <span class="col-2 p-0 m-0"> <span class="flex align-items-center flex-column mx-1 p-2 border-round-lg" style="background-color: white;">
                    <span class="box-title">Free Trial</span>
                    <span class="box-value">{{totalValues?.trial }}</span>
                </span></span>
                <span class="col-2 p-0 m-0"> 
                     <span class="flex align-items-center flex-column mx-1 p-2 border-round-lg" style="background-color: white;">
                    <span class="box-title">Lite </span>
                    <span class="box-value">{{totalValues?.lite }}</span>
                </span></span>
                <span class="col-2 p-0 m-0">   <span class="flex align-items-center flex-column mx-1 p-2 border-round-lg" style="background-color: white;">
                    <span class="box-title">Standard</span>
                    <span class="box-value">{{totalValues?.standard }}</span>
                </span></span>
                <span class="col-2 p-0 m-0">      <span class="flex align-items-center flex-column mx-1 p-2 border-round-lg" style="background-color: white;">
                    <span class="box-title">Plus</span>
                    <span class="box-value">{{totalValues?.plus }}</span>
                </span></span>
                <span class="col-2 p-0 m-0"><span class="flex align-items-center flex-column mx-1 p-2 border-round-lg" style="background-color: white;">
                    <span class="box-title">Premium</span>
                    <span class="box-value">{{totalValues?.premium }}</span>
                </span></span>
                <span class="col-2 p-0 m-0"><span class="flex align-items-center flex-column mx-1 p-2 border-round-lg" style="background-color: white;">
                    <span class="box-title">Custom</span>
                    <span class="box-value">{{totalValues?.custom }}</span>
                </span></span>
             </div>
            </div>
            </div>
            
            </div>
    </div>
    <br />
    <div class="card" style="width: 100%; overflow-x: auto;" *ngIf="dataList?.length > 0">
        <p-table [value]="dataList" sortField="id" sortMode="single" dataKey="id" rowGroupMode="subheader" groupRowsBy="id" [tableStyle]="{ width: 'min-content' }">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:15vw">Channel Name</th>
                    <th style="width:10vw">Total</th>
                    <th style="width:10vw">Code</th>
                    <th style="width:10vw">Leads</th>
                    <th style="width:10vw">Signup</th>
                    <th style="width:10vw">Trial</th>
                    <th style="width:10vw">Lite</th>
                    <th style="width:10vw">Standard</th>
                    <th style="width:10vw">Plus</th>
                    <th style="width:10vw">Preimum</th>
                    <th style="width:10vw">Custom</th>
                    <th style="width:10vw">Plan Cost</th>
                    <th style="width:10vw">Hardware</th>
                    <th style="width:10vw">Add-ons</th>
                    <th style="width:10vw">Total</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="groupheader" let-customer let-rowIndex="rowIndex" let-expanded="expanded">
                <tr *ngIf="expanded">
                    <td class="flex align-items-center" colspan="14" >
                      <span class="font-bold ml-2">{{customer.channelName}}</span>
                        <button type="button" pButton pRipple [pRowToggler]="customer" class="p-button-text p-button-rounded p-button-plain mr-2" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                  </tr> 
                  <!-- List Total -->
                  <tr *ngIf="!expanded">
                     <td class="flex align-items-center" >
                       <span class="font-bold ml-2">{{customer.channelName}}</span>
                        <button type="button" pButton pRipple [pRowToggler]="customer" class="p-button-text p-button-rounded p-button-plain mr-2" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                                        <th  style="text-align: start;padding:10px;">Total</th>
                                        <th  style="text-align: start;padding:10px;"></th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('leads',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('signup',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('trial',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('lite',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('standard',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('plus',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('premium',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('custom',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: center;padding:10px;">{{ calculateSubTotal('planCost',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: center;padding:10px;">{{ calculateSubTotal('hardwareCost',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: center;padding:10px;">{{ calculateSubTotal('addons',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: center;padding:10px;">{{ grandTotal(calculateSubTotal('planCost',customer?.list),calculateSubTotal('hardwareCost',customer?.list),calculateSubTotal('addons',customer?.list)) | currency }}</th>
                  </tr>  
                  <!-- / List Total -->
              
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-customer>
                <tr>
                    <td></td>
                    <td colspan="14" class="p-0"
              >
                            <p-table
                                [value]="customer?.list"
                                [autoLayout]="true"
                                [tableStyle]="{ width: 'min-content' }"
                                responsiveLayout="scroll"
                                dataKey="id"
                                rowGroupMode="subheader"
                            >
                                <ng-template
                                    pTemplate="body"
                                    let-order
                                >
                                    <tr>
                                        <td style="width:10vw;text-align: start;"></td>
                                        <td style="width:10vw;text-align: start;">{{order.couponCode}}</td>
                                        <td style="width:10vw;text-align: start;">{{order.leads}}</td>
                                        <td style="width:10vw;text-align: start;">{{order.signup}}</td>
                                        <td style="width:10vw;text-align: start;">{{order.trial}}</td>
                                        <td style="width:10vw;text-align: start;">{{order.lite}}</td>
                                        <td style="width:10vw;text-align: start;">{{order.standard}}</td>
                                        <td style="width:10vw;text-align: start;">{{order.plus}}</td>
                                        <td style="width:10vw;text-align: start;">{{order.premium}}</td>
                                        <td style="width:10vw;text-align: start;">{{order.custom}}</td>
                                        <td style="width:10vw;text-align: center;">{{order.planCost | currency}}</td>
                                        <td style="width:10vw;text-align: center;">{{order.hardwareCost | currency}}</td>
                                        <td style="width:10vw;text-align: center;">{{order.addons | currency}}</td>
                                        <td style="width:10vw;text-align: center;">{{order.planCost + order.hardwareCost + order.addons | currency}}</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="groupfooter" let-order>
                                  <!-- Main List Total -->
                                    <tr class="p-rowgroup-footer">
                                                            <th  style="text-align: start;padding:10px;">Total</th>
                                                            <th  style="text-align: start;padding:10px;"></th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('leads',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('signup',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('trial',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('lite',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('standard',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('plus',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('premium',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: start;padding:10px;">{{ calculateSubTotal('custom',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: center;padding:10px;">{{ calculateSubTotal('planCost',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: center;padding:10px;">{{ calculateSubTotal('hardwareCost',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: center;padding:10px;">{{ calculateSubTotal('addons',customer?.list) }}</th>
                                        <th style="width:10vw;text-align: center;padding:10px;">{{ grandTotal(calculateSubTotal('planCost',customer?.list),calculateSubTotal('hardwareCost',customer?.list),calculateSubTotal('addons',customer?.list)) | currency }}</th>
                                    </tr>
                                    <!-- / Main List Total -->
                                </ng-template>
                            
                                <ng-template
                                    pTemplate="emptymessage"
                                >
                                    <tr>
                                        <td colspan="6">
                                            There are no order for
                                            this product yet.
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                  
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer" let-customer>
                <tr>

                    <th style="width:15vw;text-align:start;padding:10px;" ></th>
                    <th style="width:15vw;text-align:start;padding:10px;" >Total</th>
                     <th style="width:15vw;text-align:start;padding:10px;" ></th>
                     <th style="width:10vw;text-align:start;padding:10px;">{{totalValues?.leads}}</th>
                     <th style="width:10vw;text-align:start;padding:10px;">{{totalValues?.signup}}</th>
                     <th style="width:10vw;text-align:start;padding:10px;">{{totalValues?.trial}}</th>
                     <th style="width:10vw;text-align:start;padding:10px;">{{totalValues?.lite}}</th>
                     <th style="width:10vw;text-align:start;padding:10px;">{{totalValues?.standard}}</th>
                     <th style="width:10vw;text-align:start;padding:10px;">{{totalValues?.plus}}</th>
                     <th style="width:10vw;text-align:start;padding:10px;">{{totalValues?.premium}}</th>
                     <th style="width:10vw;text-align:start;padding:10px;">{{totalValues?.custom}}</th>
                     <th style="width:10vw;text-align:center;padding:10px;">{{totalValues?.planCost  | currency}}</th>
                     <th style="width:10vw;text-align:center;padding:10px;">{{totalValues?.hardwareCost  | currency}}</th>
                     <th style="width:10vw;text-align:center;padding:10px;">{{totalValues?.addons  | currency}}</th>
                     <th style="width:10vw;text-align:center;padding:10px;">{{totalValues?.planCost + totalValues?.hardwareCost + totalValues?.addons  | currency}}</th>
            </ng-template>
        </p-table>
    </div>
    <div class="flex" *ngIf="dataList?.length == 0">
        <div class="text-align-center" style="width: 100%">
            <span class="control-description text-muted text-align-center">
                No data found!</span
            >
        </div>
    </div>
    <div *ngIf="totalRecords > 9">
        <p-paginator
            #p
            [first]="first"
            *ngIf="dataList?.length"
            (onPageChange)="onPageChange($event)"
            [rows]="rows"
            [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 25, 50, 100, 500, 1000]"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Records: {first} of {last}, Total Records: {totalRecords}"
        >
        </p-paginator>
    </div>
</div>

<p-dialog
    *ngIf="salesChannelServices.showEditChannel"
    header="Edit Channel"
    [(visible)]="salesChannelServices.showEditChannel"
    [style]="{ width: '40%' }"
    modal="true"
>
    <app-bizz-sales-channel-form
        [isNew]="false"
        [isViewOnly]="isViewOnly"
        (onSubmit)="refrestDataList($event)"
    ></app-bizz-sales-channel-form>
</p-dialog>
<ng-container *ngIf="salesChannelServices.showNewEditChannel">
    <p-dialog
        header="Add a New Channel"
        [(visible)]="salesChannelServices.showNewEditChannel"
        [style]="{ width: '40%' }"
        modal="true"
    >
        <app-bizz-sales-channel-form
            [singupBusinessConfig]="true"
            [isNew]="true"
            [isViewOnly]="false"
            (onSubmit)="refrestDataList($event)"
        ></app-bizz-sales-channel-form>
    </p-dialog>
</ng-container>
