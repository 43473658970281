<div>
    <div>
        <div>
            <form
                [formGroup]="editform"
                (ngSubmit)="editform.valid && saveFn()"
                autocomplete="off"
            >
                <div class="conn-grid-2x">
                    <div style="margin-top: 20px">
                        <h4 style="margin-bottom: 20px">
                            General Information
                            <!-- {{ _vendorService?.activeItem.city }} -->
                        </h4>
                        <div>
                            <label class="control-label"
                                ><strong
                                    >Vendor
                                    <span style="color: red; font-size: 1.25rem"
                                        >*</span
                                    >
                                </strong></label
                            >
                            <br />
                            <app-text-input
                                [formControl]="
                                    $any(editform.controls)['vendorName']
                                "
                                [name]="'Vendor Name'"
                                [label]="'Vendor Name'"
                                [description]="
                                    'Set the name of Vendor, it must be unique'
                                "
                                (input)="
                                    _vendorService?.activeItem.vendorName =
                                        $event.target.value
                                "
                            ></app-text-input>
                        </div>

                        <div style="margin-top: 20px">
                            <label class="control-label"
                                ><strong>Vendor Account </strong></label
                            >
                            <br />
                            <app-text-input
                                [formControl]="
                                    $any(editform.controls)['account']
                                "
                                [label]="'Vendor Account'"
                                (input)="
                                    _vendorService?.activeItem.account =
                                        $event.target.value
                                "
                            ></app-text-input>
                        </div>

                        <div style="margin-top: 20px">
                            <label class="control-label"
                                ><strong>Company </strong></label
                            >
                            <br />
                            <app-text-input
                                [formControl]="
                                    $any(editform.controls)['company']
                                "
                                [label]="'Company'"
                                (input)="
                                    _vendorService?.activeItem.company =
                                        $event.target.value
                                "
                            ></app-text-input>
                        </div>

                        <div style="margin-top: 20px">
                            <label class="control-label"
                                ><strong>Website Link </strong></label
                            >
                            <br />
                            <app-text-input
                                [formControl]="
                                    $any(editform.controls)['vendorWebsite']
                                "
                                [label]="'Website Link'"
                                (input)="
                                    _vendorService?.activeItem.vendorWebsite =
                                        $event.target.value
                                "
                            ></app-text-input>
                        </div>

                        <div style="margin-top: 20px">
                            <label class="control-label"
                                ><strong>Email </strong></label
                            >
                            <br />
                            <app-text-input
                                [formControl]="$any(editform.controls)['email']"
                                [label]="'Email'"
                                (input)="
                                    _vendorService?.activeItem.email =
                                        $event.target.value
                                "
                            ></app-text-input>
                        </div>

                        <div
                            style="margin-top: 20px"
                            [style]="{ width: '100%' }"
                        >
                            <label class="control-label"
                                ><strong
                                    >Phone
                                    <span style="color: red; font-size: 1.25rem"
                                        >*</span>
                                </strong></label
                            >
                            <br />

                            <div class="p-inputgroup">
                                <app-phone-input
                                    [formControl]="
                                        $any(editform.controls)['phone']
                                    "
                                    (input)="
                                        _vendorService?.activeItem.phone =
                                            $event.target.value
                                    "
                                >
                                </app-phone-input>

                            </div>
                        </div>
                    </div>

                    <div style="margin-top: 20px" [style]="{ width: '100%' }">
                        <h4 style="margin-bottom: 20px">
                            Contact Information
                            <!-- {{ _vendorService?.activeItem.city }} -->
                        </h4>

                        <div style="margin-top: 20px">
                            <label class="control-label"
                                ><strong>Street </strong></label
                            >
                            <br />
                            <app-text-input
                                [formControl]="
                                    $any(editform.controls)['street']
                                "
                                [label]="'Street'"
                                (input)="
                                    _vendorService?.activeItem.street =
                                        $event.target.value
                                "
                            ></app-text-input>
                        </div>

                        <div style="margin-top: 20px">
                            <label class="control-label"
                                ><strong>Zip Code </strong></label
                            >
                            <br />
                            <app-text-input
                                [formControl]="$any(editform.controls)['zip']"
                                [label]="'Zip Code'"
                                (input)="zipcodeInputEvent($event.target.value)"
                            ></app-text-input>
                            <!-- (input)="_vendorService.activeItem.zipcode= $event.target.value" -->
                        </div>

                        <div style="margin-top: 20px">
                            <label class="control-label"
                                ><strong>City </strong></label
                            >
                            <br />
                            <span
                                class="p-input-icon-right"
                                [style]="{ width: '100%' }"
                            >
                                <i
                                    class="pi pi-spin pi-spinner"
                                    *ngIf="showCityWait"
                                ></i>
                                <app-text-input
                                    formControlName="city"
                                    [label]="'City'"
                                    (input)="
                                        _vendorService?.activeItem.city =
                                            $event.target.value
                                    "
                                ></app-text-input>
                            </span>
                        </div>

                        <div style="margin-top: 20px">
                            <label class="control-label"
                                ><strong>State </strong></label
                            >
                            <br />
                            <p-dropdown
                                [style]="{
                                    width: '100%',
                                    'min-width': '200px',
                                    'margin-top': '7px'
                                }"
                                class="p-dropdown-1"
                                appendTo="body"
                                [options]="usaStates"
                                [(ngModel)]="_vendorService.selectedState"
                                optionLabel="name"
                                [filter]="true"
                                filterBy="name"
                                [ngModelOptions]="{ standalone: true }"
                                [disabled]="this._vendorService.isViewOnly"
                            ></p-dropdown>
                        </div>

                        <div style="margin-top: 20px">
                            <label class="control-label"
                                ><strong>Terms </strong></label
                            >
                            <br />
                            <textarea
                                pInputTextarea
                                formControlName="terms"
                                placeholder="Terms"
                                [style]="{
                                    width: '100%',
                                    height: '144px',
                                    'min-width': '200px',
                                    'margin-top': '7px'
                                }"
                                (input)="
                                    _vendorService.activeItem.terms =
                                        $event.target.value
                                "
                            ></textarea>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <br />
        <p-divider type="dashed"></p-divider>
        <div
            *ngIf="showActionButtons"
            class="flex justify-content-end flex-wrap card-container green-container mt-3 mb-4"
            [style]="{ 'margin-top': '20px' }"
        >
            <div class="flex align-items-center justify-content-center">
                <button
                *ngIf="!this._vendorService.isViewOnly"
                    pButton
                    pRipple
                    type="submit"
                    [label]="whatAction"
                    (click)="saveFn()"
                    [disabled]="
                        !editform.valid || this._vendorService.isViewOnly
                    "
                    [style]="{'width':'80px'}"
                ></button>
            </div>
            <div class="flex align-items-center justify-content-center ml-2">
                <button
                    pButton
                    pRipple
                    type="button"
                    label="Cancel"
                    class="p-button-outlined p-button-danger"
                    (click)="cancelButtonClick()"
                    [style]="{'width':'80px'}"
                ></button>
            </div>
        </div>
    </div>
</div>
