import { CurrencyPipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
    Component,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { AdditionItemsPriceInfo } from 'src/app/_models/subscription-management/addition-items-price-info';
import { BillingSummaryInfo } from 'src/app/_models/subscription-management/billing-summary-info';
import { CurrentPlanGeneralInformation } from 'src/app/_models/subscription-management/current-plan-general-info';
import {
    PlanInformation,
    PlanInformationList,
} from 'src/app/_models/subscription-management/plan-information';
import {
    BusinessSetupModel,
    SubscriptionModel,
} from 'src/app/_models/subscription-management/subscription-model';
import {
    PlanInfo,
    UserSubscriptionDetail,
    UserSubscriptionInfo,
} from 'src/app/_models/subscription-management/user-subscription-info';
import { GlobalService } from 'src/app/_services/global.service';
import { SubscriptionPlanService } from 'src/app/_services/subscription-plan.service';
import { BizzCustomersService } from 'src/app/_business-dashboard/_services/bizz-customers.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { WhiteLabelList } from 'src/app/_data/white-label-list';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/_services/base.service';
import { SignupService } from 'src/app/_services/signup.service';
import { UserService } from 'src/app/_services/user.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit, OnChanges {
   _global = GlobalService
    stateOptions = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ];
    invoiceDetail: any = [];
    productsDetail: any = [];
    showBuyMoreLocationForm: boolean = false;
    showBuyMoreRegisterForm: boolean = false;
    showSupportRequestForm: boolean = false;
    showPurchaseAddon: boolean = false;
    talkToExpertForm: boolean = false;
    showWebsiteSelectionPopup: boolean = false;
    showLoyaltyProgramSelectionPopup: boolean = false;
    @HostListener('window:resize', ['$event'])
    planName: string = '30-Day Free Trial';
    planAmount: string = '$30.00';
    displaySubscriptionPlan = false;
    messageText: string =
        `You are currently enjoying our ${this._global.whiteLabel.wlName} 30-day free trial. You may continue to explore our software within this time period or you can upgrade to one of our subscription plans below. Note: All uploaded data (Products, Customers, Vendors, etc.) will be deleted from our system after 30 days. `;

    currentPlanGeneralInformation: CurrentPlanGeneralInformation[] = [];
    billingPeriod: any[];
    selectedBillingPeriod: string = 'Monthly';
    planInformation = PlanInformationList;
    listSubscriptions = [];
    featuresArray: string[] = [];
    planDetails: any[] = [];
    _globals = GlobalService;
    currentPlanSubscription = <UserSubscriptionDetail>{};
    additionItemsPriceInfoHeader: string[] = [];
    additionItemsPriceInfo: AdditionItemsPriceInfo[] = [];
    billingSummaryInfo: BillingSummaryInfo[] = [];
    userCurrentPlan = <PlanInfo>{};
    loadingContent: boolean = false;
    totalLocation: number = 1;
    totalRegisters: number = 1;
    subscriptionCost: number = 79.99;
    additionalLocationCost: number = 0;
    additionalRegisterCost: number = 0;
    grandBill: number = 0;

    additionalRegisterPerLocationPrice: number = 0;
    additionalLocationNotAvailable: boolean = false;
    @Input() planInfo = <SubscriptionModel>{};
    @Input() businessSetup = <BusinessSetupModel>{};
    costHeading: string = '';

    showSubscriptionModal: boolean = false;
    directPurchase: boolean = false;
    additionalLocationString: string = '';
    addtionalAddonsQuestion: string = '';
    additionalAddonRequestionMessage: string = '';
    generalQuestion: string = '';
    generalMessage: string = '';
    additionalLocationPrice: number = 0;

    additionalRegisterString: string = '';
    additionalRegisterPrice: number = 0;

    disabledTrue: boolean = false;
    planPricePerMonth: number = 0;
    loyaltyProgramPriceString: string = '$0';
    taxRate: number = 0;
    loyaltyProgramPrice: number = 0;
    basePlanPrice: number = 0;
    subscribedPackages: any[] = [];
    currentLocations = <any>{};
    currentRegisters = <any>{};
    currentSubscription = <any>{};
    getSubPlan: any;
    staticWebsite = <any>{};
    buyLocQty = 0;
    buyRegQty = 0;

    planDuration: number = 1;
    cancellationRequest: boolean = false;
    upgradeRequested: boolean = false;
    whiteLabelList = WhiteLabelList;
    who: any = null;
    currentPlanID: number = 0;
    changedPlanID: number = 0;

    selectedPlanForSubscription = <any>{};
    planChanged: boolean = false;

    buylocationList = <any>{};
    buyRegisterList = <any>{};
    ShowCustomConfirmation = false;
    confirmationText: any;
    CurrentAction = 0;
    constructor(
        private _subscriptionPlanService: SubscriptionPlanService,
        private _bs: BaseService,
        private router: Router,
        private route: ActivatedRoute,
        private _spinner: NgxSpinnerService,
        public _bizzCustomerService: BizzCustomersService,
        private _confirmationService: ConfirmationService,
        private _abd: AppBaseDataService,
        public _signup: SignupService,
        private _userService: UserService,
        private _router: Router,
        private _messageService: MessageService,
        private _connMessageService: ConnMessageService
    ) {}

    ngOnInit(): void {
        try {
            this.getSubscriptionPlansUpdated();
            this.getEcomSubscriptionPlans();
            let host = window.location.host.toLowerCase();
            this.who = this.whiteLabelList.find((f) =>
                host.includes(f.wlName.toLowerCase())
            );
            if (!this.who) this.who = WhiteLabelList[0]; // --to be removed later
            this.who.name2parts = this.who.wlName.split('-');

            this.getUserCurrentSubscriptionDetails();

            let item = this._bizzCustomerService.activeItem.invoice;
            this.buylocationList.additionalCost = 0.0;
            this.buylocationList.newSubscriptionCharges = 0.0;
            this.buylocationList.note = '';
            this.buylocationList.qty = 0;
            this.buyRegisterList.additionalCost = 0.0;
            this.buyRegisterList.newSubscriptionCharges = 0.0;
            this.buyRegisterList.note = '';
            this.buyRegisterList.qty = 0;

            let planPricePerMonth =
                this._bizzCustomerService.activeItem.subscriptionInvoice
                    .plans[0].prices[1].value;
            if (
                this._bizzCustomerService.activeItem.subscriptionInterval == 12
            ) {
                planPricePerMonth =
                    this._bizzCustomerService.activeItem.subscriptionInvoice
                        .plans[0].prices[0].value;
            }
            let costHeading =
                '$' + planPricePerMonth + '/per month billed monthly';

            let selectedPlanInterval =
                this._bizzCustomerService.activeItem.subscriptionInterval;
            if (selectedPlanInterval == 12) {
                costHeading =
                    '$' +
                    planPricePerMonth +
                    '/per month X ' +
                    selectedPlanInterval +
                    ' = $' +
                    planPricePerMonth * selectedPlanInterval +
                    ' billed annually';
            }

            if (this._bizzCustomerService.activeItem.isFreeTrial) {
                costHeading = '$0.00';
            }
            this.invoiceDetail.push({
                label: 'Subscription Plan',
                value: this.planName,
            });
            this.invoiceDetail.push({ label: 'Status', value: item.planName });
            this.invoiceDetail.push({
                label: 'Payment Interval',
                value: item.planName,
            });
            // this.invoiceDetail.push({ label: 'Total Charges', value: item.invoiceNo })
            // this.invoiceDetail.push({ label: 'Base Plan Price', value: costHeading })
            // this.invoiceDetail.push({ label: 'Purchased Locations', value: this._bizzCustomerService.activeItem.noOfLocations })
            this.invoiceDetail.push({
                label: 'Total Charges',
                value: item.nextChargeAmount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }),
            });
            this.invoiceDetail.push({
                label: 'Renew Date',
                value: new Date(item.nextChargeDate),
            });
            this.invoiceDetail.push({
                label: 'Last Charge',
                value: new Date(item.nextChargeDate),
            });

            item = this._bizzCustomerService.activeItem.invoice.packageDetails;
            for (let i = 0; i < item.length; i++) {
                let additionalCost = '';
                if (selectedPlanInterval == 12 && (i == 1 || i == 2)) {
                    additionalCost =
                        item[i].pricePerPackage.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }) +
                        ' X ' +
                        item[i].quantity +
                        ' X 12 = ';
                }
                let includedAddon = '';
                if (item[i].addonsIncluded) {
                    includedAddon = ' (addon included)';
                }

                this.productsDetail.push({
                    srNo: i + 1,
                    description: item[i].title + includedAddon,
                    price: item[i].pricePerPackage.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }),
                    qty: i == 0 ? 1 : item[i].quantity,
                    subTotal:
                        additionalCost +
                        item[i].subTotalCost.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }),
                });
            }
        } catch (error) {}
    }

    ngOnChanges() {
        if (this._signup.showPlanSelectionModal === true) {
            setTimeout(() => {
                this.changePlanButtonClicked();
            }, 2000);
        }
    }

    performAction() {
        this.ErrorMessage = '';
        //upgrade subscription
        if (this.CurrentAction == 1) {
            this.upgradeSubPlan();
        } else if (this.CurrentAction == 2) {
            this.downgradeSubPlan();
        }
    }

    getSubscriptionPlansUpdated() {
        try {
            this._spinner.show();
            this._signup.getSubscriptionPlans().subscribe((res) => {
                if (res.success) {
                    this._spinner.hide();

                    this._signup.subscriptionPlans = res.data;
                    this.currentPlanID =
                        this._bizzCustomerService.activeItem.subscriptionInvoice?.plans[0]?.planId;
                }
            });
        } catch {
            this._spinner.hide();
        }
    }

    initSubscriptionFormInfo() {
        try {
            let item = this._bizzCustomerService.activeItem.invoice;
            this.taxRate =
                this._bizzCustomerService.activeItem.subscriptionInvoice.taxRate;
            this.taxRate =
                this._bizzCustomerService.activeItem.subscriptionInvoice.taxRate;

            this.cancellationRequest =
                this._bizzCustomerService.activeItem.cancellationRequest;
            this.upgradeRequested =
                this._bizzCustomerService.activeItem.upgradeRequested;
            this.planName =
                this._bizzCustomerService.activeItem.currentSubscription.planName;
            if (this._bizzCustomerService.activeItem.isFreeTrial) {
                let startDate: Date = new Date(
                    this._bizzCustomerService.activeItem.freeTrialStartDate.toString()
                );
                let endDate: Date = new Date(
                    this._bizzCustomerService.activeItem.freeTrialEndDate.toString()
                );

                // let diff = Math.abs(endDate.getTime() - startDate.getTime());
                //   this.dayLeftInRenewal = Math.ceil(diff / (1000 * 3600 * 24));

                // this.planAmount =
                //  this.currentPlanSubscription.subscriptionInvoice.totalPlanCost.toString();
                this.currentPlanGeneralInformation = [
                    {
                        title: 'Expires on:',
                        value: formatDate(endDate, 'MM/dd/yyyy', 'en-US'),
                    },
                ];
            } else {


                let details = this._bizzCustomerService.activeItem.invoice.packageDetails
                let selectedPlanInterval = this._bizzCustomerService.activeItem.subscriptionInterval

                this.subscribedPackages = this._bizzCustomerService.activeItem.currentSubscription.subscribedPackages
                this.currentLocations = this._bizzCustomerService.activeItem.currentSubscription.subscribedPackages.find(f => f.title == "Allowed Locations")
                this.currentRegisters = this._bizzCustomerService.activeItem.currentSubscription.subscribedPackages.find(f => f.title == "Allowed Registers")
                this.currentSubscription = this._bizzCustomerService.activeItem.currentSubscription
                this.getSubPlan = this._bizzCustomerService.activeItem.subscriptionInvoice
                // console.log(this.getSubPlan)
                this.staticWebsite = this._bizzCustomerService.activeItem.subscriptionInvoice.plans[0].featureData.find(f => f.typeString == "StaticWebsite")


            }




            this.calculateGrandTotal();
        } catch (error) {}
    }

    changeSubscriptionPeriod(e: any) {
        this.selectedBillingPeriod = e.value;

        this.calculateGrandTotal();
    }

    choseDifferentPlan(obj: any) {
        this.planDetails.map((f) => (f.currentUserSelectedPlan = false));
        obj.currentUserSelectedPlan = true;

        this.calculateGrandTotal();
    }

    getUserCurrentSubscriptionDetails() {
        this.loadingContent = true;
        this._subscriptionPlanService.getBusinessInvoice().subscribe(
            (res: UserSubscriptionInfo) => {
                if (res.responseStatusCode == 903) {
                    this.currentPlanSubscription = res.data;
                    this._bizzCustomerService.activeItem = res.data;
                    this.currentPlanID =
                        this._bizzCustomerService.activeItem.subscriptionInvoice.plans[0].planId;

                    this.planPricePerMonth =
                        this._bizzCustomerService.activeItem.subscriptionInvoice.plans[0].prices[1].value;
                    if (
                        this._bizzCustomerService.activeItem
                            .subscriptionInterval == 12
                    ) {
                        this.planPricePerMonth =
                            this._bizzCustomerService.activeItem.subscriptionInvoice.plans[0].prices[0].value;
                    }

                    let selectedPlanInterval =
                        this._bizzCustomerService.activeItem
                            .subscriptionInterval;
                    if (selectedPlanInterval == 12) {
                        this.basePlanPrice =
                            this.planPricePerMonth * selectedPlanInterval;
                        this.costHeading =
                            '$' +
                            this.planPricePerMonth +
                            '/per month X ' +
                            selectedPlanInterval +
                            ' = $' +
                            this.planPricePerMonth * selectedPlanInterval +
                            ' billed annually';
                    } else {
                        this.basePlanPrice = this.planPricePerMonth;
                        this.costHeading =
                            '$' +
                            this.planPricePerMonth +
                            '/per month billed monthly';
                    }

                    this.userCurrentPlan = this._bizzCustomerService.activeItem;

                    this.loadingContent = false;
                    this.initSubscriptionFormInfo();
                    this.route.queryParams.subscribe((params) => {
                        if (params['upgrade'] === 'true') {
                            // Do something when the 'upgrade' query parameter is 'true'
                            this.upgradePlan();
                        }
                    });
                }
            },
            (error) => {
                this.loadingContent = false;
            }
        );
    }

    updateQuantity(input: any, obj: AdditionItemsPriceInfo) {
        if (input.value != null && parseInt(input.value) > 0) {
            if (obj.id == 1) {
                if (this.totalLocation > parseInt(input.value)) {
                    this.additionItemsPriceInfo[1].quantity =
                        this.additionItemsPriceInfo[1].quantity - 1;
                } else {
                    this.additionItemsPriceInfo[1].quantity =
                        this.additionItemsPriceInfo[1].quantity + 1;
                }

                obj.includeQuantity = input.value;
                this.totalLocation = input.value;
                //this.additionItemsPriceInfo[1].includeQuantity += 1;
                obj.totalCost = obj.actualPrice * obj.includeQuantity;

                if (obj.includeQuantity <= 0) {
                    this.additionItemsPriceInfo[1].disabled = true;
                    this.totalRegisters = 0;
                    this.additionItemsPriceInfo[1].quantity = 0;
                    this.additionItemsPriceInfo[1].includeQuantity = 0;
                } else {
                    this.additionItemsPriceInfo[1].disabled = false;
                }
            } else {
                if (
                    parseInt(input.value) <
                    this.additionItemsPriceInfo[0].quantity
                ) {
                    setTimeout(() => {
                        input.value = this.additionItemsPriceInfo[0].quantity;
                        this.additionItemsPriceInfo[1].quantity =
                            this.additionItemsPriceInfo[0].quantity;
                    }, 200);
                    return false;
                    // this.additionItemsPriceInfo[1].disabled = false;
                }
                if (this.totalRegisters > parseInt(input.value)) {
                    // obj.quantity = obj.quantity - 1;
                    obj.includeQuantity = obj.includeQuantity - 1;
                } else {
                    obj.includeQuantity = obj.includeQuantity + 1;
                }

                this.totalRegisters = obj.quantity;
                obj.totalCost = obj.actualPrice * obj.includeQuantity;

                // this.additionItemsPriceInfo[1].disabled = true;
            }
            this.calculateGrandTotal();
        } else {
            setTimeout(() => {
                input.value = 1;
                obj.quantity = 1;
                obj.actualPrice = 1;
                obj.totalCost = obj.actualPrice * 1;
                this.calculateGrandTotal();
            }, 200);
        }
    }

    calculateGrandTotal() {
        if (this.additionalLocationNotAvailable) {
            this.additionItemsPriceInfo[0].quantity = 0;
            this.additionItemsPriceInfo[1].quantity = 0;
            this.additionItemsPriceInfo[0].includeQuantity = 0;
            this.additionItemsPriceInfo[1].includeQuantity = 0;
        } else {
            this.additionItemsPriceInfo[0].quantity = 1;
            this.additionItemsPriceInfo[1].quantity = 1;
            this.additionItemsPriceInfo[0].includeQuantity = 1;
            this.additionItemsPriceInfo[1].includeQuantity = 1;
        }
        this.additionalLocationCost = this.additionItemsPriceInfo[0].totalCost;
        this.additionalRegisterCost = this.additionItemsPriceInfo[1].totalCost;
        this.grandBill =
            this.additionalLocationCost +
            this.additionalRegisterCost +
            this.subscriptionCost;
    }

    resetQuantity(obj: AdditionItemsPriceInfo) {
        // console.log(typeof obj.quantity);
    }

    setValueInputsOldValue(oldValue: any, obj: AdditionItemsPriceInfo) {}
    getWordsWithSpace(str) {
        return str.replace(/[A-Z]/g, ' $&').trim();
    }

    onDesktopScreen() {
        return window.innerWidth > 991;
    }
    onMobileScreen() {
        return window.innerWidth < 992;
    }

    cancel() {
        this._confirmationService.confirm({
            header: `Subscription Cancel`,
            message: 'Are you sure you want to cancel your subscription? You will not be able to undo this without contacting support.',
            accept: () => {
                try {
                    this._abd.showSpinner();
                    this._subscriptionPlanService
                        .cancelSubscriptionByCustomer()
                        .subscribe((res) => {
                            if (res.success) {
                                this.showSubscriptionModal = true;
                                this.disabledTrue = true;
                                this._spinner.hide();
                                this._userService.logout();
                            }
                        });
                } catch {
                    this._abd.hideSpinner();
                }
            },
        });
    }
    buyLocationFormHandler() {
        this.showBuyMoreLocationForm = true;
    }
    generateSupportRequest(subject = '') {
        this.generalQuestion = subject;
        this.showSupportRequestForm = true;
        this.showPurchaseAddon = false;
        this.addtionalAddonsQuestion = this.generalMessage = '';
    }
    buyRegisterFormHandler() {
        this.showBuyMoreRegisterForm = true;
    }
    purchaseAddonHandler() {
        this.showPurchaseAddon = true;
    }
    talkToExpertRequestForm() {
        this.talkToExpertForm = true;
        this.showPurchaseAddon = false;
    }
    showPopuptoSelectWebsite() {
        this.showWebsiteSelectionPopup = true;
    }
    showLoyaltyFormPopup() {
        this.showLoyaltyProgramSelectionPopup = true;
    }
    submitTalkExpertFormRequest() {
        const obj = {
            // buyNoOfLocations: this.buyLocQty,
            userEmail: this._bizzCustomerService.activeItem.email,
            companyName: this._globals.userInfo.companyName,
            question: this.addtionalAddonsQuestion,
            message: this.additionalAddonRequestionMessage,
        };
        this.talkToExpertForm = false;
        const _url = environment.BaseURL + 'EmailForAddonsPlan';
        // const _url= 'https://connect360-stg.azure-api.net/account-svc/api/BusinessSubscription?operation=Update&updateItem=Locations&execute=true'

        this._confirmationService.confirm({
            header: `Additonal Addons Message Confirmation`,
            message: `Are you sure you want to send this message to our expert person of our team?.`,
            accept: () => {
                try {
                    this.talkToExpertForm = false;
                    this._abd.showSpinner();
                    this._bs.httpPost(_url, obj).subscribe((res) => {
                        this._spinner.hide();
                        if (res.success) {
                            this._connMessageService.showToastMessage(
                                'Thank you! Your request is sent to our support team, someone from our team will contact you as soon as possible.',
                                'success'
                            );
                            this.addtionalAddonsQuestion = '';
                            this.additionalAddonRequestionMessage = '';
                        }
                    });
                } catch {
                    this.talkToExpertForm = false;
                    this._abd.hideSpinner();
                }
            },
            reject: (type) => {
                this.talkToExpertForm = true;
                // verifyObj.isVerified = !e.checked;
            },
        });
    }
    submitGeneralRequest() {
        const obj = {
            userEmail: this._bizzCustomerService.activeItem.email,
            companyName: this._globals.userInfo.companyName,
            question: this.generalQuestion,
            message: this.generalMessage,
        };
        this.showSupportRequestForm = false;
        const _url = environment.BaseURL + 'EmailForAddonsPlan';

        this._confirmationService.confirm({
            header: `Support Request Confirmation`,
            message: `Are you sure you want to send this message to our business team?.`,
            accept: () => {
                try {
                    this.showSupportRequestForm = false;
                    this._abd.showSpinner();
                    this._bs.httpPost(_url, obj).subscribe((res) => {
                        this._spinner.hide();
                        if (res.success) {
                            this._connMessageService.showToastMessage(
                                'Thank you! Your request is sent to our support team, someone from our team will contact you as soon as possible.',
                                'success'
                            );
                            this.generalMessage = '';
                        }
                    });
                } catch {
                    this.showSupportRequestForm = false;
                    this._abd.hideSpinner();
                }
            },
            reject: (type) => {
                this.showSupportRequestForm = true;
            },
        });
    }
    submitHardwareCatalogRequest() {
        const obj = {
            userEmail: this._bizzCustomerService.activeItem.email,
            companyName: this._globals.userInfo.companyName,
            question: 'Request for Hardware Catalog',
            message: 'Contact me',
        };
        this.showPurchaseAddon = false;
        const _url = environment.BaseURL + 'EmailForAddonsPlan';
        this._confirmationService.confirm({
            header: `Additional Hardware Request Confirmation`,
            message: `Are you sure you want to request additional hardware catalog details from our business team?`,
            accept: () => {
                try {
                    this.showPurchaseAddon = false;
                    this._abd.showSpinner();
                    this._bs.httpPost(_url, obj).subscribe((res) => {
                        this._spinner.hide();
                        if (res.success) {
                            this._connMessageService.showToastMessage(
                                'Thank you! Your request is sent to our support team, someone from our team will contact you as soon as possible.',
                                'success'
                            );
                        }
                    });
                } catch {
                    this.showPurchaseAddon = false;
                    this._abd.hideSpinner();
                }
            },
            reject: (type) => {
                this.showPurchaseAddon = true;
            },
        });
    }
    locationQtyChanged(e = null) {
        const obj = {
            buyNoOfLocations: this.buyLocQty,
        };
        const _url =
            environment.BusinessBaseURL +
            'BusinessSubscription?operation=Update&updateItem=Locations&execute=false';
        this._spinner.show();
        this._bs.httpPut(_url, obj).subscribe((res) => {
            if (res.success) {
                this.buylocationList = res.data;

                this._spinner.hide();
            }
        });
    }
    registerQtyChanged(e) {
        const obj = {
            buyNoOfRegisters: this.buyRegQty,
        };
        const _url =
            environment.BusinessBaseURL +
            'BusinessSubscription?operation=Update&updateItem=Registers&execute=false';
        this._spinner.show();
        this._bs.httpPut(_url, obj).subscribe((res) => {
            if (res.success) {
                this.buyRegisterList = res.data;
                this._spinner.hide();
            }
        });
    }
    postBuyLocation() {
        const obj = {
            buyNoOfLocations: this.buyLocQty,
        };
        this.showBuyMoreLocationForm = false;
        const _url =
            environment.BusinessBaseURL +
            'BusinessSubscription?operation=Update&updateItem=Locations&execute=true';

        this._confirmationService.confirm({
            header: `Buy Location Confirmation`,
            message: `Are you sure you want to buy additional Location?.`,
            accept: () => {
                try {
                    this.showBuyMoreLocationForm = false;
                    this._abd.showSpinner();
                    this._bs.httpPut(_url, obj).subscribe((res) => {
                        this._spinner.hide();
                        if (res.success) {
                            this.getUserCurrentSubscriptionDetails();
                            this._connMessageService.showToastMessage(
                                'You have successfully purchased additional location.',
                                'success'
                            );
                        }
                    });
                } catch {
                    this.showBuyMoreLocationForm = false;
                    this._abd.hideSpinner();
                }
            },
            reject: (type) => {
                this.showBuyMoreLocationForm = true;
                // verifyObj.isVerified = !e.checked;
            },
        });
    }
    postBuyRegisters() {
        const obj = {
            BuyNoOfRegisters: this.buyRegQty,
        };
        this.showBuyMoreRegisterForm = false;
        const _url =
            environment.BusinessBaseURL +
            'BusinessSubscription?operation=Update&updateItem=Registers&execute=true';
        this._confirmationService.confirm({
            header: `Buy Additional Register Confirmation`,
            message: `Are you sure you want to buy additional Register?.`,
            accept: () => {
                try {
                    this.showBuyMoreRegisterForm = false;
                    this._abd.showSpinner();
                    this._bs.httpPut(_url, obj).subscribe((res) => {
                        this._spinner.hide();
                        if (res.success) {
                            this.getUserCurrentSubscriptionDetails();
                            this._connMessageService.showToastMessage(
                                'You have successfully purchased additional register.',
                                'success'
                            );
                        }
                    });
                } catch {
                    this.showBuyMoreRegisterForm = false;
                    this._abd.hideSpinner();
                }
            },
            reject: (type) => {
                this.showBuyMoreRegisterForm = true;
            },
        });
    }
    upgradePlan(execute = false) {
        this.ErrorMessage = '';
        if (!execute) this.changePlanButtonClicked();
        else {
            //show a popup here see slide number 9
            this.CurrentAction = 1;
            this.ShowCustomConfirmation = true;
            this.upgradeSubPlan('false');
        }
    }
    downgradePlan(execute = false) {
        this.ErrorMessage = '';
        if (!execute) this.changePlanButtonClicked();
        else {
            //show a popup here see slide number 9
            this.CurrentAction = 2;
            this.ShowCustomConfirmation = true;
            this.downgradeSubPlan('false');
        }
    }

    ErrorMessage = '';
    currentSubscriptionCharges = 0;
    newSubscriptionCharges = 0;
    subscriptionChangeNotes = '';
    upgradeSucessMessage = '';
    upgradeSubPlan(execute = 'true') {
        let Sucess = false;
        try {
            this.ErrorMessage = '';
            this.upgradeSucessMessage = '';
            this._abd.showSpinner();
            let newplan = {
                BuySubscriptionPlanId: this.selectedPlanForSubscription.planId,
            };
            this._subscriptionPlanService
                .upgradeSubscription(newplan, true, execute)
                .subscribe((res) => {
                    if (res.success) {
                        Sucess = true;
                        this.subscriptionChangeNotes = res.data.note;
                        this.currentSubscriptionCharges =
                            res.data.currentSubscriptionCharges;
                        this.newSubscriptionCharges =
                            res.data.newSubscriptionCharges;
                        if (execute == 'true') {
                            this.upgradeSucessMessage =
                                'You have sucessfully updated your plan.';
                            this.getSubscriptionPlansUpdated();
                            this.getEcomSubscriptionPlans();
                            this.getUserCurrentSubscriptionDetails();
                            
                        }
                    } else {
                        this.ErrorMessage =
                            'Plan change failed, Please try again or contact support!';
                    }
                });
        } catch {}
    }

    getEcomSubscriptionPlans() {
        this._signup.getEComSubscriptionPlans().subscribe((res) => {
            if (res.success) {
                this._signup.eComSubscriptionPlans = res.data;

                let obj = this._signup.eComSubscriptionPlans;
                for (let i = 0; i < obj.length; i++) {
                    obj[i].selected = false;
                    obj[i].wantDomain = 0;
                }
                // console.log('this._signup.eComSubscriptionPlans', this._signup.eComSubscriptionPlans)
            }
        });
    }
    downgradeSubPlan(execute = 'true') {
        let Sucess = false;
        this.upgradeSucessMessage = '';
        try {
            this.ErrorMessage = '';
            this._abd.showSpinner();
            let newplan = {
                BuySubscriptionPlanId: this.selectedPlanForSubscription.planId,
            };
            this._subscriptionPlanService
                .upgradeSubscription(newplan, false, execute)
                .subscribe((res) => {
                    if (res.success) {
                        Sucess = true;
                        this.subscriptionChangeNotes = res.data.note;
                        this.currentSubscriptionCharges =
                            res.data.currentSubscriptionCharges;
                        this.newSubscriptionCharges =
                            res.data.newSubscriptionCharges;
                       
                            if (execute == 'true') {
                            this.upgradeSucessMessage =
                                'You have sucessfully updated your plan.';
                                this.getSubscriptionPlansUpdated();
                                this.getEcomSubscriptionPlans();
                                this.getUserCurrentSubscriptionDetails();
                        }
                    } else {
                        this.ErrorMessage =
                            'An error occured during the operation, please try again.';
                    }
                });
        } catch {
        } finally {
        }
    }

    cancelSubscription(actionType: string) {
        try {
            if (actionType == 'c') {
                this.cancel();
            }
            if (actionType == 'r') {
                this.upgradePlan();
            }
        } catch (error) {}
    }
    changePlanButtonClicked() {
        this._signup.subscriptionPlans
            .filter((f) => f.isTrial || f.isCustom)
            .map((f) => (f.showPlan = false));
        this._signup.subscriptionPlans
            .filter((f) => !f.isTrial && !f.isCustom)
            .map((f) => (f.showPlan = true));

        this.selectedPlanForSubscription = this._signup.subscriptionPlans.find(
            (f) =>
                f.planId ==
                this._bizzCustomerService.activeItem.currentSubscription.planId
        );
        this._signup.currentSubscriptionPlan =
            this.selectedPlanForSubscription.title;
        //this.selectedPlanForSubscription = this._bizzCustomerService.activeItem.subscriptionInvoice.plans[0]

        this.displaySubscriptionPlan = true;

        sessionStorage.setItem(
            'currentPlan',
            JSON.stringify(this.selectedPlanForSubscription)
        );
        let unverifiedAccount: any = JSON.parse(sessionStorage.getItem('selectedPlan'));
        if (unverifiedAccount == undefined) {
            this._signup.selectedPlan = this.selectedPlanForSubscription;
            sessionStorage.setItem(
                'selectedPlan',
                JSON.stringify(this.selectedPlanForSubscription)
            );
        }

        let subscriptionAgain: any =
            sessionStorage.getItem('sub-from-dashboard');

        if (subscriptionAgain != undefined) {
            this.planChanged = true;
        }
        if (this._signup.showPlanSelectionModal !== true) {
            this._signup.selectedPlan = this._signup.subscriptionPlans.find(
                (f) =>
                    f.planId ==
                    this._bizzCustomerService.activeItem.currentSubscription
                        .planId
            );
        }
    }

    closeDialogSubscriptionPlan(selectedPlan: any) {
        try {
            this.selectedPlanForSubscription = selectedPlan;
        } catch (error) {}
    }

    closeSubscriptionModal(buttonType: number) {
        try {
            // this.displaySubscriptionPlan = false;
            if (this._bizzCustomerService.activeItem.isFreeTrial) {
                if (buttonType == 2) {
                    this.displaySubscriptionPlan = false;
                    let alreadySelectedPlan = JSON.parse(
                        sessionStorage.getItem('selectedPlan')
                    );
                    if (
                        this.selectedPlanForSubscription.planId === '1' &&
                        alreadySelectedPlan !== null
                    ) {
                        this.selectedPlanForSubscription = alreadySelectedPlan;
                    }
                    sessionStorage.setItem(
                        'selectedPlan',
                        JSON.stringify(this.selectedPlanForSubscription)
                    );

                    sessionStorage.setItem('show-selected-plan', 'false');
                    sessionStorage.setItem('free-trial-account', '0');
                    sessionStorage.setItem('un-verified-account', '1');
                    sessionStorage.setItem('currentStep', '4');
                    sessionStorage.setItem('savedSteps', '4');
                    sessionStorage.setItem('accountVerified', '1');
                    sessionStorage.setItem('sub-from-dashboard', '1');
                    sessionStorage.setItem(
                        'locationsQty',
                        this._signup.checkoutPageCalcVars.locationsQty
                    );
                    sessionStorage.setItem(
                        'registersQty',
                        this._signup.checkoutPageCalcVars.registersQty
                    );
                    sessionStorage.setItem(
                        'selectedPlanTimeframe',
                        this._signup.selectedPlanTimeframe
                    );

                    this._signup.selectedPlan =
                        this.selectedPlanForSubscription;
                    this._signup.customerBillTo = {
                        firstName: this._globals.userInfo.firstName,
                        lastName: this._globals.userInfo.lastName,

                        email: this._bizzCustomerService.activeItem.email,
                        company: this._globals.userInfo.companyName,
                        address:
                            this._globals.userInfo.businessSetup[0].address,
                        city: this._globals.userInfo.businessSetup[0].city,
                        state: this._globals.userInfo.businessSetup[0].state,
                        zip: this._globals.userInfo.businessSetup[0].zip,
                        country: 'USA',
                    };

                    this._signup.businessInfo = {
                        businessId:
                            this._globals.userInfo.businessSetup[0].businessId,
                        userId: this._globals.userInfo.businessSetup[0].userID,
                        companyName: this._globals.userInfo.companyName,
                        industryTypeId:
                            this._globals.userInfo.businessSetup[0].industry,
                        phone: this._globals.userInfo.businessSetup[0].phone,
                        email: this._bizzCustomerService.activeItem.email,
                        address:
                            this._globals.userInfo.businessSetup[0].address,
                        zip: this._globals.userInfo.businessSetup[0].zip,
                        city: this._globals.userInfo.businessSetup[0].city,
                        state: this._globals.userInfo.businessSetup[0].state,
                        selectedState: null,
                        userID: this._globals.userInfo.businessSetup[0].userID,
                    };
                    sessionStorage.setItem(
                        'step-2',
                        JSON.stringify(this._signup.businessInfo)
                    );

                    this.router.navigate(['/setup/signup']);
                } else {
                    this._confirmationService.confirm({
                        message:
                            'All changes will be lost. Do you wish to continue?',
                        header: 'Confirmation',
                        icon: 'pi pi-info-circle',
                        accept: () => {
                            try {
                                this.displaySubscriptionPlan = false;
                                let alreadySelectedPlan = JSON.parse(
                                    sessionStorage.getItem('selectedPlan')
                                );
                                if (alreadySelectedPlan !== null) {
                                    this.selectedPlanForSubscription =
                                        alreadySelectedPlan;
                                    this._signup.selectedPlan =
                                        alreadySelectedPlan;
                                }
                                this._signup.showPlanSelectionModal = false;
                            } catch (err) {
                                console.log(err);
                            }
                        },
                    });
                }
            }
            //user have a plan
            //check if plan is not same as customer already have
            else {
                if (buttonType == 2) {
                    this.displaySubscriptionPlan = false;
                    if (
                        this.selectedPlanForSubscription.planId >
                        this._bizzCustomerService.activeItem.currentSubscription
                            .planId
                    ) {
                        this.upgradePlan(true);
                    } else if (
                        this.selectedPlanForSubscription.planId <
                        this._bizzCustomerService.activeItem.currentSubscription
                            .planId
                    ) {
                        this.downgradePlan(true);
                    } else {
                    }
                } else {
                    this.displaySubscriptionPlan = false;
                }
            }
        } catch (error) {}

        if (buttonType == 2) {
            this._signup.showSelectedPlan = false;
        }
    }

    subscriptionPlanChanged(s: boolean) {
        try {
            this.planChanged = s;
        } catch (error) {}
    }
}
