<h3
    style="
        margin-top: 0px;
        font-size: 1.7rem;
        color: var(--bs-active-primary);
        font-weight: 700px;
    "
>
    Details
</h3>
<p-divider></p-divider>
<br />
<p-table
    styleClass="p-datatable-striped"
    [autoLayout]="true"
    [tableStyle]="{ width: 'max-content' }"
    responsiveLayout="scroll"
    [value]="hardwaredetails"
    rowHover="true"
>
    <ng-template pTemplate="header">
        <tr>
            <th>Hardware/Accessories</th>
            <!-- <th>Model</th> -->
            <!-- <th>Serial Number</th> -->
            <th style="text-align: center">Qty</th>
            <th style="text-align: center">Price</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td >{{getWordsWithSpace(item.title)}}</td>
            <!-- <td>{{item.model}}</td> -->
            <!-- <td>{{item.serialNo}}</td> -->
            <td style="text-align: center">{{item.qty}}</td>
            <td style="text-align: center">{{item.totalPrice | currency}}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" style="text-align: center">
        <tr>
            <td colspan="17" style="text-align: center">
                No data found
            </td>
        </tr>
    </ng-template>
</p-table>
