<div class="conn-grid">    <form [formGroup]="editform" autocomplete="off">        <div>            <div>                <label class="control-label" style="margin-right: 10px"
    ><strong
        >Product Name
        <span style="color: red; font-size: 1.25rem">*</span>                    </strong></label
>                <br />                <app-text-input
    formControlName="productName"
    [label]=""
    placeholder="Product name"
    [name]="'Product Name'"
    (input)="
        _productServices.pts.t_productName = $event.target.value
    "
    [description]="
        'A product name is required and recommended to be unique'
    "
></app-text-input>            </div>        </div>        <!-- <div style="margin-top: 20px">            <div class="form-group" *ngIf="_platform.ANDROID">                <label class="control-label" style="margin-right: 10px"><strong>Description</strong></label>                <br />                <textarea [ngModelOptions]="{ standalone: true }" style="width: 100%; margin-top: 10px; max-height: 35vh;" 
    [(ngModel)]="_productServices.productDescription" [rows]="5" pInputTextarea
    autoResize="autoResize"></textarea>                <span class="control-description text-muted"> Set a description to the product for better
    visibility.</span>            </div>        </div> -->        <div style="margin-top: 20px">            <div class="form-group">                <label class="control-label" style="margin-right: 10px"
    ><strong>Description</strong></label
>                <br />                <textarea
    [(ngModel)]="_productServices.productDescription"
    rows="5"
    class="tty"
    [ngModelOptions]="{standalone: true}"
></textarea>                <div class="control-description text-muted">                    Set a description to the product for better visibility.
</div>            </div>        </div>    </form></div><style>    .tty {
width: 100%;
color: #5e6278;
background-color: white;
border: 1px solid #e4e6ef;
box-shadow: none !important;
font-size: var(--connect-primary-font-size);
font-weight: 600;
border-radius: 4px;
margin-top: 7px;
}
.tty:focus-within {
outline: none !important;
width: 100%;
color: #5e6278;
background-color: white;
border: 1px solid #e4e6ef;
box-shadow: none !important;
font-size: var(--connect-primary-font-size);
font-weight: 600;
border-radius: 4px;
}
</style>