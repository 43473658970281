<!-- <div class="conn-grid-2x"> -->
    <div>
        <div>
            <div>
                <!-- <label class="control-label"
                    ><strong
                        >Invoice Configuration
                        <span style="color: red; font-size: 1.25rem">*</span>
                    </strong></label
                > -->
                <br />
                <form
                    [formGroup]="tenderform"
                    (ngSubmit)="tenderform.valid && saveFn()"
                    autocomplete="off"
                >

                <div class="mb-4 hidden">
                    <p-inputSwitch [formControl]="$any(tenderform.controls)['enableTenderForm']" inputId="rememberme20"></p-inputSwitch>
                    <label class="control-label" for="rememberme20"><strong> Enable</strong></label>
                </div>
                <br/>

                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(tenderform.controls)['enableTips']"
                        inputId="rememberme7"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme7">Enable Tip</label>
                </div>
                <br/>
                <div class="field-checkbox" *ngIf="f.enableTips?.value === true">
                    <p-checkbox
                        [formControl]="$any(tenderform.controls)['promptCustomerTip']"
                        inputId="rememberme8"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme8">Enable Prompt Customer For Tip</label>
                </div>
                <br *ngIf="f.enableTips?.value === true" />
                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(tenderform.controls)['allowCash']"
                        inputId="rememberme1"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme1">Allow cash transaction</label>
                </div>
                <br/>

                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(tenderform.controls)['allowDeditCard']"
                        inputId="rememberme2"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme2">Allow debit card transaction</label>
                </div>
                <br/>

                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(tenderform.controls)['allowCreditCard']"
                        inputId="rememberme3"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme3">Allow credit card transaction</label>
                </div>
                <br/>
                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(tenderform.controls)['allowZellePayment']"
                        inputId="rememberme4"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme4">Allow Zelle transaction</label>
                </div>
                <br/>
                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(tenderform.controls)['allowPaypalPayment']"
                        inputId="rememberme5"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme5">Allow Paypal transaction</label>
                </div>
                <br/>
                <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(tenderform.controls)['allowVenomoPayment']"
                        inputId="rememberme6"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme6">Allow Venmo transaction</label>
                </div>
                <br/>

                <!-- <div class="field-checkbox">
                    <p-checkbox
                        [formControl]="$any(tenderform.controls)['allowCheck']"
                        inputId="rememberme4"
                        [binary]="true"
                        styleClass="mr-2"
                    ></p-checkbox>
                    <label for="rememberme4">Allow check</label>
                </div> -->
                <br/>


                    <div
                        class="flex justify-content-end flex-wrap card-container green-container mt-3"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <button
                                pButton
                                pRipple
                                type="submit"
                                label="Save"
                                [disabled]="!tenderform.valid"
                            ></button>
                        </div>
                        <div
                            class="flex align-items-center justify-content-center ml-2"
                            *ngIf ="singupBusinessConfig == true"
                        >
                            <button
                                pButton
                                pRipple
                                type="button"
                                label="Cancel"
                                class="p-button-outlined p-button-danger"
                                (click)="cancelButtonClick()"
                            ></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
