<h5 style="color: var(--action-button)">Invoice Items</h5>
<br />
<p-table
    #dt
    [columns]=""
    [autoLayout]="true"
    [value]="_reportService.selectedInvoiceDetails.invoiceItems"
    responsiveLayout="scroll"
    showLoader="true"
    rowHover="true"
    [customSort]="true"
    [style]="{ 'min-width': '100%' }"
>
    <ng-template pTemplate="header">
        <tr>
            <th></th>
            <th>Item</th>
            <th>Transaction Type</th>
            <th>Price ($)</th>
            <th>Qty</th>
            <th style="text-align: right">Discount ($)</th>
            <th style="text-align: right">Total ($)</th>
            
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr [pSelectableRow]="item">
            <td>
                <div class="conn-flex-image">
                    <img
                        src="{{
                            item.image != '' && item.image != 'noproduct'
                                ? item.image
                                : '/assets/images/default_product.png'
                        }}"
                        alt=""
                        width="28px"
                        height="28px"
                        style="border-radius: 50px"
                    />
                </div>
            </td>
            <td>{{ item.itemName }} <i class="pi pi-info-circle" *ngIf="item.reasonNote" [pTooltip]="'Reason for Refund: '+item.reasonNote"></i>
          
            </td>
            <td>{{ item.invoiceTypeString }}</td>
            <td>{{ item.price | currency }}</td>
            <td>{{ item.qty | number }}</td>
            <td style="text-align: right">{{ item.discount | currency }}</td>
            <td style="text-align: right">{{ item.linePrice | currency }}</td>
            
        </tr>
    </ng-template>

    <ng-template pTemplate="footer">
        <tr>
            <td colspan="5" style="border: 0px"></td>
            <td style="text-align: left; color: var(--gray-600)">
                Total Items
            </td>
            <td style="text-align: right; color: var(--gray-600)">
                {{
                    _reportService.selectedInvoiceDetails?.invoiceItems?.length
                }}
            </td>
        </tr>

        <tr>
            <td colspan="5" style="border: 0px"></td>
            <td style="text-align: left; color: var(--gray-600)">
                Subtotal Total
            </td>
            <td style="text-align: right; color: var(--gray-600)">
                {{ _reportService.selectedInvoiceDetails?.subTotal | currency }}
            </td>
        </tr>

        <tr>
            <td colspan="5" style="border: 0px"></td>
            <td style="text-align: left; color: var(--gray-600)">CC fees</td>
            <td style="text-align: right; color: var(--gray-600)">
                {{
                    _reportService.selectedInvoiceDetails?.otherChargesAmount
                        | currency
                }}
            </td>
        </tr>

        <tr>
            <td colspan="5" style="border: 0px"></td>
            <td style="text-align: left; color: var(--gray-600)">Discount</td>
            <td style="text-align: right; color: var(--gray-600)">
                {{ _reportService.selectedInvoiceDetails?.discount | currency }}
            </td>
        </tr>

        <tr>
            <td colspan="5" style="border: 0px"></td>
            <td style="text-align: left; color: var(--gray-600)">Sales Tax</td>
            <td style="text-align: right; color: var(--gray-600)">
                {{ _reportService.selectedInvoiceDetails?.salesTax | currency }}
            </td>
        </tr>

        <tr>
            <td colspan="5" style="border: 0px"></td>
            <td style="text-align: left; color: var(--gray-600)">
                Shipping Cost
            </td>
            <td style="text-align: right; color: var(--gray-600)">
                {{
                    _reportService.selectedInvoiceDetails?.shippingCost
                        | currency
                }}
            </td>
        </tr>

        <tr>
            <td colspan="5" style="border: 0px"></td>
            <td style="text-align: left; color: var(--gray-600)">
                Shipping Tax
            </td>
            <td style="text-align: right; color: var(--gray-600)">
                {{
                    _reportService.selectedInvoiceDetails?.shippingTax
                        | currency
                }}
            </td>
        </tr>

        <tr style="font-weight: bolder; font-size: 17px">
            <td colspan="5" style="border: 0px"></td>
            <td style="text-align: left">Grand Total</td>
            <td style="text-align: right">
                {{
                    _reportService.selectedInvoiceDetails.grandTotal | currency
                }}
            </td>
        </tr>
        <tr>
            <td colspan="5" style="border: 0px"></td>
            <td style="text-align: left; color: var(--gray-600)">
               Tip
            </td>
            <td style="text-align: right; color: var(--gray-600)">
                {{
                    _reportService.selectedInvoiceDetails?.tipAmount
                        | currency
                }}
            </td>
        </tr>
        <tr style="font-weight: bolder; font-size: 17px">
            <td colspan="5" style="border: 0px"></td>
            <td style="text-align: left">Tendered</td>
            <td style="text-align: right">
                {{
                   _reportService.selectedInvoiceDetails.grandTotal + _reportService.selectedInvoiceDetails?.tipAmount | currency
                }}
            </td>
        </tr>
    </ng-template>
</p-table>
