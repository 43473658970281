<ng-container *ngIf="_barcodeLabelServices.productDetailPrintBarcodeLabel">
    <p-dialog
        header="Barcode Printing"
        [(visible)]="_barcodeLabelServices.productDetailPrintBarcodeLabel"
        [style]="{ width: '70%' }"
        modal="true"
    >
        <app-barcode-label-printing-form
        *ngIf="_barcodeLabelServices.productDetailPrintBarcodeLabel"
            [isNew]="true"
            [fromList]="false"
            [isProductLabel]="true"
        ></app-barcode-label-printing-form>
    </p-dialog>
</ng-container>
<div class="conn-grid-main create-product">
    <div>
        <p-tabMenu
            #tab
            [model]="items"
            [activeItem]="activeItem"
            (click)="onTabItemClick(tab)"
        >
            <ng-template pTemplate="item" let-item let-i="index">
                <div>
                    <span>
                        <i
                            style="color: var(--info-color)"
                            class="{{ item.icon }}"
                        ></i>
                    </span>
                    {{ item.label }}
                </div>
            </ng-template>
        </p-tabMenu>

        <div
            *ngIf="activeItem.label === 'General'"
            class="fadeinright animation-duration-200 mt-3"
        >
            <div class="conn-card">
                <h2 class="w-title card-title">General</h2>
                <app-product-details></app-product-details>
            </div>
            <br />
            <div class="conn-card">
                <h2 class="w-title card-title">Properties</h2>
                <app-product-general-properties></app-product-general-properties>
            </div>
            <br />
            <div class="conn-card">
                <h2 class="w-title card-title">Inventory</h2>
                <app-product-inventory></app-product-inventory>
            </div>
            <br />
            <div class="conn-card">
                <h2 class="w-title card-title">Pricing</h2>
                <app-product-pricing></app-product-pricing>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Stock Management'">
            <br />
            <div class="conn-card fadeinright animation-duration-200">
                <h2 class="w-title card-title">Stock</h2>
                <app-product-stock-management></app-product-stock-management>
            </div>
        </div>
        <div *ngIf="activeItem.label === 'Product Images'">
            <br />
            <div class="conn-card">
                <h2 class="w-title card-title">Gallery Images</h2>
                <div
                    class="fadeinright animation-duration-200 flex"
                    style="width: 100%"
                >
                    <app-image-multi style="width: 100%"></app-image-multi>
                </div>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Shipping'">
            <br />
            <div class="conn-card fadeinright animation-duration-200">
                <h2 class="w-title card-title">Shipping Information</h2>
                <app-product-shipping-info></app-product-shipping-info>
            </div>
        </div>

        <div *ngIf="activeItem.label === 'Additional'">
            <br />
            <div class="conn-card fadeinright animation-duration-200">
                <h2 class="w-title card-title">Additonal</h2>
                <app-product-notes></app-product-notes>
            </div>
        </div>
        <div *ngIf="activeItem.label === 'Print Label' && isNew" >
            <br />
            <div class="conn-card fadeinright animation-duration-200">
                <h2 class="w-title card-title">Print Label</h2>
                <div class="grid align-items-center">

                    <div class=" col-3">
                              <label class="control-label">
                                  <strong>
                                     Template
                                      <!-- <span style="color: red; font-size: 1.25rem" >*</span> -->
                                  </strong>
                              </label>       
                  </div>  
                          <div class="col-9 p-0 flex row">  
                              <div class="p-3 col-4 cursor-pointer" *ngFor="let temp of templateList">
                             <img 
                             [class]="
                                 temp.templateId == activeTemplateId
                                     ? 'active-template'
                                     : 'inactive-template'
                             " (click)="activeTemplate(temp.templateId)"
                              [src]="temp.templateURL" height="65vh"  alt="template">
                                 </div>
                           
                         
                          </div> 
                  
                      </div>
                  
                  <form
                      [formGroup]="labelForm"
                      autocomplete="off"
                     >
                     <div class="grid align-items-center">

                        <div class=" col-3">
                                  <label class="control-label">
                                      <strong>
                                         Location
                                          <!-- <span style="color: red; font-size: 1.25rem">*</span> -->
                                      </strong>
                                  </label>      
                      </div>  
                              <div class="col-9 p-0 row">
                                <div class="col-6">
                                    <p-dropdown
                                 [formControl]="$any(labelForm.controls)['location']"
                                 [style]="{}"
                                 class="p-dropdown-1"
                                 [options]="this._abd.locations"
                                 optionLabel="locationName"
                                 [autoDisplayFirst]="false"
                             >
                             </p-dropdown>
         <span class="control-description text-muted">This location will be printed on the printing label.</span>
                           
                                    <div *ngIf="$any(labelForm.controls)['location'].invalid && $any(labelForm.controls)['location'].touched" class="invalid-feedback">
                                        Please Select a Location
                                    </div>
                                </div>
                              </div>  
                      
                    </div>
                      <div class="grid align-items-center">
                  
                    <div class=" col-3">
                              <label class="control-label">
                                  <strong>
                                      Printer Locations
                                      <!-- <span style="color: red; font-size: 1.25rem" *ngIf="fromList">*</span> -->
                                  </strong>
                              </label>       
                  </div>  
                          <div class="col-9 p-0 row">
                              <div class="col-6">
                       
                               <p-multiSelect
                              [formControl]="$any(labelForm.controls)['locations']"
                              [style]="'width:100%'"
                              [options]="this._abd.locations"
                              optionLabel="locationName"
                              [filter]="false"
                              appendTo="body"
                              [showToggleAll]="true"
                            >
                          <ng-template pTemplate="header">
                             <div class="checkbox-all-text">Select All</div>
                           </ng-template>
                          </p-multiSelect>
                          <div *ngIf="$any(labelForm.controls)['locations'].invalid && $any(labelForm.controls)['locations'].touched" class="invalid-feedback">
                             Please enter a Location
                         </div> 
                             
                              </div>
                          
                          </div> 
                  
                      </div>
                  
                  
                      <div class="grid align-items-center">
                  
                    <div class=" col-3">
                              <label class="control-label">
                                  <strong>
                                     No of Labels to print
                                      <span style="color: red; font-size: 1.25rem" *ngIf="fromList">*</span>
                                  </strong>
                              </label>       
                  </div>  
                          <div class="col-9 p-0 row">
                            <div class="col-6">
                                <app-text-input
                                [formControl]="$any(labelForm.controls)['noOfCopy']"
                                [name]="'No of Labels to print'"
                                [label]=""
                               >
                               </app-text-input>
                            </div>
                          </div> 
                  
                      </div>
                  </form>
            </div>
        </div>
    </div>

    <div style="margin-top: 10px">
        <div class="conn-card fadeinleft animation-duration-100">
            <h2 class="w-title card-title">Thumbnail</h2>
            <div
                class="flex justify-content-center flex-wrap card-container blue-container"
            >
                <app-image-upload [imageData]="tempImageUrl"></app-image-upload>
                <span class="text-muted fs-7 text-align-center"
                    >Set the product thumbnail image. Only png, jpg, webP, and
                    jpeg image files are accepted</span
                >
            </div>
        </div>
        <div class="fadeinleft animation-duration-100">
            <br />
            <div class="conn-card">
                <h2 class="w-title card-title">Where to Sell</h2>
                <div class="sub-widget-w-margin-10">
                    <p-dropdown
                        [style]="{ width: '100%', 'min-width': '200px' }"
                        class="p-dropdown-1"
                        appendTo="body"
                        [options]="_abd.productSellType"
                        [(ngModel)]="_abd.selectedProductSellType"
                        optionLabel="Type"
                        [filter]="false"
                        (onChange)="whereToSellChanged()"
                    ></p-dropdown>

                    <span class="control-description text-muted">
                        Set sell online, if you going to sell using online
                        store</span
                    >
                </div>
            </div>
        </div>

        <div class="fadeinleft animation-duration-100" *ngxPermissionsOnly="['2028','2027','2029','2030']">
            <div class="conn-card">
                <h2 class="w-title card-title">Product Details</h2>
                <div class="sub-widget-w-margin-10" *ngxPermissionsOnly="['2028']">
                    <h3 class="w-title card-title">
                        Department <span style="color: red">*</span>
                    </h3>
                    <p-dropdown
                        [style]="{ width: '100%', 'min-width': '200px' }"
                        class="p-dropdown-1"
                        appendTo="body"
                        [options]="_abd.departmentsBS$ | async"
                        [(ngModel)]="_abd.selectedDepartment"
                        optionLabel="departmentName"
                        [filter]="true"
                        filterBy="departmentName"
                        (onChange)="departmentChanged($event)"
                    ></p-dropdown>
                    <span class="control-description text-muted">
                        Set Product's Department</span
                    >
                    <br />
                    <button
                        pButton
                        pRipple
                        type="button"
                        class="link-btn-alternate"
                        style="width: 100%; margin-top: 20px"
                        (click)="newDepartClick()"
                    >
                        <i
                            class="pi pi-plus"
                            style="font-size: 1rem; margin-right: 5px"
                        >
                        </i>
                        Add a new department
                    </button>
                </div>

                <div class="sub-widget-w-margin-10" *ngxPermissionsOnly="['2027']">
                    <!-- category -->
                    <h3 class="w-title card-title">
                        Category <span style="color: red">*</span>
                    </h3>
                    <p-dropdown
                        [style]="{ width: '100%', 'min-width': '200px' }"
                        class="p-dropdown-1"
                        appendTo="body"
                        [options]="_abd.categories$ | async"
                        [(ngModel)]="_abd.selectedCategory"
                        optionLabel="categoryName"
                        [filter]="true"
                        filterBy="categoryName"
                    ></p-dropdown>
                    <span class="control-description text-muted">
                        Set Product's Category</span
                    >
                    <br />
                    <button
                        pButton
                        pRipple
                        type="button"
                        class="link-btn-alternate"
                        style="width: 100%; margin-top: 20px"
                        (click)="_productService.isNewCategory = true"
                    >
                        <i
                            class="pi pi-plus"
                            style="font-size: 1rem; margin-right: 5px"
                        >
                        </i>
                        Add a new Category
                    </button>
                </div>

                <div class="sub-widget-w-margin-10" *ngxPermissionsOnly="['2029']">
                    <!-- Manufacter -->
                    <h3 class="w-title card-title">
                        Manufacturer <span style="color: red">*</span>
                    </h3>
                    <p-dropdown
                        [style]="{ width: '100%', 'min-width': '200px' }"
                        class="p-dropdown-1"
                        appendTo="body"
                        [options]="_abd.manufacturer$ | async"
                        [(ngModel)]="_abd.selectedManufacturer"
                        optionLabel="manufacturerName"
                        [filter]="true"
                        filterBy="manufacturerName"
                    ></p-dropdown>
                    <span class="control-description text-muted">
                        Set Product's Manufacturer</span
                    >
                    <br />
                    <button
                        pButton
                        pRipple
                        type="button"
                        class="link-btn-alternate"
                        style="width: 100%; margin-top: 20px"
                        (click)="_productService.isNewManufacturer = true"
                    >
                        <i
                            class="pi pi-plus"
                            style="font-size: 1rem; margin-right: 5px"
                        >
                        </i>
                        Add a new Manufacturer
                    </button>
                </div>

                <div class="sub-widget-w-margin-10" *ngxPermissionsOnly="['2030']">
                    <!-- Vendor -->
                    <h3 class="w-title card-title">
                        Vendor <span style="color: red">*</span>
                    </h3>
                    <p-dropdown
                        [style]="{ width: '100%', 'min-width': '200px' }"
                        class="p-dropdown-1"
                        appendTo="body"
                        [options]="_abd.vendorsBS$ | async"
                        [(ngModel)]="_abd.selectedVendor"
                        optionLabel="vendorName"
                        [filter]="true"
                        filterBy="vendorName"
                    ></p-dropdown>
                    <span class="control-description text-muted">
                        Set Product's Vendor</span
                    >
                    <br />
                    <button
                        pButton
                        pRipple
                        type="button"
                        class="link-btn-alternate"
                        style="width: 100%; margin-top: 20px"
                        (click)="_productService.isNewVendor = true"
                    >
                        <i
                            class="pi pi-plus"
                            style="font-size: 1rem; margin-right: 5px"
                        >
                        </i>
                        Add a new Vendor
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="conn-grid-main create-product">
    <div class="form-group mt-3">
        <div class="flex justify-content-end">
            <button
                *ngIf="!_productService.isViewOnly"
                pButton
                label="Save and Duplicate"
                class="p-button-help"
                [style]="{ width: '150px', 'margin-right': '10px' }"
                [disabled]="!validToSave()"
                (click)="saveProductClick('save-and-duplicate')"
            ></button>

            <p-button
                *ngIf="!_productService.isViewOnly"
                label="Save Product"
                [style]="{ width: '150px', 'margin-right': '10px' }"
                [disabled]="!validToSave()"
                (onClick)="saveProductClick('save-only')"
            ></p-button>
            <button
                pButton
                type="button"
                label="Cancel"
                class="p-button-outlined p-button-danger"
                [style]="{ width: '150px' }"
                (click)="cancelClick()"
            ></button>
        </div>
    </div>
    <div></div>
</div>

<!-- -- MODELS -- -->
<p-dialog
    *ngIf="_productService.isNewDepartment"
    header="New Department"
    [(visible)]="_productService.isNewDepartment"
    modal="true"
>
    <app-new-edit-department></app-new-edit-department>
</p-dialog>

<p-dialog
    *ngIf="_productService.isNewCategory"
    header="New Category"
    [(visible)]="_productService.isNewCategory"
    modal="true"
>
    <app-new-edit-category></app-new-edit-category>
</p-dialog>

<p-dialog
    *ngIf="_productService.isNewManufacturer"
    header="New Manufacturer"
    [(visible)]="_productService.isNewManufacturer"
    modal="true"
>
    <app-new-edit-manufacturer></app-new-edit-manufacturer>
</p-dialog>

<p-dialog
    *ngIf="_productService.isNewVendor"
    header="New Vendors"
    [(visible)]="_productService.isNewVendor"
    modal="true"
>
    <app-new-edit-vendor [isNew]="true"></app-new-edit-vendor>
</p-dialog>
