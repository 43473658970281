import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/_services/products.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-stock-management',
  templateUrl: './product-stock-management.component.html',
  styleUrls: ['./product-stock-management.component.scss']
})
export class ProductStockManagementComponent implements OnInit {
  data: any = ''
  loading = false

  constructor(
    public _reportService: ReportsService,
    public _productService: ProductsService,
    public _abd: AppBaseDataService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    if (this._abd.locations.length) {

      const x = this._abd.initActLocations()
      this._abd.initLocations(x)


      if (!this._productService.selectedLocations.length || !this._productService.selectedLocations[0]) {
        for (let i = 0; i < x.length; i++)
          x[i].openingQty = 0
        this._productService.selectedLocations = x
      } else {
        const copy = JSON.parse(JSON.stringify(this._productService.selectedLocations))
        this._productService.selectedLocations = []
        for (let i = 0; i < copy.length; i++) {
          for (let j = 0; j < x.length; j++) {
            if (copy[i].id == x[j].id) {
              this._productService.selectedLocations.push(x[j])
              break
            }
          }
        }
      }
      //-- api call must come here
      let locations = ''
      const locs = this._productService.selectedLocations
      for (let i = 0; i < locs.length; i++)
        locations += locs[i].locationId + ','

      locations = locations.substring(0, locations.length - 1)

      if (this._productService.isNew) {
        for (let j = 0; j < this._productService.selectedLocations.length; j++) {
          let line = this._productService.selectedLocations[j]
          // if (line.stockOnHand) line.stockOnHand = 0
          // if (line.openingStock) line.openingStock = 0
          // if (line.stockOnHandQoh) line.stockOnHandQoh = 0
          line.stockOnHand = 0
          line.stockOnHandQoh = 0
          if (!line.openingStock) line.openingStock = 0

          try { delete line.newQoh }
          catch { }
        }
      }
      else {

        this._productService.getItemStockStatus(this._productService.activeItem.crsItemSKUs[0].id, locations).subscribe(res => {
          if (res.success) {
            this.loading = true
            this.data = res.data.stockDetails
            let dt = this.data
            for (let i = 0; i < dt.length; i++) {
              for (let j = 0; j < this._productService.selectedLocations.length; j++) {
                let line = this._productService.selectedLocations[j]
                try {
                  if (dt[i].locationId == line.locationId) {

                    if (typeof line.newQoh == "undefined") {
                      line.newQoh = dt[i].stockOnHands
                      line.stockOnHand = dt[i].stockOnHands
                      line.stockOnHandQoh = dt[i].stockOnHands
                      line.openingStock = dt[i].openingStock
                    } else {
                      line.newQoh = dt[i].stockOnHands
                      line.stockOnHand = dt[i].stockOnHands
                      line.stockOnHandQoh = dt[i].stockOnHands
                      line.openingStock = dt[i].openingStock
                    }
                  }
                } catch { }
              }
            }
          }
        })
      }
    }
  }

  gotoaddlocation() {
    this._router.navigate(['app/locations-list'])
  }

  calcMax(item) {
    if (this._productService.isNew) return 99999999999
    else return item.stockOnHand
  }
  changeQOH(e: any) {
    this._productService.QOHChange = true;
  }

  ok2showLocations() {
    if (this._abd.actLocations.length > 0 &&
      !this._productService.activeItem &&
      this._productService.selectedLocations.length > 0)
      return true

    return false
  }



}
