import { UserService } from './../../_services/user.service';
import { Component, OnInit } from '@angular/core';
import { usTimeZoneList } from 'src/app/_data/time-zones';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/_services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';

@Component({
  selector: 'app-business-setup',
  templateUrl: './business-setup.component.html',
  styleUrls: ['./business-setup.component.scss']
})
export class BusinessSetupComponent implements OnInit {
  showContinueButton: boolean = true;
  showBackButton: boolean = false;

  activeTab = 'configuration-setup'
  currentStep: number = 4
  usTimeZones = usTimeZoneList

  mitems = [
    {
        id: 1,
        title: 'General Info',
        desc: 'Set some generic info about your business',
        actionSaved: false
    },
    {
        id: 2,
        title: 'Location Setup',
        desc: 'Manage different locations of your business',
        actionSaved: false
    },
    {
        id: 3,
        title: 'User Setup',
        desc: 'Manage different users of your business',
        actionSaved: false
    },
    {
        id: 4,
        title: 'Configuration',
        desc: 'Set some basic configurations according to your business',
        actionSaved: false
    },
  ]

  _globals = GlobalService

  savedSteps : number = -1 ;
  constructor(
    public _businessSetup: BusinessSetupService,
    private _userService: UserService,
    private _router: Router,
    private _spinner: NgxSpinnerService,
    private _abd: AppBaseDataService
  ) { }

    ngOnInit(): void {
      this.continue()
        // this.checkSavedStep();
    }

    // checkSavedStep(){
    //     let anyStepFound: any = sessionStorage.getItem('saved-step');
    //     if (anyStepFound != undefined) {
    //         for (let index = 0; index <= (parseInt(anyStepFound)-1); index++) {
    //             this.mitems[index].actionSaved = true;

    //         }
    //     }
    // }

  continue() {

    // if (this.activeTab == 'general-info-1') {
      this._abd.showSpinner()
 
      try {
        this._abd.showSpinner()
        this._userService.createStaffUser_ForSetupOnly(JSON.stringify(this._businessSetup.activeItem)).subscribe(res => {
          if (res.success) {
            this._userService.getUserByUserName(this._globals.userInfo.username).subscribe(res1 => {
              if (res1.success) {
                this.currentStep = 4
                this.activeTab = 'configuration-setup'
                this.showBackButton = true
                  this.mitems[2].actionSaved = true;
                  sessionStorage.setItem('saved-step', '3')
                this._businessSetup.isDisableNextButton = false
                this._abd.hideSpinner()
                return res1
              }
            })
          }
        })
      } catch { this._abd.hideSpinner() }

    // } 
    // else if (this.activeTab == 'location-setup') {
    //   this.currentStep = 3
    //   this.activeTab = 'user-setup'
    //   this.showBackButton = true
    //     this.mitems[1].actionSaved = true;
    //     sessionStorage.setItem('saved-step', '2')

    // }
    //  else if (this.activeTab == 'user-setup') {
    //   this.currentStep = 4
    //   this.activeTab = 'configuration-setup'
    //   this.showBackButton = true
    //     this.mitems[2].actionSaved = true;
    //     sessionStorage.setItem('saved-step', '3')
    // } else if (this.activeTab == 'final-step') {

    //   sessionStorage.setItem('saved-step', '4')
    //   sessionStorage.setItem('final-step', '1')
    //     localStorage.setItem('goto', window.location.origin+'/')
    //   //  sessionStorage.setItem('setup-complete', '1')
    //   this._userService.businessWizardComplete().subscribe(res => {
    //     if (res.success) {

    //         let websiteDomain: string = "";
    //         websiteDomain = window.location.origin + '/'; // this._globals.whiteLabel.domain
    //         var n = websiteDomain.lastIndexOf('/');
    //         var result = websiteDomain.substring(n + 1);
    //         localStorage.setItem('goto','')
    //         if (result == "") {
    //             websiteDomain = websiteDomain.substring(0, websiteDomain.length - 1);

    //         }
    //         window.location.href = websiteDomain + "/pages/signin"
           
    //        // this._router.navigate(['/'], { replaceUrl: true });
    //       //this._router.navigate(['/'])
    //     }
    //   })
    // }
    // else if (this.activeTab == 'configuration-setup') {
    //   this.currentStep = 5
    //   this.activeTab = 'final-step'
    //   this.showBackButton = true
    //     this.mitems[3].actionSaved = true;
    //     sessionStorage.setItem('saved-step', '')
    // }

  }
saveFn(){
       sessionStorage.setItem('saved-step', '4')
      localStorage.setItem('goto', window.location.origin+'/')
    this._userService.businessWizardComplete().subscribe(res => {
      if (res.success) {

          let websiteDomain: string = "";
          websiteDomain = window.location.origin + '/'; // this._globals.whiteLabel.domain
          var n = websiteDomain.lastIndexOf('/');
          var result = websiteDomain.substring(n + 1);
          localStorage.setItem('goto','')
          if (result == "") {
              websiteDomain = websiteDomain.substring(0, websiteDomain.length - 1);

          }
          window.location.href = websiteDomain + "/#/"
         
      }
    })
}
  back() {
    if (this.activeTab == 'final-step') {
      this.activeTab = 'configuration-setup'
      this.currentStep = 4
      this.showBackButton = true
    } else if (this.activeTab == 'user-setup') {
      this.activeTab = 'location-setup'
      this.currentStep = 2
      this.showBackButton = true
    } else if (this.activeTab == 'location-setup') {
      this.currentStep = 1
      this.activeTab = 'general-info-1'
      this.showBackButton = false
    } else if (this.activeTab == 'configuration-setup') {
      this.currentStep = 3
      this.activeTab = 'user-setup'
      this.showBackButton = true
    }
    // else if (this.activeTab == 'final-setup') {
    //   this.currentStep = 4
    //   this.activeTab = 'configuration-setup'
    //   this.showBackButton = true
    // }
  }
}

