import { PurchaseService } from 'src/app/_services/purchase.service';
import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { GlobalService } from 'src/app/_services/global.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Router } from '@angular/router';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import jsPDF from "jspdf";
import "jspdf-autotable";
import { FilterServiceService } from 'src/app/_services/filter-service.service';

@Component({
  selector: 'app-purchase-order-list',
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.scss']
})
export class PurchaseOrderListComponent implements OnInit {
  _globals = GlobalService
  @ViewChild('opActions') opActions: OverlayPanel

  page = 0
  rows = 10
  first = 0
  totalRecords = 0

  //order
  orderParam = ''
  orderBy = ''

  selectedRow: any

  completeData:any;

  dataList: any = []
  a=true;
  actions: MenuItem[]
  actionClicked: string = '';
  dataObj: any;
  isEmailEnable: boolean;

  constructor(
    public _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private _messageService: MessageService,
    public _purchaseService: PurchaseService,
    private _confirmationService: ConfirmationService,
    private _router: Router,
    private _filterService: FilterServiceService,
  ) { }

  ngOnInit(): void {
    this._purchaseService.baseFilterOptions = this._abd.appCookedData(false)
    this._globals.componentName = 'Purchase Orders'
    try {
      this._spinner.show()
        this._purchaseService.getPurchaseOrderConfigurationDetails().subscribe((r) => { 
          if (r.success) {
                this.isEmailEnable = r.data.po_sendemail;
            this._spinner.hide()
              }
        },)
    } catch (error) {

    }
  }

  ngAfterViewInit() {
    const cname = 'purchase-orders-list'
    let ppages = this._filterService.getPages(cname)
    if (ppages) {
      let lastpage = parseInt(ppages.page)
      if (lastpage < 0) lastpage = 0
      if (lastpage > 0) {
        this.page = lastpage
        this.first = lastpage * this.rows
      }
    }

    let filter = this._filterService.getFilters(cname)
    if (filter) {
      if (filter.searchText) this._filterService.searchText = filter.searchText

      if (filter.startDate) this._filterService.dateRange[0] = new Date(filter.startDate)
      if (filter.endDate) this._filterService.dateRange[1] = new Date(filter.endDate)

      if (filter.selectedLocation) this._filterService.selectedLocation = filter.selectedLocation
      if (filter.selectedVendor) this._filterService.selectedVendor = filter.selectedVendor
      if (filter.poStatus) {
        const f = this._purchaseService.purchaseStatusList.find(f => f.value == filter.poStatus)
        if (f)
          this._filterService.selectedStatus = f
        else
          this._filterService.selectedStatus = 'All'
      }
    }

    let oorderby = this._filterService.getOrderby(cname)
    if (oorderby) {
      this.orderParam = oorderby.orderParam
      this.orderBy = oorderby.orderBy
    }

    this.getDataList()

  }
  checkIsEmailAllow(){
    if(!this.isEmailEnable){
   this.actions = this.actions.filter((v)=>v.label !== 'Send Email')
      }
    }
  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this.getDataList()
  }

  checkDateRange(what) {
    if (what == 'single') {
      try {
        //-- code for single date comes here
      }
      catch { }
    }
    if (what == 'range') {
      if (!isNaN(this._filterService.dateRange[0].getTime())) {
        try {
          if (isNaN(this._filterService.dateRange[1].getTime()))
            this._filterService.dateRange[1] = this._filterService.dateRange[0]
        }
        catch { }
      }
      else {
        this._filterService.initDates()
        return
      }
    }
  }

  getDataList() {
    this.checkDateRange('range')
    let obj = this._filterService
    let vendorid = obj.selectedVendor ? obj.selectedVendor.id : ''
    let locationid = obj.selectedLocation ? obj.selectedLocation.locationId : ''
    let poStatus = (obj.selectedStatus && obj.selectedStatus !== 'All') ? obj.selectedStatus.value : 'All'

    this.page = this.page < 1 ? 0 : this.page
    this.rows = this.rows < 1 ? 10 : this.rows

    const outfilter = {
      searchText: this._filterService.searchText ? this._filterService.searchText : '',
      selectedLocation: this._filterService.selectedLocation ? this._filterService.selectedLocation : '',
      selectedVendor: this._filterService.selectedVendor ? this._filterService.selectedVendor : '',
      poStatus: poStatus ? poStatus : 'All',
      startDate: formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
      endDate: formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
    }

    this._spinner.show()
    this._purchaseService.getPoList(
      this._filterService.searchText,
      false,
      locationid,
      vendorid,
      poStatus,
      formatDate(this._filterService.dateRange[0], 'MM/dd/yyyy', 'en-US'),
      formatDate(this._filterService.dateRange[1], 'MM/dd/yyyy', 'en-US'),
      this.page,
      this.rows
    ).subscribe(res => {
      this._spinner.hide()
      if (res.success) {
        this.totalRecords = res.totalRecords
        this.dataList = res.data

        for (let i = 0; i < this.dataList.length; i++) {
          this.dataList[i].srNo = i + 1
        }

        const cname = 'purchase-orders-list'
        this._filterService.updateFilters(cname, outfilter)
        this._filterService.updatePages(cname, { page: this.page, rows: this.rows })
      }
    })
  }

  actionButtonClicked(item) {
    this.actions = [
      { label: 'Create duplicate', icon: 'pi pi-clone', styleClass: 'p-button-text', disabled: false },
      // { label: 'Post', icon: 'pi pi-check-square', styleClass: 'p-button-text', disabled: false },
      // { label: 'Cancel', icon: 'pi pi-times', styleClass: 'p-button-text', disabled: false },
    ]
    // createDuplicateOrder(poStatus='DRAFT')
    this._purchaseService.mapItemTonewEditItem(item)
    let obj = this._purchaseService.newEditItem
    obj.isViewOnly = false
    obj.isNew = false
    obj.isEdit = true

    const m = this._purchaseService.newEditItem.POStatus
    switch (m) {
      case 'DRAFT':
        this.actions = [
          { label: 'Edit', icon: 'pi pi-pencil', styleClass: 'p-button-text', disabled: false, command: () => { this.optionEditClicked() } },
          { label: 'Create duplicate', icon: 'pi pi-clone', styleClass: 'p-button-text', disabled: false },
          { label: 'Post', icon: 'pi pi-check-square', styleClass: 'p-button-text', disabled: false },
          { label: 'Cancel PO', icon: 'pi pi-times', styleClass: 'p-button-text', disabled: false },
        ]
        break
      case 'OPEN':
        this.actions = [
          {
            label: 'Create duplicate', icon: 'pi pi-clone', styleClass: 'p-button-text', disabled: false
          },
          // { label: 'Direct Receive', icon: 'pi pi-window-minimize', styleClass: 'p-button-text', disabled: false },
          { label: 'Receive PO', icon: 'pi pi-file-import', styleClass: 'p-button-text', disabled: false },
          // { label: 'Commit PO', icon: 'pi pi-check-circle', styleClass: 'p-button-text', disabled: false },
          // { label: 'Cancel PO', icon: 'pi pi-times', styleClass: 'p-button-text', disabled: false },
          { label: 'Send Email', icon: 'pi pi-send', styleClass: 'p-button-text',disabled:false },
        ]

        if (item.inventoryStatus == 'RECEIVED' || item.inventoryStatus == 'PARTIAL')
          this.actions.push({ label: 'Post PO', icon: 'pi pi-check-circle', styleClass: 'p-button-text', disabled: false })

        this.actions.push({ label: 'Cancel PO', icon: 'pi pi-times', styleClass: 'p-button-text', disabled: false })
        break
      case 'CANCELLED':
        this.actions = [
          { label: 'Create duplicate', icon: 'pi pi-clone', styleClass: 'p-button-text', disabled: false },
        ]
        break
      case 'CLOSEDFORRECEIVING':
        this.actions = [
          { label: 'Create duplicate', icon: 'pi pi-clone', styleClass: 'p-button-text', disabled: false },
          { label: 'Close PO', icon: 'pi pi-check-circle', styleClass: 'p-button-text', disabled: false },
        ]
        break

      default:
        // this.actions[0].disabled = true
        // this.actions[1].disabled = true
        // this.actions[2].disabled = true
    }
    this.checkIsEmailAllow()
  }

  actionButtonOptionsClicked(label: string) {
    label = label.toLocaleLowerCase()
    this._purchaseService.generatePoNumber()
    this.actionClicked = label;
    switch (label) {
      case 'create duplicate':
        this._confirmationService.confirm({
          message: 'Are you sure to duplicate this Purchase Order?',
          header: 'Confirmation',
          icon: 'pi pi-info-circle',
          accept: () => {
            try {
              this.createDuplicateOrder()
              this.getDataList()
            } catch { }
          },
          reject: () => { }
        })
        break
      case 'receive po':
        this._router.navigate(['app/purchase-orders-receive-closed-for-receiving'])
        break
      case 'send email':
       this.sendEmail(); 
      break
      case 'direct receive':
        this.opActions.hide()
        this._router.navigate(['app/purchase-orders-receive-direct'])
        break
      case 'edit':
        this.optionEditClicked()
        break
      case 'post':
        this.opActions.hide()
        this.createPurchaseOrder('OPEN')
        break
      case 'post po':
        this._confirmationService.confirm({
          message: 'Are you sure you want to Post this Purchase Order?',
          header: 'Confirmation',
          icon: 'pi pi-info-circle',
          accept: () => {
            try {
              this.optionCommitClicked()
            } catch { }
          },
          reject: () => { }
        })
        break
      case 'close po':
        this._confirmationService.confirm({
          message: 'Are you sure you want to Close this Purchase Order?',
          header: 'Confirmation',
          icon: 'pi pi-info-circle',
          accept: () => {
            try {
              this.optionCloseClicked()
            } catch { }
          },
          reject: () => { }
        })
        break
      case 'cancel po':
        this.opActions.hide()

        this._confirmationService.confirm({
          message: 'Are you sure you want to CANCEL this Purchase Order?',
          header: 'Confirmation',
          icon: 'pi pi-info-circle',
          accept: () => {
            try {
              this.optionCancelClicked()
            } catch { }
          },
          reject: () => { }
        })
        break
      case 'delete':
        this.opActions.hide()
        break
    }
  }
  sendEmail(){
        this._purchaseService.isSendEmail = true;
  }

  savePO(obj, poStatus = 'DRAFT') {

    let id = obj.id

    if (poStatus == 'DRAFT') {
      const pono = this._purchaseService.generatePoNumber()
      id = '-1'
      obj.poNumber = pono
    }

if (obj.poType=='DIRECT') {
  const lst = obj.dpoItems
  let mlist = []

      let netTotal = 0
      let taxTotal = 0
      let discountTotal = 0

      for (let i = 0; i < lst.length; i++) {
        let sku = ''

        const itm = lst[i]
        const m = {
          itemSKUId: itm.itemSKUId,
          notes: itm.notes,
          quantity: itm.receivedQuantity,
          costPrice: this._globals.roundNumbers(itm.costPrice),
          subTotal: this._globals.roundNumbers(itm.subTotal),
          discountPercent: itm.discountPercent,
          discountAmount: this._globals.roundNumbers(itm.discountAmount),
          taxRate: itm.taxRate,
          taxAmount: this._globals.roundNumbers(itm.taxAmount),
          Shipping: this._globals.roundNumbers(itm.shipping),
          totalAmount: this._globals.roundNumbers(itm.totalAmount),
          updatedById: this._globals.userInfo.userID,
          updatedBy: this._globals.userInfo.displayName,
          priceA: itm.priceA,
          priceB: itm.priceB,
          priceC: itm.priceC,
        }
        mlist.push(m)

        netTotal += lst[i].netCost
        taxTotal += lst[i].taxAmount
        discountTotal += lst[i].discountAmount
      }
      const model = {
        id: id,
        poNumber: obj.poNumber,
        POType: 'DIRECT',
        dated: new Date(),
        dueDate: new Date(),
        vendorId: obj.vendorId,
        requestedById: this._globals.userInfo.userID,
        requestedBy: this._globals.userInfo.displayName,
        locationId: obj.locationId,
        notes: obj.notes,
        poStatus: 'CLOSEDFORRECEIVING',
        paymentStatus: 'PENDING',
        subTotal: this._globals.roundNumbers(obj.subTotal),
        netTotal: this._globals.roundNumbers(obj.netTotal),
        taxTotal: this._globals.roundNumbers(obj.taxTotal),
        discountTotal: this._globals.roundNumbers(obj.discountTotal),
        shipping: this._globals.roundNumbers(obj.shipping),
        grandTotal: this._globals.roundNumbers(obj.grandTotal),
        ShippingByItem: obj.shippingByItem,
        DividedShippingItems: obj.dividedShippingItems,
        "POItems": mlist,
      }

      this._spinner.show()
      this._purchaseService.purchaseOrderPost(model).subscribe(res => {

        if (res.success) {
          if(this.actionClicked == "open"){
              this._messageService.add({ severity: 'success', summary: 'Purchase Order Posted successfully!' })
              this.actionClicked = '';
          }

          this.getDataList()
        } else {
          this._messageService.add({ severity: 'error', summary: 'Unable to post purchase order!' })
        }
        this._spinner.hide()
      })
    }
    else {
      const lst = obj.poItems
      let mlist = []

      let netTotal = 0
      let taxTotal = 0
      let discountTotal = 0

      for (let i = 0; i < lst.length; i++) {
        let sku = ''

        const itm = lst[i]
        const m = {
          itemSKUId: itm.itemSKUId,
          notes: itm.notes,
          quantity: itm.orderedQty,
          costPrice: this._globals.roundNumbers(itm.costPrice),
          subTotal: this._globals.roundNumbers(itm.subTotal),
          discountPercent: itm.discountPercent,
          discountAmount: this._globals.roundNumbers(itm.discountAmount),
          taxRate: itm.taxRate,
          taxAmount: this._globals.roundNumbers(itm.taxAmount),
          Shipping: this._globals.roundNumbers(itm.shipping),
          totalAmount: this._globals.roundNumbers(itm.totalAmount),
          updatedById: this._globals.userInfo.userID,
          updatedBy: this._globals.userInfo.displayName,
        }
        mlist.push(m)

        netTotal += lst[i].netCost
        taxTotal += lst[i].taxAmount
        discountTotal += lst[i].discountAmount
      }


    const model = {
      id: id,
      poNumber: obj.poNumber,
      POType: 'MANUAL',
      dated: new Date(),
      dueDate: new Date(),
      vendorId: obj.vendorId,
      requestedById: this._globals.userInfo.userID,
      requestedBy: this._globals.userInfo.displayName,
      locationId: obj.locationId,
      notes: obj.notes,
      poStatus: poStatus, //'DRAFT',
      paymentStatus: 'PENDING',
      subTotal: this._globals.roundNumbers(obj.subTotal),
      netTotal: this._globals.roundNumbers(obj.netTotal),
      taxTotal: this._globals.roundNumbers(obj.taxTotal),
      discountTotal: this._globals.roundNumbers(obj.discountTotal),
      shipping: this._globals.roundNumbers(obj.shipping),
      grandTotal: this._globals.roundNumbers(obj.grandTotal),
      ShippingByItem: obj.shippingByItem,
      DividedShippingItems: obj.dividedShippingItems,
      "POItems": mlist,
    }

    this._spinner.show()
    this._purchaseService.purchaseOrderPost(model).subscribe(res => {

      if (res.success) {
        if(this.actionClicked == "open"){
            this._messageService.add({ severity: 'success', summary: 'Purchase Order Posted successfully!' })
            this.actionClicked = '';
        }

        this.getDataList()
      } else {
        this._messageService.add({ severity: 'error', summary: 'Unable to post purchase order!' })
      }
      this._spinner.hide()
    })
  }
  }

  createPurchaseOrder(poStatus = 'DRAFT') {
    this._spinner.show()
    try {
      this._purchaseService.searchSingleOrder(this._purchaseService.newEditItem.Id).subscribe(res => {
        if (res.success) {
        this._messageService.add({ severity: 'success', summary: 'Purchase Order Posted successfully!' })
          const obj = res.data
          this.savePO(obj, poStatus)
          this._spinner.hide()
        } else {
          this._spinner.hide()
          this._messageService.add({ severity: 'error', summary: 'Unable to Post purchase order!' })
        }
      })
    } catch { this._spinner.hide() }
  }

  createDuplicateOrder(poStatus = 'DRAFT') {
    this._spinner.show()
    try {
      this._purchaseService.searchSingleOrder(this._purchaseService.newEditItem.Id).subscribe(res => {
        if (res.success) {
        this._messageService.add({ severity: 'success', summary: 'Purchase Order duplicated successfully!' })
          const obj = res.data
          this.savePO(obj, poStatus)
          this._spinner.hide()
        } else {
          this._spinner.hide()
          this._messageService.add({ severity: 'error', summary: 'Unable to duplicate purchase order!' })
        }
      })
    } catch { this._spinner.hide() }
  }

  optionCommitClicked() {
    try {
      let data = {
        "poStatus": this._purchaseService.newEditItem.POStatus,
        "requestedById": this._globals.userInfo.userID,
        "requestedBy": this._globals.userInfo.displayName,
      }

      this._spinner.show()
      this._purchaseService.saveReceivedPurchaseOrder(this._purchaseService.newEditItem.Id, true, data).subscribe(res => {
        this._spinner.hide()
        if (res.success) {
          this._messageService.add({ severity: 'success', summary: 'Purchase Order processed successfully!' })
          this.getDataList()
        }
      })

    } catch { this._abd.hideSpinner() }
  }

  optionCloseClicked() {
    try {
      let data = {
        "poStatus": 'CLOSED',
        "requestedById": this._globals.userInfo.userID,
        "requestedBy": this._globals.userInfo.displayName,
      }

      this._spinner.show()
      this._purchaseService.saveReceivedPurchaseOrder(this._purchaseService.newEditItem.Id, false, data).subscribe(res => {
        this._spinner.hide()
        if (res.success) {
          this._messageService.add({ severity: 'success', summary: 'Purchase Order processed successfully!' })
          this.getDataList()
        }
      })

    } catch { this._abd.hideSpinner() }
  }

  optionCancelClicked() {
    try {
      let data = {
        "poStatus": "CANCELLED",
        "requestedById": this._globals.userInfo.userID,
        "requestedBy": this._globals.userInfo.displayName,
      }

      this._spinner.show()
      this._purchaseService.updatePurchaseOrder(this._purchaseService.newEditItem.Id, data).subscribe(res => {
        this._spinner.hide()
        if (res.success) {
          this._messageService.add({ severity: 'success', summary: 'Purchase Order Cancelled successfully!' })
          this.getDataList()
        }
      })

    } catch { this._abd.hideSpinner() }
  }

  optionEditClicked() {
    let obj = this._purchaseService.newEditItem
    let obj1 = this.dataList.find(f => f.id == obj.Id)
    if (!obj1) return

    obj.isViewOnly = false
    obj.isNew = false
    obj.isEdit = true
    this.opActions.hide()

    if (obj1.poType == 'DIRECT')
      this._router.navigate(['app/purchase-orders-receive-direct'])
    else
      this._router.navigate(['app/purchase-orders-create-edit'])
  }

  onRowSelect(e) {
    this._purchaseService.mapItemTonewEditItem(e.data)
    let obj = this._purchaseService.newEditItem
    obj.isViewOnly = true
    obj.isNew = false
    obj.isEdit = false

    this._purchaseService.selectedId = e.data.id
    if (e.data.poStatus == 'DRAFT' && e.data.poType == 'DIRECT')
      this._router.navigate(['app/purchase-orders-receive-direct'])
    else if (e.data.poStatus == 'DRAFT' && e.data.poType !== 'DIRECT')
      this._router.navigate(['app/purchase-orders-create-edit'])
    else if (e.data.poStatus == 'OPEN')
      this._router.navigate(['app/purchase-orders-receive-open'])
    else if (e.data.poStatus == 'CLOSEDFORRECEIVING')
      this._router.navigate(['app/purchase-orders-receive-open'])
       else if (e.data.poStatus == 'CLOSED')
       this._router.navigate(['app/purchase-orders-receive-open'])
  }

  onRowUnselect(e) { }

  callbackFuncFromFilter(event) {
    this.getDataList()
  }

  filterButtonPressed() {
    this.page = 0
    this.getDataList()
  }

  exportExcel() {
    let header = [
      'Sr#', 'Date', 'PO#', 'Location', 'Vendor', 'Qty',
      'Sub Total', 'Discount', 'Net Cost', 'Tax',
      'Total Cost', 'Status'
    ]
    const ws = []
    const xd = this.dataList
    let tmp = []
    tmp.push(header)
    for (let j = 0; j < xd.length; j++) {
      let inner = []
      inner.push(xd[j].srNo)
      inner.push(formatDate(new Date(xd[j].dated), 'MM/dd/yyyy, h:mm a', 'en-US'))
      inner.push(xd[j].poNumber)
      inner.push(xd[j].locationName)
      inner.push(xd[j].vendorName)
      inner.push(xd[j].totalQty.toFixed(2))
      inner.push('$ ' + xd[j].subTotal.toFixed(2))
      inner.push('$ ' + xd[j].discountTotal.toFixed(2))
      inner.push('$ ' + (xd[j].subTotal - xd[j].discountTotal).toFixed(2))
      inner.push('$ ' + xd[j].taxTotal.toFixed(2))
      inner.push('$ ' + xd[j].grandTotal.toFixed(2))
      inner.push(xd[j].poStatus)
      tmp.push(inner)
    }
    ws.push(tmp)

    const workbook = {
      Sheets: {
        'Purchase Order List': XLSX.utils.aoa_to_sheet(ws[0]),
      },
      SheetNames: ['Purchase Order List']
    };

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const filename = 'purchase-order-list-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.xlsx'
    FileSaver.saveAs(data, filename);
  }

  exportPdf() {
    let cols = [
      { id: 0, dataKey: "srNo", title: "Sr#", type: 'string' },
      { id: 1, dataKey: "dated", title: "Date", type: 'date' },
      { id: 2, dataKey: "poNumber", title: "PO#", type: 'string' },
      { id: 3, dataKey: "locationName", title: "Location", type: 'string' },
      { id: 4, dataKey: "vendorName", title: "Vendor", type: 'string' },
      { id: 5, dataKey: "totalQty", title: "Qty", type: 'int' },
      { id: 6, dataKey: "subTotal", title: "Sub Total", type: 'currency' },
      { id: 7, dataKey: "discountTotal", title: "Discount", type: 'currency' },
      { id: 8, dataKey: "discountTotal", title: "Net Cost", type: 'currency' },
      { id: 9, dataKey: "taxTotal", title: "Tax", type: 'currency' },
      { id: 10, dataKey: "grandTotal", title: "Total Cost", type: 'currency' },
      { id: 11, dataKey: "poStatus", title: "Status", type: 'string' },
    ]

    const data = this._abd.parseForExport(this.dataList, cols)

    const doc = new jsPDF('l', 'mm', 'a3');
    doc.text('Purchase Order ' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US'),15,10),
    doc['autoTable'](cols, data)
    const filename = 'purchase-order-list-' + formatDate(new Date(), 'MMM/dd/yyyy', 'en-US') + '.pdf'
    doc.save(filename);
  }

  doEnableActionButton(item) {
    const showOn = [1]
    try {
      if (showOn.find(f => f == item)) return false
      else return true
    } catch { return true }
  }



}
