import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-product-general-properties',
  templateUrl: './product-general-properties.component.html',
  styleUrls: ['./product-general-properties.component.scss']
})
export class ProductGeneralPropertiesComponent implements OnInit {
  uom:any=[]
  constructor(
    public _productService: ProductsService,
    public _abd: AppBaseDataService
  ) { }

  ngOnInit(): void {

    const x = this._productService.selectedSellAs

    this.setUoms(x.value)
  }

  sellAsClicked(e) {
    this.setUoms(e.value.value)
  }

  setUoms(value:any) {
    const uom = JSON.parse(JSON.stringify(this._abd.uom))
    this._abd.uomCalc=[]
    this._abd.uomCalc = this._abd.uom.filter(f => f.unitClassId === value)
  }

}
