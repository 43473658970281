import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-product-shipping-info',
  templateUrl: './product-shipping-info.component.html',
  styleUrls: ['./product-shipping-info.component.scss']
})
export class ProductShippingInfoComponent implements OnInit {
  editform: FormGroup
  weightUOM:any=''

  constructor(
    private _fb: FormBuilder,
    public _productService: ProductsService,
    public _abd: AppBaseDataService,
  ) { }

  ngOnInit(): void {
    // this.weightUOM=this._abd.WeightUOM[0]
    this.initForm()
  }

  initForm() {
    let obj = this._productService.activeItem

    this.editform = this._fb.group({
      weight: [this._productService.shippingWeight],
      height: [this._productService.shippingHeight],
      width: [this._productService.shippingWidth],
      length: [this._productService.shippingLength],
    })
  }

  saveFn() {}

  onInput(e, what:string) {
    if(what=='shippingWeight') this._productService.shippingWeight = e.value;
    if(what=='shippingHeight') this._productService.shippingHeight = e.value;
    if(what=='shippingWidth') this._productService.shippingWidth = e.value;
    if(what=='shippingLength') this._productService.shippingLength = e.value;
  }

}
