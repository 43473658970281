import { Injectable } from '@angular/core';
import { AppBaseDataService } from './app-base-data.service';
import { BaseService } from './base.service';
import { map } from 'rxjs';

import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  baseFilterOptions = {}

  filter = {
    searchText: '',
    selectedLocation: '',
    selectedVendor: '',
    startDate: '',
    endDate: '',
    poNo: '',
    status: '',
  }

  purchaseStatusList = [
    { id: 1, value: 'DRAFT', name: 'Draft' },
    { id: 2, value: 'OPEN', name: 'Open' },
    { id: 3, value: 'CLOSED', name: 'Closed' },
    { id: 4, value: 'CANCELLED', name: 'Cancelled' },
    { id: 5, value: 'CLOSEDFORRECEIVING', name: 'Closed for receiving' },
  ]

  purchaseType = [
    { id: 1, name: 'PO' },
    { id: 2, name: 'DP' },
  ]

  isNewDirectPO: boolean = false
  activeItem: any = null
  activeItemFull: any = null

  newEditItem12 = {
    poNumber: null,
    id: '',
    requestedById: '',
    requestedByName: '',
    receivedById: '',
    receivedByName: '',
    vendorId: '',
    vendorObj: null,
    locationId: '',
    locationObj: null,
    dated: new Date(),
    enableDueDate: false,
    isViewOnly: false,
    isEdit: false,
    isNew: false

  }

  isSendEmail:boolean = false;
  
  selectedId: any = null
  newEditItem: any = {}

  receivingNotes = ''

  constructor(
    private _bs: BaseService,
    private _abd: AppBaseDataService,
    private _spinner: NgxSpinnerService,
    private _messageService: MessageService,
    private _http:HttpClient,
    ) { }

  getPoList(
    filterValue = '',
    receivePO,
    locationId: string,
    vendorId: string,
    poStatus: string,
    startDate: string,
    endDate: string,
    pageNo,
    pageSize) {
    let url = environment.InventoryBaseURL + 'PurchaseOrder?'
      + `filterValue=${filterValue}`
      + `&receivePO=${receivePO}`
      + `&locationId=${locationId}`
      + `&vendorId=${vendorId}`
      + `&poStatus=${poStatus}`
      + `&startDate=${startDate}`
      + `&endDate=${endDate}`
      + `&pageNo=${pageNo}`
      + `&pageSize=${pageSize}`

    return this._bs.httpGet(url).pipe(map(response => {
      return response;
    }))
  }

  directOrder(data: any, option: any) {
    let url = `${environment.InventoryBaseURL}DirectPurchaseOrder?commit=${option}`
    return this._bs.httpPost(url, JSON.stringify(data)).pipe(map(res => {
      return res;
    }))
  }

  purchaseOrderPost(data: any) {
    let url = environment.InventoryBaseURL + 'PurchaseOrder';

    if (data.id == '-1') {
      return this._bs.httpPost(url, JSON.stringify(data)).pipe(map(res => {
        return res;
      }))
    }
    else {
      url += '/' + data.id
      return this._bs.httpPut(url, JSON.stringify(data)).pipe(map(res => {
        return res;
      }))
    }
  }

  purchaseOrderReceive(data: any, postToInventory: boolean) {
    let url = environment.InventoryBaseURL + 'ReceivePurchaseOrder/' + data.purchaseOrderId + '?commit='
    url += postToInventory ? 'true' : 'false'

    return this._bs.httpPost(url, JSON.stringify(data)).pipe(map(res => {
      return res;
    }))
  }

  updatePurchaseOrder(id, data, extraQryParameter='') {
    let url = `${environment.InventoryBaseURL}PurchaseOrder/${id}`
    if(extraQryParameter=='commit') url += `?Commit=true`

    return this._bs.httpPut(url, data).pipe(map(res => {
      return res;
    }))
  }

  saveReceivedPurchaseOrder(id, commit, data) {
    let url = environment.InventoryBaseURL
    url += `ReceivePurchaseOrder/${id}?commit=${commit}`
    return this._bs.httpPost(url, data).pipe(map(res => {
      return res;
    }))
  }

  searchSingleOrder(orderid: any, getWhat = false) {
    let url = environment.InventoryBaseURL + "PurchaseOrder/" + orderid + `?receivePO=${getWhat}`

    return this._bs.httpGet(url).pipe(map(response => {
      return response;
    }))
  }

  getPurchaseStatusName(status: string) {
    try {
      let ret = status.toLowerCase()
      // if (ret == 'closedforreceiving') return 'Closed For Rec.'
      if (ret == 'closedforreceiving') return 'Received'
      ret = ret[0].toUpperCase() + ret.slice(1)
      return ret
    } catch { return status }
  }

  getPoStatusClass(status: string) {
    const data = status.toLowerCase()
    if (data == 'new') return 'p-mr-2 new-chip'
    if (data == 'open') return 'p-mr-2 open-chip'
    if (data == 'draft') return 'p-mr-2 draft-chip'
    if (data == 'posted') return 'p-mr-2 posted-chip'
    if (data == 'received') return 'p-mr-2 received-chip'
    if (data == 'partial') return 'p-mr-2 partial-chip'
    if (data == 'cancelled') return 'p-mr-2 cancelled-chip'
    if (data == 'completed') return 'p-mr-2 completed-chip'
  }

  generatePoNumber() {
    const dt = new Date()
    return dt.getTime()
  }

  mapItemTonewEditItem(item) {
    const newEditItem = {
      PartitionKey: item.partitionKey,
      PONumber: item.poNumber,
      GRNNumber: 0,
      BillNumber: '',
      PurchaseType: item.purchaseType,
      Dated: new Date(item.dated),
      EnableDueDate: item.enableDueDate,
      DueDate: new Date(item.dueDate),
      VendorId: item.vendorId,
      Vendor: item.vendor,
      BillTo: '',
      SiteId: '',
      WarehouseId: item.warehouseId,
      Warehouse: item.warehouse,
      Transportation: '',
      ShipVia: '',
      ReceiveType: '',
      RequestedById: item.requestedById,
      RequestedBy: item.requestedBy,
      POStatus: item.poStatus,
      POType: item.poType,
      ReceivedById: '',
      ReceivedBy: '',
      SubTotal: item.subTotal,
      TaxTotal: item.taxTotal,
      DiscountTotal: item.discountTotal,
      GrandTotal: item.grandTotal,
      IsBillGenerated: item.isBillGenerated,
      PurchaseDetails: null,
      IsPaymentFinalized: item.isPaymentFinalized,
      RefNo: '',
      AttachmentPath: '',
      Notes: '',
      Shipping: item.shipping,
      InvoiceNumber: '',
      Id: item.id,
      CreatedDate: new Date(item.createdDate),
      TotalQuantity: item.totalQuantity,
      GRNStatus: item.grnStatus,
      InventoryPostStatus: '',

      locationObj: this._abd.locations.find(f => f.id == item.warehouseId),
      vendorObj: this._abd.vendors.find(f => f.id == item.vendorId),

      isViewOnly: false,
      isEdit: false,
      isNew: false
    }
    this.newEditItem = newEditItem

  }

  loadInit() {
    this.blankNewEditItem()
  }

  blankNewEditItem() {
    this.newEditItem = {
      PartitionKey: '',
      PONumber: '',
      GRNNumber: '',
      BillNumber: '',
      PurchaseType: '',
      Dated: new Date(),
      EnableDueDate: '',
      DueDate: new Date(),
      VendorId: '',
      Vendor: '',
      BillTo: '',
      SiteId: '',
      WarehouseId: '',
      Warehouse: '',
      Transportation: '',
      ShipVia: '',
      ReceiveType: '',
      RequestedById: '',
      RequestedBy: '',
      POStatus: '',
      ReceivedById: '',
      ReceivedBy: '',
      SubTotal: 0,
      TaxTotal: 0,
      DiscountTotal: 0,
      GrandTotal: 0,
      IsBillGenerated: false,
      PurchaseDetails: '',
      IsPaymentFinalized: false,
      RefNo: '',
      AttachmentPath: '',
      Notes: '',
      Shipping: '',
      InvoiceNumber: '',
      Id: '',
      CreatedDate: new Date(),
      ReceivingDate: new Date(),
      TotalQuantity: 0,
      GRNStatus: '',
      InventoryPostStatus: '',

      locationObj: null,
      vendorObj: null,
      isViewOnly: false,
      isEdit: false,
      isNew: false
    }
  }

    savePurchaseOrderConfiguration( data) {
        let url = environment.BusinessBaseURL
        url += 'InventoryConfig/0?type=PO'
        return this._bs.httpPut(url, data).pipe(map(res => {
            return res;
        }))
    }

    getPurchaseOrderConfigurationDetails() {
        let url = environment.BusinessBaseURL
        url += 'InventoryConfig/0?filterValue&type=PO'
        return this._bs.httpGet(url).pipe(map(res => {
            return res
        }))
    }
   sendEmail(body){
       let url = environment.BusinessBaseURL+'SendEmail'
       return this._http.post(url, body, { responseType: 'text' })
       //    return this._bs.httpPost(url,body).pipe(map(res => {
    //     return res;
    // }))
   }

}
