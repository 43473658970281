import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TimeTrackingConfig } from 'src/app/_models/configuration-widgets/business-config';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { GlobalService } from 'src/app/_services/global.service';
import { PurchaseService } from 'src/app/_services/purchase.service';
import { RewardProgramService } from 'src/app/_services/reward-program.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-time-tracking-configuration',
  templateUrl: './time-tracking-configuration.component.html',
  styleUrls: ['./time-tracking-configuration.component.scss']
})
export class TimeTrackingConfigrationComponent implements OnInit {
    
    _global = GlobalService
    currentSelectedLocation:any = this._abd.locations[0]
    timeTrackingForm: FormGroup;
    timeTrackingSettingData: TimeTrackingConfig;
    @Input() singupBusinessConfig: boolean = true;
    @Output() widgetSaved = new EventEmitter();
    constructor(private _fb: FormBuilder,
                public _businessSetupService: BusinessSetupService,
                private _spinner: NgxSpinnerService,
                private _connMessageService: ConnMessageService,
                private _confirmationService: ConfirmationService,
                public _abd: AppBaseDataService,
                private _messageService: MessageService,
                public _userService:UserService,
                ) { }
  
    ngOnInit(): void {
      this.initForm()
      this.getTimeTrackingConfigrationWithLocation(this.currentSelectedLocation)
    }
    get f(){
      return this.timeTrackingForm?.controls
    }
    set f(values: any) {
      if (this.timeTrackingForm) {
        this.timeTrackingForm.setValue(values);
      }
    }

    // getTimeTrackingConfigration(){
    //   try{
    //     this._abd.showSpinner()
    //     this._abd.getTimeTrackingConfiguration().subscribe(res=>{
    //       if(res.success){
    //         this.timeTrackingSettingData = res.data
    //        this.setValues(this.timeTrackingSettingData)
    //         this._abd.hideSpinner()
    //       }
    //     })
    //   }
    //   catch{
    //     this._abd.hideSpinner()
    //   }
    // }
  setValues(values:any) {
      this.f = {
        active:values.active,
        forceCheckIn:values.forceCheckIn,
        autoCheckInOnLogin:values.autoCheckInOnLogin,
        enableTotalWorkingHours:values.enableTotalWorkingHours,
        totalWorkingHours:values.totalWorkingHours,
        autoCheckoutOnLogout:values.autoCheckoutOnLogout,
        forceCheckoutBeforeLogout:values.forceCheckoutBeforeLogout,
        printTimeTrackingReportFromPOSAPP:values.printTimeTrackingReportFromPOSAPP,
      }
  }
 
    initForm() {
      this.timeTrackingForm = this._fb.group({
        active:[''],
        forceCheckIn:[''],
        autoCheckInOnLogin:[''],
        enableTotalWorkingHours:[''],
        totalWorkingHours:['',[Validators.max(24),Validators.pattern(/^([0-9]\d*)(\.\d+)?$/)]],
        autoCheckoutOnLogout:[''],
        forceCheckoutBeforeLogout:[''],
        printTimeTrackingReportFromPOSAPP:[''],
      })

      this.enableAll(this.timeTrackingForm.get('active').value)
      this.timeTrackingForm.get('active').valueChanges.subscribe((v)=>{
        if(v === true){
          this.enableAll(v)
        }
      })
  
    }
    getTimeTrackingConfigrationWithLocation(value:any){
      try{
        this._abd.showSpinner()
        this._abd.getTimeTrackingConfigurationWithFilter(value.locationId).subscribe(res=>{
          if(res.success){
            this.timeTrackingSettingData = res.data
           this.setValues(this.timeTrackingSettingData)
            this._abd.hideSpinner()
          }
        })
      }
      catch{
        this._abd.hideSpinner()
      }
    }
  // checkConfigration(value?:any) {
  //   if(value.checked === false){
  //     this._abd.showSpinner()
  //   this._userService.getTimeAttendance(this._global.userInfo.userID).subscribe((res)=>{
  //   if(res.data.type === 0){
  //     let obj ={
  //       UserId:        res.data?.userId,
  //       EmployeeID:    res.data?.userId,
  //       EmployeeRole:  res.data?.employeeRole,
  //       EmployeeEmail: res.data?.employeeEmail, 
  //       EmployeeName:  res.data?.employeeName,
  //       LocationName:  res.data?.locationName,
  //       RegisterName:  res.data?.registerName,
  //       locationid:    res.data?.locationId,
  //       Type:          1,
  //       Method:        res.data?.method,
  //       reason:        1,
  //      }
  //     this._confirmationService.confirm({
  //       message: 'Disabling Time Tracking will update your current check-in status to Check-out. Are you sure you want to proceed with this change?',
  //       header: 'Time Tracking Configuration',
  //       icon: 'pi pi-info-circle',
  //       accept: () => {
  //            this._userService.checkOutUser(obj).subscribe((res)=>{
  //             localStorage.setItem('userAttendance','not-check-in'),
  //             this._userService.isCurrentCheckIn = false
  //             this._userService.isCheckIn = false
  //             localStorage.removeItem('enableTimeAttendance');
  //             this.enableAll(false)
  //           this._abd.hideSpinner()
  //            })
         
     
  //       },
  //       reject: () => { 
  //         this.timeTrackingForm.get('active')?.setValue(true);
  //       }
  //     });
  //   }else{
  //     this.enableAll(false)
  //     this._abd.hideSpinner()
  //   }

  //   })
  //   }
  // }
    enableAll(value){
      if(value==false){
        this.timeTrackingForm.get('forceCheckIn')?.disable();
        this.timeTrackingForm.get('autoCheckInOnLogin')?.disable();
        this.timeTrackingForm.get('enableTotalWorkingHours')?.disable();
        this.timeTrackingForm.get('totalWorkingHours')?.disable();
        this.timeTrackingForm.get('autoCheckoutOnLogout')?.disable();
        this.timeTrackingForm.get('forceCheckoutBeforeLogout')?.disable();
        this.timeTrackingForm.get('printTimeTrackingReportFromPOSAPP')?.disable();
      }else if (value==true){
        this.timeTrackingForm.get('forceCheckIn')?.enable();
        this.timeTrackingForm.get('autoCheckInOnLogin')?.enable();
        this.timeTrackingForm.get('enableTotalWorkingHours')?.enable();
        this.timeTrackingForm.get('totalWorkingHours')?.enable();
        this.timeTrackingForm.get('autoCheckoutOnLogout')?.enable();
        this.timeTrackingForm.get('forceCheckoutBeforeLogout')?.enable();
        this.timeTrackingForm.get('printTimeTrackingReportFromPOSAPP')?.enable();
        
        this.forceCheckIndisableControls(this.timeTrackingForm.get('forceCheckIn').value)
        this.totalWorkingHoursdisableControls(this.timeTrackingForm.get('enableTotalWorkingHours').value)
          this.timeTrackingForm.get('forceCheckIn').valueChanges.subscribe((v)=>{
        this.forceCheckIndisableControls(v)
      })
      this.timeTrackingForm.get('enableTotalWorkingHours').valueChanges.subscribe((v)=>{
        this.totalWorkingHoursdisableControls(v)
      })
      }
    }
    forceCheckIndisableControls(value){
      if(value==false){
        this.timeTrackingForm.get('autoCheckInOnLogin')?.enable();
      }else if (value==true){
        this.timeTrackingForm.get('autoCheckInOnLogin')?.disable();
        this.timeTrackingForm.get('autoCheckInOnLogin')?.setValue(false);
      }
    }
    totalWorkingHoursdisableControls(value){
      if(value==false){
        this.timeTrackingForm.get('totalWorkingHours')?.disable();
      }else if (value==true){
        this.timeTrackingForm.get('totalWorkingHours')?.enable();
      }
    }
    saveFn(){
      if(this._abd.locations.length < 1){
          return  this._messageService.add({ severity: 'error', summary: 'Location required. Please add a location to proceed.' })
      }
      if(this.f.enableTotalWorkingHours.value === true){
        if (this.f.totalWorkingHours.value == '' || this.f.totalWorkingHours.value == null) {
            this._messageService.add({ severity: 'error', summary: 'Please Enter Total Working Hours' })
            return false;
        }
    }
      let timeTrackingValue: TimeTrackingConfig = {
        active:this.timeTrackingForm.get('active').value,
        forceCheckIn:this.timeTrackingForm.get('forceCheckIn').value,
        autoCheckInOnLogin:this.timeTrackingForm.get('autoCheckInOnLogin').value,
        enableTotalWorkingHours:this.timeTrackingForm.get('enableTotalWorkingHours').value,
        totalWorkingHours:this.timeTrackingForm.get('totalWorkingHours').value,
        autoCheckoutOnLogout:this.timeTrackingForm.get('autoCheckoutOnLogout').value,
        forceCheckoutBeforeLogout:this.timeTrackingForm.get('forceCheckoutBeforeLogout').value,
        printTimeTrackingReportFromPOSAPP:this.timeTrackingForm.get('printTimeTrackingReportFromPOSAPP').value,
        LocationId:this.currentSelectedLocation.locationId
      };
      try{
        this._abd.showSpinner()
        this._businessSetupService.saveTimeTrackingConfiguration(timeTrackingValue).subscribe(res=>{
          if(res.success){
            if( this.f.active.value === true){
              this._userService.isCheckInEnable = true
              localStorage.setItem('enableTimeAttendance','true');
            }else if(this.f.active.value === false){
              this._userService.isCheckInEnable = false
              localStorage.removeItem('enableTimeAttendance');
            }
            this._connMessageService.showToastMessage(`Time Tracking settings saved successfully!`, 'success')
            this._businessSetupService.showTenderConfiguration = false
          }
        })
      }
      catch{
        this._connMessageService.showToastMessage(`Error saving Time Tracking settings!`, 'error')
        this._abd.hideSpinner()
      }
    }
    locationChanged(e){
      this.currentSelectedLocation = e.value
      this.getTimeTrackingConfigrationWithLocation(e.value)
    }
    cancelButtonClick(){
      this._businessSetupService.showTenderConfiguration = false
    }
  
  }
  