import { GlobalService } from 'src/app/_services/global.service';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/_services/products.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-product-pricing',
  templateUrl: './product-pricing.component.html',
  styleUrls: ['./product-pricing.component.scss']
})
export class ProductPricingComponent implements OnInit {
  editform: FormGroup
  _globals = GlobalService
  
  selectedDefaultPrice:any=''

  constructor(
    public _productService: ProductsService,
    public _abd: AppBaseDataService,
    private _fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.selectedDefaultPrice = this._productService.selectedDefaultPrice
    this.initForm()
    if (!this._productService.activeItem) {
      let s = JSON.parse(JSON.stringify(this._productService.productInfo.taxRate[0]))
      // s.name  = s.name + '(' + this._abd.systemConfigs.taxConfig.tax1 + '%)'
      s.name  = '(' + this._abd.systemConfigs.taxConfig.tax1 + '%)'
      this._productService.taxRates = []
      this._productService.taxRates.push(s)
      this._productService.selectedTaxRate = this._productService.taxRates[0]
    }
  }

  initForm() {
    this.editform = this._fb.group({
      priceA: [this._productService.pts.priceA, Validators.required],
      priceB: [this._productService.pts.priceB, this._globals.validateNonZeroNumber],
      priceC: [this._productService.pts.priceC, this._globals.validateNonZeroNumber],
      costPrice: [this._productService.pts.costPrice, this._globals.validateNonZeroNumber],
      discountPrice: [this._productService.pts.discountPrice, this._globals.validateNonZeroNumber],
      caseQty: [this._productService.pts.caseQty, this._globals.validateNonZeroNumber],
    })

  }

  sellQtyChanged(e) {
    //console.log(this._productService.selectedSellQuantity.value)
  }

  defaultPriceChange(e) {
    this._productService.selectedDefaultPrice = e.value
  }

  isFormValid() {
    return this.editform.valid
  }

}
