<p style="
        margin-top: 0px;
        font-size: 2.1rem;
        color: var(--bs-active-primary);
        font-weight: 900;
    ">
    Customer Details
     <!-- - {{currentCustomer.businessName}} -->
</p>
<br />
<div class="grid">
    <div class="col">
        <div class="conn-card">
            <app-bizz-customer-personal-info></app-bizz-customer-personal-info>
        </div>
    </div>
</div>
<div class="conn-grid-main create-product">
    <h4>Subscription</h4>
    <div>
        <p-tabMenu #tab
            [model]="items"
            [activeItem]="activeItem"
            [scrollable]="true"
            (click)="onTabItemClick(tab)">
        </p-tabMenu>

        <div *ngIf="activeItem.label === 'Subscription'" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <div class="conn-grid ">
                    <!-- <app-subscription
                        [planInfo]="planInfo"
                        [businessSetup]="businessSetup"
                    >
                    </app-subscription> -->
                    <app-subscription-detail-of-customer>

                    </app-subscription-detail-of-customer>
                </div>
            </div>
            <!-- <div class="grid">
                <div class="col">
                    <div class="conn-card">
                        <app-bizz-customer-personal-info></app-bizz-customer-personal-info>
                    </div>
                </div>
            </div> -->
            <!-- <div class="grid" *ngIf="_bizzCustomerService.activeItem.isSubscriptionCompleted">
                <div class="col">
                    <div class="conn-card">
                        <app-bizz-customer-subscription-invoice></app-bizz-customer-subscription-invoice>
                    </div>
                </div>
            </div> -->
        </div>
        <div *ngIf="activeItem.label === 'Billing Address' && !planInfo.isFreeTrial"
            class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <div class="conn-grid ">
                    <app-billing-address></app-billing-address>
                </div>
            </div>
        </div>
        <div *ngIf="activeItem.label === 'Billing History' && !planInfo.isFreeTrial" class="fadeinright animation-duration-200 mt-3">
            <div class="conn-card">
                <div class="conn-grid ">
                    <app-billing-history></app-billing-history>
                </div>
            </div>
        </div>
        <div *ngIf="activeItem.label === 'Hardware' && !planInfo.isFreeTrial" class="fadeinright animation-duration-200 mt-3">
            <!-- <div class="conn-card">
                <div class="conn-grid ">
                    <app-hardware-details-history></app-hardware-details-history>
                </div>
            </div> -->
            <div class="nas-grid-2x">
                <!-- <div class="col" *ngIf="_bizzCustomerService.activeItem.subscriptionInvoice?.plans">
                    <div class="conn-card">
                        <app-bizz-customer-subscription-plan-details></app-bizz-customer-subscription-plan-details>
                    </div>
                </div> -->
                <div class="col" *ngIf="_bizzCustomerService.activeItem.subscriptionInvoice?.hardwarePkg">
                    <div class="conn-card">
                        <app-bizz-customer-hardware-details></app-bizz-customer-hardware-details>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="grid">
    <div class="col">
        <div class="conn-card">
            <app-subscription-detail-of-customer></app-subscription-detail-of-customer>
        </div>
    </div>
</div> -->


<!-- <div class="grid">
    <div class="col">
        <div class="conn-card">
            <app-bizz-customer-personal-info></app-bizz-customer-personal-info>
        </div>
    </div>
</div>

<div class="nas-grid-2x">
    <div class="col" *ngIf="_bizzCustomerService.activeItem.subscriptionInvoice?.plans">
        <div class="conn-card">
            <app-bizz-customer-subscription-plan-details></app-bizz-customer-subscription-plan-details>
        </div>
    </div>
    <div class="col" *ngIf="_bizzCustomerService.activeItem.subscriptionInvoice?.hardwarePkg">
        <div class="conn-card">
            <app-bizz-customer-hardware-details></app-bizz-customer-hardware-details>
        </div>
    </div>
</div>

<div class="grid" *ngIf="_bizzCustomerService.activeItem.isSubscriptionCompleted">
    <div class="col">
        <div class="conn-card">
            <app-bizz-customer-subscription-invoice></app-bizz-customer-subscription-invoice>
        </div>
    </div>
</div> -->

