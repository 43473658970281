import { Component, Input, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {
  // imageData = "assets/150-26.jpg";
  dummyImageData = "https://preview.keenthemes.com/metronic8/demo2/assets/media/svg/files/blank-image.svg";

  fileToUpload: any;

  @Input() imageData = "https://preview.keenthemes.com/metronic8/demo2/assets/media/svg/files/blank-image.svg"
  constructor(
    private _productService: ProductsService
  ) { }

  ngOnInit(): void {
  }

  handleFileInput(event: any) {
    try {
      let file = event.target.files;
      this.fileToUpload = file.item(0);
      const self = this
      //Show image preview
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageData = event.target.result;
      }
      reader.onloadend = function () {
        self._productService.featureImage = reader.result
      }
      reader.readAsDataURL(this.fileToUpload);
    } catch { }
  }

  removeImage() {
    this._productService.featureImage = null
    this.imageData = this.dummyImageData;
  }

}
