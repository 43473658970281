import { BizzCustomersService } from './../../_services/bizz-customers.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { PaginatorComponent } from 'src/app/_forms/paginator/paginator.component';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { Table } from 'primeng/table';
import { FilterServiceService } from 'src/app/_services/filter-service.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-bizz-customers-list',
  templateUrl: './bizz-customers-list.component.html',
  styleUrls: ['./bizz-customers-list.component.scss']
})
export class BizzCustomersListComponent implements OnInit {
  _globals = GlobalService
  @ViewChild('dt') dataTable: Table;
  @ViewChild('op') op: OverlayPanel
  @ViewChild('paginator') paginator: PaginatorComponent

  page = 0
  rows = 10
  totalRecords = 0
  dataList: any = null
  noData:boolean = false;
  //isSubscriptionCompleted:boolean=false;
  // summaryHeader: [['#','Full Name','Status']],
  // colflds: ['index','fullName', 'status'],
  colHeader = [['Customer Name','Business Name','Status','Current Plan','Price (mnthly/ yrly)','Payment Interval','Contact Number','Contact Email']];
  colflds = ['customerName','businessName', 'isActive','currentPlan','price','paymentInterval','phone','email'];
  searchText:any=''
  lastSearchText = ''



  constructor(
    private _bizzCustomerService: BizzCustomersService,
    private _spinner: NgxSpinnerService,
    private _filterService:FilterServiceService,
    private _router: Router,
    private _abd: AppBaseDataService
  ) { }

  ngOnInit(): void {
    this._globals.componentName = `${this._globals.whiteLabel.wlName} Customers`
    this._globals.showProductDelButton = false
    this.getDataList()
  }




  getDataList() {
    this._abd.showSpinner()
    this._bizzCustomerService.getAllCustomers(this.page, 200,this.searchText).subscribe(res => {
      if (res?.success) {
        this._abd.hideSpinner()
        this.totalRecords = res.totalRecords
        this.dataList = res.data
        this.dataList=this.dataList.filter(f=>(f.isSubscriptionCompleted || f.isFreeTrial))

      }
      this._abd.hideSpinner()
    })
  }
  getDate(status,date){
    if(status.accountStatusString=='Deactivated Account'){
      return '-'
    }else{
      return date
    }
  }

  clearSearchText() {
    this.searchText = ''
    if (this.lastSearchText.length > 0) {
      this.lastSearchText = ''
      this.filterButtonPressed()
    }
  }
  searchFilterText(){
    this.dataTable.filterGlobal(this.searchText, 'contains');
   let filter =  this.dataTable.filteredValue ? true : false
    if(this.searchText && !filter)
    {
      setTimeout(() => {
        this.noData = true
      }, 500);
      }
  else{this.noData = false;}  


} 
   clearFilterSearch(){
    this.searchText = ''
    this.searchFilterText()
  }

  searchTextSearch() {
    if (this.searchText.length < 1 && this.lastSearchText.length < 1) return
    if (this.searchText === this.lastSearchText) return
    this.lastSearchText = this.searchText
    this.filterButtonPressed()
  }

  implementLocalFilter() {

  }
  getPlanInterval(interval){
  
  if(interval === 1){
    return "mth"
  }else{
    return "yr"
  }
   
 }
  callbackFuncFromFilter(event) {
    this.implementLocalFilter()
  }

  filterButtonPressed() {
    this.getDataList();
  }

  getPrice(item:any)
  {
    try
    {
      if(item.isFreeTrial)
      return 0.00;
      if(item.invoice==null)
      return 0.00;
      if(item.subscriptionInvoice==null)
      return 0.00;
      if(item.subscriptionInvoice.plans[0])
      return item.subscriptionInvoice.plans[0].price;
      return 0.00;
    }
    catch{
      return 0.00;
    }
  }
 getAccountStatus(item:any)
  {
    try
    {

      if(item.accountStatusString=='Deactivated Account')
      return 'Deactivated'
      return 'Active'
    }
    catch{
      return 0.00;
    }
  }
  onPageChange(e) {
    e.totalRecords = this.totalRecords
    this.rows = e.rows
    this.page = e.page
    this.getDataList()
  }
  clear(table: Table) {
    table.clear();
}
  onRowSelect(e) {
    if(e.data.currentSubscription != undefined){
        this._bizzCustomerService.activeItem = JSON.parse(JSON.stringify(e.data))
        this._router.navigate(['bizz/customers-detail'])
    }
    else{
        alert('There is no subscription available yet')
    }

  }

  exportExcel() {
  
    let list = this.dataList.map((v)=>{
      return{
        customerName:v.firstName+' '+v.lastName,
        businessName:v.businessName,
        isActive:v.accountStatusString=='Deactivated Account' ? false : true,
        currentPlan:v.currentSubscription.planName,
        price:v.invoice.planInterval === 1 ? '$'+ v.currentSubscription.total.toFixed(2)+'/mth': '$'+ v.currentSubscription.total.toFixed(2)+'/yr',
        paymentInterval:v.accountStatusString=='Deactivated Account' ? '-' : formatDate(v.currentSubscription.subscriptionExpire , 'M/d/yyyy', 'en-US'),
        phone:v.userPhone,
        email:v.email
      }
    })

    this._abd.exportExcel(list, this.colHeader[0], this.colflds, `${this._globals.whiteLabel.wlName} Customers`,false)
  }

  exportPdf() {
 
    let list = this.dataList.map((v)=>{
      return{
        customerName:v.firstName+' '+v.lastName,
        businessName:v.businessName,
        isActive:v.accountStatusString=='Deactivated Account' ? false : true,
        currentPlan:v.currentSubscription.planName,
        price:v.invoice.planInterval === 1 ? '$'+ v.currentSubscription.total.toFixed(2)+'/mth': '$'+ v.currentSubscription.total.toFixed(2)+'/yr',
        paymentInterval:v.accountStatusString=='Deactivated Account' ? '-' : formatDate(v.currentSubscription.subscriptionExpire , 'M/d/yyyy', 'en-US'),
        phone:v.userPhone,
        email:v.email
      }
  })

    this._abd.exportPdf(list, this.colHeader, this.colflds, `${this._globals.whiteLabel.wlName} Customers`, false,false,false)
  }

  onRowUnselect(e) { }

  onPage(e) {
  }

}
